.pipeline_node_display {
    background: var(--bg);
    border-radius: .5rem;
    padding: 0.5rem;
    border: 1px solid lightgray;
    box-shadow: 0px 2px 5px var(--secondary20);
    width: 12rem;
    height: 3.5rem;
    font-size: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;
}

.pipeline_node_actual_display {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    /* padding: 0rem 1.5rem; */
}

.pipeline_node_actual_display p {
    margin-right: 1rem;
}

.node_incomplete {
    position: absolute;
    top:0;
    right: 0;
    background-color: var(--accent);
    color: var(--bg);
    /* font-weight: 300; */
    padding: 0.2rem;
    box-sizing: border-box;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transform: translate(50%, -50%);
}

.create_stage_holder {
    width: 100vw;
    position: relative;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(0.5rem) brightness(80%);
}

.create_stage {
    /* background-color: white; */
    position: relative;
    z-index: 9999;
    width: 80%;
    height: 80%;
    background: var(--background);
    border-radius: 0.5rem;
    border: .1px solid lightgray;
    box-shadow: 0px 2px 5px rgb(0,0,0,0.2);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2rem;
    box-sizing: border-box;
}

.stage_bottom_actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.stage_cancel, .stage_save, .stage_delete {
    border-radius: 0.5rem;
    border: 0.5px solid lightgray;
    box-shadow: 0px 2px 5px rgb(0,0,0,0.2);
    padding: 0.5rem 1rem;
    width: 5rem;
    text-align: center;
    cursor: pointer;
    height: 1.2rem;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.stage_delete {
    box-shadow: 0px 2px 5px rgb(255,0,0,0.2);
    color: red;
}

.stage_save {
    background: rgba(0, 0, 0, 0.8);
    color: white;
}

.stage_save:hover {
    background: rgba(0, 0, 0, 1);
}

.stage_cancel:hover, .stage_save:hover, .stage_delete:hover {
    transform: scale(1.025);
    box-shadow: 0px 2px 5px rgb(0,0,0,0.35);
}

.stage_delete:hover {
    box-shadow: 0px 2px 5px rgb(255,0,0,0.35);
}

.create_stage_heading {
    text-align: center;
    margin: 0;
    padding: 0;
    margin-bottom: 1rem;
}

.skillscore_info_icon {
    position: absolute;
    left: 2rem;
    top: 2rem;
    cursor: pointer;
}