/* .job_settings_holder {
    padding: 2rem;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
} */

.job_settings_add_stage_button {
    width: 8rem;
    height: 2rem;
    border-radius: 0.2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 1rem;
    box-sizing: border-box;
    color: white;
    background-color: black;
    box-shadow: 0px 2px 5px rgb(0, 0, 0, 0.3);
    cursor: pointer;
    transition: box-shadow 0.3s ease;
}

.job_settings_add_stage_button:hover {
    box-shadow: 0px 2px 5px rgb(0, 0, 0, 0.6);
}

.job_settings_pipeline_container {
    height: 35vh;
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    box-sizing: border-box;
    border-radius: 0.2rem;
    margin: 1rem 0rem;
    overflow-y: auto;
    overflow-x: hidden;
    box-shadow: inset 0px 0px 5px rgb(0, 0, 0, 0.5);
}

.job_settings_pipeline_stage {
    display: flex;
    min-height: 3rem;
    height: 3rem;
    
    border: 0.5px solid lightgray;
    box-shadow: 0px 2px 5px rgb(0, 0, 0, 0.05);
    margin-bottom: .5rem;
    width: 100%;
}

.job_settings_pipeline_save {
    border-radius: 0.2rem;
    box-shadow: 0px 2px 5px rgb(0, 0, 0, 0.05);
    width: 8rem;
    height: 2.5rem;
    color: white;
    background-color: black;
    transition: box-shadow 1s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.job_settings_pipeline_save:hover {
    /* border: 0.2px solid black; */
    box-shadow: 0px 2px 5px rgb(0, 0, 0, 0.5);
}

.job_settings_pipeline_reorder_icon {
    height: 100%;
    display: flex;
    align-items: center;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    font-size: 0.8rem;
    
}

.reorder_icon_disabled {
    opacity: 0.3;
} 

h4 {
    margin: 0;
    padding: 0;
}

.pipeline_stage_left {
    width: 60%;
}

.sub-stage {
    display: flex;
    width: 40%;
    justify-content: space-between;
    align-items: center;
    padding: 0rem .5rem;
    box-sizing: border-box;
}


.add_new_stage_area {
    width: 100vw;
    position: relative;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(0.5rem) brightness(80%);
}

.add_new_stage_popup {
    background-color: white;
    width: 50vw;
    height: 65vh;
    box-shadow: 0px 0px 20px rgba(211, 211, 211, 1);
    /* border: 2px solid lightgray; */
    border-radius: .5rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 1rem 2rem 2rem 2rem;
    align-items: center;
    box-sizing: border-box;
}

.stage_popup_top_area {
    width: 100%;
}

.stage_popup_heading {
    text-align: center;
    margin: 0;
    padding: 0;
}

.stage_popup_input {
    width: 100%;
    padding: 0.5rem 1rem;
    box-sizing: border-box;
    /* display: flex;
    align-items: center; */
}

.actions_bottom {
    width: 100%;
}

.stage_input_info {
    background: none;
    border: 1px solid lightgray;
    color: black;
    box-sizing: border-box;
    width: 40%;
    margin-bottom: 1rem;
    padding: .5rem 1rem;
    border-radius: .2rem;
    box-shadow: 0px 2px 5px rgb(0, 0, 0, 0.1);
    font-family: "Archivo", sans-serif;
    font-size: 1rem;
}

.stage_input_info:focus {
    outline: none;
    background: none;
    border: 1px solid lightgray;
    color: black;
}

.job_pipeline_stage_type {
    background: none;
    color: black;
    box-sizing: border-box;
    width: 40%;
    height: 2rem;
    padding: 0 .5rem;
    border: 1px solid lightgray;
    box-shadow: 0px 2px 5px rgb(0, 0, 0, 0.1);
    border-radius: .2rem;
    font-size: 1rem;
    font-family: "Archivo", sans-serif;
  }
  
  .job_pipeline_stage_type::placeholder {
    color: lightgray;
    opacity: 0.1;
  }
  