.candidate_email {
    border: 1px solid var(--sectionBackground);
    border-radius: 2rem;
    padding: 0.5rem 1.5rem;
    box-sizing: border-box;
    cursor: pointer;
    transition: all 0.3s ease;
    max-width: 17.5rem;
    display: flex;
    align-items: center;

    cursor: pointer;
    width: 100%;
    height: 100%;

}

.candidate_email_text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 90%;

    
}

.candidate_email_icon {
    margin-right: .5rem;
    
}

.candidate_email:hover {
    background-color: var(--sectionBackground);
    /* color: white; */
}

.email_history {
    width: 100%;
    height: 80%;
    overflow-y: auto;
}

.email_history_received_item {
    /* text-align: left; */
    width: 100%;
    display: flex;
    justify-content: flex-start;
}

.email_history_sent_item {
    /* text-align: right; */
    justify-content: flex-end;
    width: 100%;
    display: flex;
    /* border: 1px solid red; */
}

.email_history_item {
    width: 60%;
    border: 1px solid var(--secondary);
    border-radius: 0.5rem;
    padding: 2rem;
    margin-bottom: 1rem;
    box-sizing: border-box;
    position: relative;
}

.email_history_item_top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.candidate_send_email {
    position: absolute;
    bottom: 1rem;
    width: 40%;
    height: 2.5rem;
    border-radius: 1rem;
    border: 0.5px solid var(--primary);
    color: var(--primary);
    box-shadow: 0px 2px 5px var(--boxShadow);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
}

.candidate_send_email:hover {
    color: var(--background);
    background-color: var(--secondary);
    border: 0.5px solid var(--secondary);
    box-shadow: 0px 2px 5px var(--secondary);
}

.candidate_send_email_holder {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.candidate_send_email_details {
    width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}


.send_email_select {
    width: 100%;
    padding: 0.5rem 1rem;
    box-sizing: border-box;
    border: 1px solid var(--secondary40);
    border-radius: 0.5rem;
    margin-top: 0.5rem;
    background: none;
    width: 15rem;
    outline: none;
}

.send_email_subject {
    outline: none;
    border: 1px solid var(--secondary40);
    border-radius: 0.3rem;
    background: none;
    width: 100%;
    padding: 0.5rem 1rem;
    box-sizing: border-box;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
}

.send_email_body {
    margin-top: 0.5rem;
}

.candidate_send_email_placeholders {
    width: 25%;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: 100%;
}

.placeholders_list {
    margin-top: 1rem;
}

.placeholder {
    margin-bottom: 1rem;
    border: 1px solid rgb(167, 167, 167);
    padding: 0.5rem 1rem;
    cursor: pointer;
    border-radius: 0.75rem;
}