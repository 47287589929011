.automation {
    position: absolute;
    min-width: 20vw;
    height: 15vh;
    bottom: 2rem;
    left: 10rem;
    
    z-index: 99999999;
    background: var(--background);
    color: black;
    display: flex;
    border: 1px solid var(--secondary40);
    box-shadow: 0px 0px 5px var(--secondary40);
    border-radius: .5rem;
    align-items: center;
    justify-content: center;
    display: none;
    padding: .5rem 1rem;
    box-sizing: border-box;
}

.automation div {
    margin-right: 1rem;
    cursor: default;
}

.automation_message_title {
    font-weight: bold;
}

.automation_close {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    background: var(--bg);
    background-color: var(--primary70);
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.5rem;
    transform: translateY(-50%) translateX(100%);
}

.automation_close_icon {
    width: 1rem;
    height: 1rem;
    font-weight: 600;
    cursor: pointer;
}

.automation_message_title {
    margin-bottom: 0.5rem;;
}

.automation_message_description {
    margin-bottom: 0.5rem;
}

.automation_message {
}

.automation_message_action {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    text-decoration: underline;
    color: var(--primary);
}

.automation_message_action_text {
    cursor: pointer;
    text-decoration: underline;
    color: var(--primary);
}

@keyframes flyInAutomation {
    from {
        transform: translateX(-200%);
    }
    to {
        transform: translateX(0%);
    }
}

@keyframes flyOutAutomation {
    from {
        transform: translateX(0%);
    }
    to {
        transform: translateX(-200%);
    }
}