
.candidates_best_fit {
    background-color: var(--primary);
    border-radius: 2rem;
    width: 10rem;
    height: 3rem;
    padding: 0.35rem 0.5rem;
    box-sizing: border-box;
    display: flex;
    color: var(--bg);
    justify-content: center;
    cursor: pointer;
    align-items: center;
    transition: all 0.3s ease;
}

.candidates_best_fit:hover {
    box-shadow: 0px 2px 5px var(--secondary60);
    transform: scale(1.025);
}

.find_best_fit_enter_job_details {
    /* border: 1px solid red; */
    text-align: left;
    width: 100%;
    padding-top: 1rem;
    /* padding: 2rem; */
    /* box-sizing: border-box; */
}

.find_best_fit_enter_job_title {
    margin-bottom: 2rem;
}

.find_best_fit_enter_job_title h3, .find_best_fit_enter_job_description h3 {
    margin-bottom: .75rem;
    font-size: 1.1rem;
    font-weight: 600;
}

.find_best_fit_job_title_input {
    background: none;
    outline: none;
    border: 1px solid var(--secondary);
    border-radius: .5rem;
    width: 40%;
    padding: 0.5rem 1rem;
    color: var(--text);
    font-family: "Inter", sans-serif;
}

.fit_best_fit_job_desc {
    resize: none;
    background: none;
    border: 1px solid var(--secondary);
    border-radius: .5rem;
    padding: 0.5rem 1rem;
    color: var(--text);
    width: 80%;
    min-height: 15rem;
    font-family: "Inter", sans-serif;
}

.find_best_fit_job_select {
    background: none;
    color: black;
    box-sizing: border-box;
    width: 50%;
    height: 2.5rem;
    padding: 0 .5rem;
    border: 1px solid lightgray;
    box-shadow: 0px 2px 2px rgb(0, 0, 0, 0.1);
    border-radius: .2rem;
    font-size: 1rem;
    font-family: "Inter", sans-serif;
    outline: none;
}

.find_best_fit_job_select::placeholder {
    color: lightgray;
    opacity: 0.1;
}

.select_job_holder {
    width: 100%;
    justify-content: space-between;
    display: flex;
    align-items: center;
    padding: 0.5rem 7.5rem;
    box-sizing: border-box;
    /* border: 1px solid red; */
}



.candidate_search {
    border: 1px solid var(--primary);
    border-radius: 2rem;
    width: 10rem;
    height: 2.5rem;
    padding: 0.35rem 0.5rem;
    box-sizing: border-box;
    display: flex;
    color: var(--primary);
    justify-content: center;
    cursor: pointer;
    align-items: center;
    

    overflow: hidden;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-weight: 400;
    position: relative;
    background-color : transparent;
    overflow: hidden;
}

.candidate_search::before, .candidate_search::after{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: -100%;
    left: 0;
    background-color: var(--primary);
    z-index: -1;
    transition: all 200ms ease-in;
}

.candidate_search:hover {
    color: #fff;
}

.candidate_search::before {
    opacity: 0.3;
}

.candidate_search::after {
    transition-delay: 0.15s;
}

.candidate_search:hover::before, .candidate_search:hover::after {
    top: 0;
}

.best_fit_selection_option {
    width: 100%;
    display: flex;
    margin: 1rem 0rem;
    align-items: center;
    justify-content: flex-end;
}

.best_fit_selection_option p {
    margin: 0;
    margin-left: 1rem;
}

.best_fit_candidate_actions {
    display: flex;

}


.best_fit_candidates_holder {
    width: 100%;
    min-height: 57.5%;
    box-shadow: 0px 0px 2px inset var(--secondary);
    border-radius: 0.5rem;
    /* padding: 0rem 1rem; */
    box-sizing: border-box;
}

.best_fit_candidates_holder_heading {
    display: flex;
    align-items: center;
    padding: 1rem;
    box-sizing: border-box;
}

.best_fit_candidates_holder_heading > div {
    text-align: center;
}

.best_fit_candidates_holder_heading p {
    margin: 0;
}

.best_fit_candidates_holder_body {
    
   
    height: 85%;
    overflow-y: auto;
}

.best_fit_candidate {
    width: 100%;
    padding: 1rem 1rem;
    box-sizing: border-box;
    border-bottom: 1px solid lightgray;
    display: flex;
    align-items: center;
}


.best_fit_candidate > div {
    text-align: center;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.best_fit_candidate_score {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10%;
}

.best_fit_candidate_name {
    /* display: flex;
    justify-content: flex-start;
    justify-content: center; */
    width: 15%;
}

.best_fit_candidate_email {
    display: flex;
    justify-content: center;
    width: 25%;
    font-size: 0.8rem;
    padding: 0rem 1rem;
    box-sizing: border-box;
}

.best_fit_candidate_email_history {
    width: 100% !important; 
    display: flex;
    justify-content: center;
    align-items: center;
}


.best_fit_candidate_location {
    text-align: center;
    width: 15%;
    /* border: 1px solid red; */
}

.best_fit_candidate_job_applied {
    display: flex;
    justify-content: center;
    width: 25%;
}

.best_fit_candidate_actions {
    width: 10%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 1.5rem;
    box-sizing: border-box;
}

.best_fit_candidate_actions div {
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
}

.find_best_fit_disclaimer {
    width: 100%;
    color: gray;
    display: flex;
    align-items: center;
}