.candidate_history_area {
    /* border: 10px solid red; */
    /* height: 100%; */
    max-height: 92.5%;
    overflow-y: auto;
}

.candidate_history_item {
    border-bottom: 1px solid var(--secondary40);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.75rem 0;
    box-sizing: border-box;
   
}

.candidate_history_item_content {
    width: 80%;
}

.candidate_history_item_date {
    width: 20%;
}

.candidate_history_item_email {
    cursor: pointer;
    color: var(--primary);
    text-decoration: underline;
}

.candidate_history_item_content p {
    margin: 0;
}

.candidate_history_item_content p:not(:last-child) {
    margin-bottom: 0.5rem;
}

.no_candidate_history_item {
    padding: 0.5rem 0.5;
    opacity: 0.75;
    box-sizing: border-box;
    text-align: center;
}

.candidate_history_item_date p {
    margin: 0;
}
