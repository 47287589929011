

#shift_stage_actions {
    display: flex;
    justify-content: center;
    width: 85%;
    transition: all 0.3s ease;
}

#shift_stage_actions {
    transition: all 0.3s ease;
}


.candidate_current_stage {
    border-radius: 25rem;
    min-width: 10rem;
    padding: 0rem 1rem;
    height: 2rem;
    border: 1px solid var(--secondary70);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    position: absolute;
    top: 2rem;
    left: 2rem;
}

.candidate_change_stage_options {
    background: none;
    /* border: 1px solid lightgray; */
    color: black;
    box-sizing: border-box;
    width: 100%;
    padding: .5rem .5rem;
    font-size: 1rem;
}

.candidate_change_stage_option:first-of-type {
    border-top: 1px solid lightgray;
}

.candidate_change_stage_option {
    border-bottom: 1px solid lightgray;
    cursor: pointer;
    padding: 1rem 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.change_stage_body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.change_stage_email_subject {
    width: 70%;
    margin-bottom: 1rem;
    outline: none;
    border: 1px solid rgb(167, 167, 167);
    border-radius: 0.3rem;
    background: none;
    padding: 0.5rem 1rem;
    box-sizing: border-box;
    margin-bottom: 1rem;
}

.change_stage_email_body {
    width: 100%;
    /* border: 1px solid red; */
    height: 80%;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
}

.change_email_personalize_rejection_holder {
    width: 70%;
    height: 2rem;
    background: linear-gradient(45deg, var(--secondary) 0%, var(--accent) 100%);
    border-radius: 1rem;
    padding: 0.1rem;
}

.change_email_personalize_rejection {
    width: 100%;
    height: 100%;
    padding: 0.5rem 1rem;
    box-sizing: border-box;
    transition: all 0.3s ease;
    border-radius: 1rem;
    background-color: var(--bg);
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
}

.change_email_personalize_rejection:hover {
    box-shadow: 0px 5px 15px var(--secondary);
    background-color: linear-gradient(45deg, var(--secondary) 0%, var(--accent) 100%);
    color: var(--bg);
}

.candidate_move_action {
    position: absolute;
    bottom: 2rem;
    right: 2rem;
    border-radius: 1rem;
    border: 1px solid var(--secondary40);
    background-color: var(--primary60);
    color: var(--bg);
    /* box-shadow: 0px 2px 5px rgb(0,0,0,0.2); */
    padding: 0.5rem 1rem;
    width: 12rem;
    text-align: center;
    cursor: pointer;
    height: 1.2rem;
    transition: all 0.3s ease;
}

.candidate_move_action:hover {
    transform: scale(1.025);
    background-color: var(--primary80);
    color: var(--bg);
    box-shadow: 0px 2px 5px var(--secondary60);
}

.candidate_reject_action {
    position: absolute;
    bottom: 2rem;
    right: 2rem;
    border-radius: 0.5rem;
    border: 0.5px solid rgb(250, 102, 102);
    box-shadow: 0px 2px 5px rgb(0,0,0,0.2);
    padding: 0.5rem 1rem;
    width: 12rem;
    text-align: center;
    cursor: pointer;
    height: 1.2rem;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0px 2px 5px rgb(255,0,0,0.2);
    color: red;
}

.candidate_reject_action:hover {
    transform: scale(1.025);
    box-shadow: 0px 2px 5px rgb(255,0,0,0.35);
}

.personalized_rejection_row:first-of-type {
    /* border-top: 1px solid var(--secondary40) */
}

.personalized_rejection_row {
    padding: 1rem 0.5rem;
    display: flex;
    border-bottom: 1px solid var(--secondary40);
}

.personalized_rejection_row_label {
    min-width: 40%;
    /* border: 1px solid red; */
}

.personalised_rejection_message_content {
    background: linear-gradient(45deg, var(--secondary), var(--primary), var(--accent));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 400% 100%;
    animation: gradient 5s ease infinite;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.personalize_actions {
    padding: 0rem 3rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
}

.personalize_cancel_holder {
    width: 10rem;
    /* height: 2rem; */
    /* background: lightgray; */
    border-radius: 1rem;
    padding: 0.1rem;
}

.personalize_cancel {
    border: 1px solid var(--secondary40)
}

.personalize_accept_holder {
    width: 10rem;
    /* height: 2rem; */
    background: linear-gradient(45deg, var(--secondary) 0%, var(--accent) 100%);
    border-radius: 1rem;
    padding: 0.1rem;
}

.personalize_accept, .personalize_cancel {
    width: 100%;
    height: 100%;
    padding: 0.5rem 1rem;
    box-sizing: border-box;
    transition: all 0.3s ease;
    border-radius: 1rem;
    background-color: var(--bg);
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
}

.personalize_accept:hover {
    box-shadow: 0px 5px 15px var(--secondary);
    background-color: linear-gradient(45deg, var(--secondary) 0%, var(--accent) 100%);
    color: var(--bg);
}

.personalize_cancel:hover {
    box-shadow: 0px 2px 5px rgb(0, 0, 0, 0.2);
}


.hire_candidate_body {
    padding: 1rem 3.5rem;
    box-sizing: border-box;
    width: 100%;
}


.hire_candidate_message{
    background: linear-gradient(45deg, var(--secondary), var(--primary), var(--accent));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 400% 100%;
    animation: gradient 5s ease infinite;
    font-size: 1.2rem;
    font-weight: 600;
    width: 100%;
    text-align: center;
    margin-bottom: 3rem;
}

.hire_send_an_email {
    color: var(--primary);
    text-decoration: underline;
}   

.hire_candidate_email_subject_holder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
}