.prescreening_description {
    margin-bottom: 3rem;
}

.prescreening_icon {
    margin-right: 1rem;
}

.prescreening_heading {
    height: 2.5rem;
    display: flex;
    justify-content: space-between;
    padding: 0rem 1rem 0rem 3rem;
}

.prescreening_category{ 
    display: flex;
    justify-content: space-between;
    /* box-shadow: 0px 2px 5px rgb(0,0,0,0.2); */
    border-bottom: .5px solid lightgray;
    width: 100%;
    height: 3rem;
    align-items: center;
    /* background: rgb(250, 250, 250); */
    /* border-radius: 0.5rem; */
    padding: 0rem 1rem;
    box-sizing: border-box;
    padding: 0.5rem 1rem;
}

.prescreening_category_first {
    border-top: .5px solid lightgray;
}

.prescreening_category_heading {
    font-size: 1.1rem;
    font-weight: 500;
    margin-right: 4rem;
}

.prescreening_heading_category {
    font-size: 1.1rem;
}

.screening_location_input {
    border: none;
    border-bottom: 2px dotted #ccc;
    outline: none;
    height: 2rem;
    font-size: 1rem;
}

.screening_location_input:focus {
    border-bottom-color: #7a0dff;
}

.location_editable_pen {
    color: #ccc;
    margin-left: 10px;
    cursor: pointer;
}

.screening_location_input:focus + .location_editable_pen {
    color: #7a0dff;
}