
.candidate_total_holder{
    width: 100%;
    height: 100%;
    display: flex;
    padding: 0rem 1rem;
    box-sizing: border-box;
}

.candidate_navigation {
    display: flex;
    height: 100%;
    align-items: center;
}

.candidate_navigation_icon {
    font-size: 1.5rem;
    transform: scale(1, 1.35);
    cursor: pointer;
    transition: all 0.3s ease;
}

.candidate_navigation_icon:hover {
    transform: scale(1.2, 1.62);
}

.candidate_holder {
    padding: 2rem 1rem;
    box-sizing: border-box;
    width: 100%;
    height: 85%;
}

.candidate_top {
    display: flex;
    width: 100%;
    height: 5rem;
    align-items: center;
    justify-content: space-between;
    padding: 0rem 2rem;
    box-sizing: border-box;
}


.candidateInfo {
    width: 60%;
    /* min-width: 70%; */
    display: flex;
    align-items: center;
}

.candidate_top_end {
    display: flex;
    align-items: center;
    width: 40%;
    justify-content: flex-end;
    height: 100%;
}

.candidate_info_item {
    margin-left: .5rem;
    height: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.candidate_info_icon {
    width: 10%;
}

.candidate_info_text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.back_button {
    margin-right: 1rem;
    font-size: 1.2rem;
    transition: all 0.3s ease;
    color: var(--text);
}

.back_button:hover {
    transform: scale(1.1);
}

.back_button a:active {
    color: var(--text);
}

.candidate_score {
}


.candidate_name {
    margin: 0;
    margin-right: 0.5rem;
}

.candidate_second_row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    padding: 0rem 2rem;
    box-sizing: border-box;
    height: 5%;
}

.candidate_socials {
    display: flex;
    align-items: center;
}

.candidate_socials a {
    text-decoration: none;
    color: var(--text);
    margin-right: 1.5rem;
    transition: all 0.3s ease;
}

.candidate_socials a:hover {
    transform: scale(1.2);
}


.candidate_profile_email {
    border-right: 1px solid var(--secondary40);
    padding-right: 1rem;
    margin-right: 1rem;
    height: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.candidate_profile_email:hover {
    background-color: var(--secondary10);
}


.candidate_stage {
    border: 1px solid var(--secondary40);
    background-color: var(--secondary5);
    border-radius: 2rem;
    padding: 0.5rem 1.5rem;
    box-sizing: border-box;
    cursor: pointer;
    transition: all 0.3s ease;
    min-width: 13.5rem;
    width: 100%;
    height: 80%;

    display: flex;
    align-items: center;
}

.candidate_stage:hover {
    background-color: var(--sectionBackground);
}


h3 {
    margin: 0;
}


.open_candidate_options {
    cursor: pointer;
}

.candidate_info_display {
    /* border: 1px solid red; */
    height: 100%;
    width: 97.5%;
    display: flex;
}

.candidate_info_left {
    width: 60%;
    height: 100%;
    margin-right: 1.5rem;
    /* border: 1px solid red; */
    /* padding: 1rem;
    box-sizing: border-box; */
}

.candidate_info_right {
    width: 40%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* padding: 1rem;
    box-sizing: border-box; */
}

.candidate_info_container {
    border: 1px solid var(--sectionBackground);
    /* background-color: white; */
    box-shadow: 0px 2px 5px var(--boxShadow);

    /* border: 1px solid var(--secondary20);
    background-color: var(--secondary5); */

    border-radius: 2rem;
    width: 100%;
    overflow-x: auto;

}

.candidate_actions_holder {
    border: 1px solid var(--secondary40);
    background: var(--bg);
    box-shadow: 0px 2px 5px var(--secondary10);
    /* background: red; */
    border-radius: 1rem;
    width: 15rem;
    /* transform: translateX(-50%); */
    /* height: 20rem; */
    overflow: hidden;
}


.candidate_action {
    width: 100%;
    /* border-bottom: 1px solid var(--secondary40); */
    padding: 0.5rem 1rem;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.candidate_action:hover {
    background-color: var(--secondary10);
}

.candidate_education {
    height: 35%;
    margin-bottom: 1rem;
    cursor: pointer;
}



.candidate_work_history {
    height: 62.5%;
}








/* HANDING THE CANDIDATE INFO STAGE SWITCHING */


.candidate_info_container_holder {
    /* display: flex;
    align-items: center; */
    height: 48%;
}

.candidate_info_container_holder_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 90%;
}


.candidate_info_container_holder_bottom {
    height: 10%;
}

.candidate_technical_skills {
    /* height: 95%; */
}

.candidate_interview_feedback {
    height: 100%;
}

.candidate_soft_skills, .candidate_personality_fit {
    /* height: 100%; */
    display: none;
}

.candidate_carousel_info_container {
    display: none;
    padding: 1rem;
    box-sizing: border-box;
    overflow: hidden;
    height: 100%;
}

.candidate_carousel_info_container.active {
    display: block;
}

.arrow {
    cursor: pointer;
    user-select: none;
    transition: transform 0.3s ease;
    transform: scale(1, 1.5);
    width: 5%;
    height: 5%;
}

.arrow:hover {
    transform: scale(1.2, 1.8);
}

.arrow.left {
    margin-right: 0.5rem;
}

.arrow.right {
    margin-left: .5rem;
}

.indicator {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.dot {
    height: 15px;
    width: 15px;
    margin: 0 2px;
    background-color: var(--secondary);
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
}

.dot.active {
    background-color: var(--primary);
}




/* HANDLING THE CANDIDATE EDUCATION ASPECT */

.candidate_info_container_top {
    justify-content: space-between;
    display: flex;
    align-items: center;

    margin-bottom: 0.5rem;

}

.candidate_info_container_info {
    display: flex;

    height: 70%;

}

.candidate_education {
    padding: 1rem 2rem;
    box-sizing: border-box;
}

.candidate_education_info {

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 0.75rem 0rem;
}

.candidate_education h3 {
    margin: 0;
    margin-right: 0.5rem;
    /* margin-bottom: 1rem; */
}

.candidate_education_category {
}

.candidate_education_category h4 {
    margin: 0;
    margin-right: 0.5rem;
    /* margin-bottom: 1rem; */
}

.candidate_education_category p {
    margin: 0;
}

.candidate_education_level {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* justify-content: space-between; */
    /* margin-left: rem; */
    width: 30%;
    height: 100%;
}

/* .candidate_education_level_icon_holder {
    border: 0.5px solid lightgrey;
} */

.candidate_education_level_icon {
    font-size: 3rem;
    /* padding: 1rem; */
    /* box-sizing: border-box; */
    /* border-radius: 1rem; */
    height: 80%;
    aspect-ratio: 1/1 !important;
    /* width: 100%; */
    /* width: 100%;
    aspect-ratio: 1/1; */
    /* height: 100%; */
    color: var(--iconColor);
}

.candidate_education_level_text {
    font-weight: 400;
}

.candidate_education_major {
   display: flex;
   align-items: center;
   /* border: 1px solid red; */
}

.candidate_education_university {
    display: flex;
    align-items: center;
}

.candidate_education_gpa {
    display: flex;
    align-items: center;
}

.candidate_education_major_category_heading {
    margin-right: 0.35rem;
}


/* HANDING THE CANDIDATE WORK EXPERIENCE ASPECT */

.candidate_work_history {
    padding: 1rem 2rem;
    box-sizing: border-box;
}

.candidate_work_history_choice {
    display: flex;
    /* width: 100%; */
    justify-content: space-between;
    margin: 1rem 3rem 0rem 3rem;

}

.candidate_work_history_choice_selection {
    position: relative;
    color: black;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.5s ease;
    padding: 0;
    cursor: pointer;
    margin-right: 2rem;
    margin-bottom: 1.5rem;
}

.candidate_work_history_choice_selection::after {
    content: '';
    position: absolute;
    background-color: black;
    height: 3px;
    width: 0%;
    left: 0;
    transition: width 0.5s ease;
    bottom: -8px;
}

.candidate_work_history_choice_selection:hover {
    transform: scale(1.05);
}

.candidate_work_history_choice_selection:hover::after {
    width: 100%;
}

.candidate_work_history_choice_selection.selected::after {
    content: '';
    position: absolute;
    background-color: black;
    height: 3px;
    width: 100%;
    left: 0;
    bottom: -8px;
}

.candidate_work_history_area {
    overflow-y: scroll;
    height: 70%;
}


/* styling a job */

.candidate_work_history_job {
    border-bottom: 1px solid lightgray;
    padding: 1rem;
    box-sizing: border-box;
    cursor: pointer;
}

.candidate_work_history_job_top {
    display: flex;
    justify-content: space-between;
    align-items:center;
    /* border: 1px solid red; */
}

.candidate_work_history_job_duration {
    width: 17.5%;
}

.candidate_work_history_job_title {
    font-size: 1.2rem;
    font-weight: 500;
}

.candidate_work_history_job_company {
    color: rgb(68, 68, 68);
}

.candidate_work_history_job_list_item {
    margin-bottom: 0.5rem;
}


/* HANDLING THE SKILLS AND PERSONALITY ASPECT */

.candidate_skills {
    padding: 1rem 2rem;
    box-sizing: border-box;
    position: relative;
    width: 100%;
}

.candidate_skills_display {
    overflow-y: auto;
    height: 80%;
}

.candidate_skill {
    border-bottom: 1px solid lightgray;
    padding: 1rem;
    box-sizing: border-box;
    cursor: pointer;
}

.candidate_skill_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.candidate_skill_name {
    font-size: 1.1rem;
    font-weight: 500;
}

.skill_showcase {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;


}

.skill_showcase_head {
    font-weight: 600;
    margin: 0;
    margin-right: 0.35rem;

}

.skill_showcase_content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    /* position: relative; */
    max-width: 13.5rem;
}



/* HANDLING THE CSS FOR THE POPUP */

.skill_popup_holder {
    width: 100vw;
    position: relative;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(0.5rem) brightness(80%);
}

.skill_popup {
    z-index: 9999;
    width: 80%;
    height: 80%;
    background: var(--background);
    border-radius: 0.5rem;
    border: .1px solid lightgray;
    box-shadow: 0px 2px 5px rgb(0,0,0,0.2);
    display: flex;
    flex-direction: column;
    padding: 2rem 3rem;
    box-sizing: border-box;
}

.skill_popup_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
}

.skill_popup_header_first {
    display: flex;
    align-items: center;
}

.skill_popup_header_first h3 {
    font-size: 1.75rem;
}

.skill_popup_close {
    cursor: pointer;
}

.skill_popup_showcases_holder {
    height: 80%;
    overflow-y: scroll;
}

.skill_popup_showcase {
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    border-bottom: 1px solid lightgray;
    padding: 2rem;
    box-sizing: border-box;
}

.skill_popup_showcase_score {
    display: flex;
    align-items: center;
}

.skill_popup_showcase_info {
    width: 40%;
}

.skill_popup_showcase_title {
    margin-bottom: 0.75rem;
    font-size: 1.2rem;
    font-weight: 550;
}

.skill_popup_showcase_reason {
    width: 60%;
}



/* Handling the job popup details */

.score_popup_heading {
    width: 95%;
    display:flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
}

.score_popup_heading h1 {
    display: flex;
    align-items: center;
    margin: 0;
}

.popup_body {
    width: 100%;
    height: 85%;
    text-align: left;
    padding: 0rem 1rem;
    box-sizing: border-box;
}


.job_score_details {
    padding-top: 2rem;
    height: 80%;
    /* max-height: 80%; */
    /* border: 1px solid red; */
    overflow-y: scroll;
    overflow-x: hidden;
}

.job_score_detail_holder {
    background: none;
    width: 100%;
    border: none;
    margin: 0;

}

.job_score_detail_collapsible {
    border-bottom: 1px solid var(--secondary40);
}

.work_detail {
    border-top: 1px solid var(--secondary40);
}

.job_score_detail {
    background: none;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0.5rem;
    /* padding-bottom: 0; */
}

.job_score_detail_first {
    display:  flex;
    align-items: center;
}

.job_score_detail_head {
    font-size: 1.2rem;
    font-weight: 500;

}

.job_score_detail_body {
    padding: 0rem 2rem;
    margin-bottom: 2rem;
}

.job_score_information {
    margin-bottom: 2rem;
}

.job_score_breakdown {
    display: flex;
    align-items: center;

}


.job_score_breakdown_item {
    width: 20%;
    height: 2rem;
    border-right: 1px solid var(--secondary40);
    display: flex;
    align-items: center;
}

.job_score_breakdown_item:last-of-type {
    border-right: none;
}

.project_information {
    margin-bottom: 2rem;
}

.project_score_breakdown_item {
    display: flex;
    border-bottom: 1px solid var(--secondary40);
    align-items: center;
    padding: 1rem 0.5rem;
}

.project_score_breakdown_item:first-of-type {
    border-top: 1px solid var(--secondary40);
}


.education_information {
    /* border-top: 1px solid var(--secondary40); */
    border-bottom: 1px solid var(--secondary40);
    padding: 1rem 0.5rem;
}

.education_information_item {
    display: flex;
    align-items: center;
    padding: 0.25rem 0rem;
}

.education_information_item_first {
    margin-right: 1rem;
    width: 15rem;
}

.education_score_detail {
    padding: 0.5rem;
    padding-right: 2rem;
    box-sizing: border-box;
}

.education_score_breakdown {
    height: 52.5%;
}

.education_score_collapsibles_holder {
    height: 100%;
    overflow-y: auto;
}

.skill_sentences_holder {
    /* border: 1px solid red; */
    padding: 0rem 2rem;
    padding-top: 2rem;
}

.skill_popup_sentence:first-of-type {
    border-top: 1px solid var(--secondary40);
}

.skill_popup_sentence {
    border-bottom: 1px solid var(--secondary40);
}



/* HANDLING THE CANDIDATE INTERVIEW ASPECT */

.interview_feedback_container {
  cursor: pointer;
}

.interview_heading_holder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0rem 0.5rem;
    height: 15%;
    padding-bottom: 5%;
}

.interview_display_info {
    height: 70%;
}

.interview_display_item:first-of-type {
    border-top: 1px solid var(--secondary20);
}

.interview_display_item {
    border-bottom: 1px solid var(--secondary20);
    padding: 1rem 0rem;
    display: flex;
    align-items: flex-start;
}

.interview_display_item p {
    margin: 0;
}

.interview_display_item_head {
  margin: 0;
  font-weight: 500;
  margin-right: 2.5% !important;
  width: 35%;
}

.interview_display_item_interviewers {
    height: 5rem;
    width: 60%;
    overflow-y: auto;
}

.interview_click {
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.interview_click p {
  margin: 0;
  margin-right: 0.5rem;
}


.interview_display_section {
  border-bottom: 1px solid var(--secondary40);
  padding: 1rem 0rem;
}

.interview_interviewers_section {
    height: 15%;
}

.interview_summary_section {
    height: 20%;
}

.interview_feedback_section {
    height: 65%;
}

.interview_feedback_direct {
    height: 100%;
    width: 90%;
    padding: 0% 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.interview_interviewers_section {
    overflow-x: auto;
}

.interview_feedback_carousel {
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-top: 1rem; */
    height: 100%;
    margin-bottom: 0.75rem;
}

.interview_feedback_direct_interviewer {
    display: flex;
    align-items: center;
    width: 100%;
}

.interview_feedback_direct_interviewer_head {
    font-weight: 500;
    margin-right: 0.5rem;
}

.interview_feedback_direct_holder {
    border: 1px solid var(--secondary40);
    border-radius: 1rem;
    padding: 1rem;
    width: 98%;
    height: 100%;
    background: var(--secondary5);
    overflow-y: auto;
}

.interview_feedback_proceed {
    margin: 0;
    font-weight: 450;
    font-size: 1.1rem;
}

.interview_feedback_text {
    height: 82.5%;
    overflow-y: auto;
}

.interview_feedback_indicators {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.interview_feedback_nav {
    cursor: pointer;
    user-select: none;
    transition: transform 0.3s ease;
    transform: scale(1, 1.5);
}

.interview_feedback_nav:hover {
    transform: scale(1.2, 1.8);
}


/* showing the feedback questions and responses */

.interview_feedback_question:first-of-type {
    border-top: 1px solid var(--secondary20);
}

.interview_feedback_question {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    border-bottom: 1px solid var(--secondary20);
    justify-content: space-between;
    padding: 1rem 2rem;
}

.interview_feedback_question_name {
    font-weight: 500;
    margin: 0;
    margin-bottom: 1rem;
}

.interview_feedback_question_type {
    opacity: 0.5;
    margin-left: 1rem;
}

.interview_feedback_question_answer {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    font-weight: 300;
    margin: 0;
}


.prescreening_rejected_view_anyway {
    border: 1px solid var(--secondary40);
    border-radius: 0.5rem;
    background-color: var(--secondary5);
    width: 8rem;
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-top: 2rem;
}

.prescreening_rejected_view_anyway:hover {
    background-color: var(--secondary40);
    border: 1px solid var(--secondary10);
    box-shadow: 0px 2px 5px var(--secondary20);
}

.question_input {
    width: 70%;
    font-family: "Inter";
    border: none;
    resize: none;
    background: none;
    border: 1px solid var(--secondary40);
    border-radius: 0.5rem;
    height: 2.5rem;
    padding: .5rem 1rem;
    box-sizing: border-box;
    outline: none;
  }
  
  .question_select {
    font-family: "Inter";
    border: none;
    resize: none;
    background: none;
    border: 1px solid var(--secondary40);
    border-radius: 0.5rem;
    outline: none;
    height: 2.5rem;
    padding: .5rem 1rem;
    box-sizing: border-box;
    color: var(--text) !important;
    opacity: 1 !important;
  }
  
  
  .question_text {
    margin: 0;
    font-weight: 500;
  }
  
  
  .question_response {
    margin-top: 1rem;
  }
  
  
  .range_slider_preview {
    display: flex;
    align-items: center;
    width: 100%;
    border: 1px solid var(--secondary40);
    border-radius: 0.5rem;
    height: 2.5rem;
    padding: .5rem 1rem;
    box-sizing: border-box;
  }
  
  .range_slider_value {
    width: 5%;
    text-align: center;
    padding-right: 2.5%;
    border-right: 1px solid var(--secondary40);
  }
  
  .range_slider_slider {
    width: 92.5%;
    display: flex;
    align-items: center;
  }
  
  .range_slider_desc {
    width: 25%;
    text-align: center;
    margin: 0;
  }
  
  .question_preview_range {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 50%;
  }
  
  .question_preview_range p {
    margin: 0;
  }
  
  .question_preview_slider {
    width: 95%;
    padding-right: 0rem;
    padding: 0rem 0.5rem;
    display: flex;
  }
  
  .feedback_notes_holder {
      padding: 1rem 0.5rem;
  }
  
  .score_slider_preview {
    height: 2.5rem;
    border: 1px solid var(--secondary40);
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    box-sizing: border-box;
    padding-left: 2.5rem;
  }
  
  .feedback_long_input {
    width: 70%;
    font-family: "Inter";
    border: none;
    resize: none;
    background: none;
    border: 1px solid var(--secondary40);
    border-radius: 0.5rem;
    height: 4.5rem;
    padding: .5rem 1rem;
    box-sizing: border-box;
    outline: none;
  }