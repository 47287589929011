.resume_content_holder {
    width: 100%;
    height: 100%;
    padding: 2rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.resume_holder {
    height: 100%;
    width: 70%;
}

.notes_holder {
    height: 100%;
    width: 60%;
    padding-left: 2rem;
}

.notes {
    height: 100%;
    border-radius: 0.5rem;
    box-shadow: inset 0px 0px 5px rgb(0, 0, 0, 0.5);
    padding: 2rem;
    box-sizing: border-box;
}

.notes_writing_area {
    width: 100%;
    height: 100%;
    font-family: "Inter";
    border: none;
    resize: none;
    background: none;
}

textarea:focus {
    outline: none;
}

.notes_actions {
    display: flex;
    justify-content: space-between;
}

.notes_action_button {
    width: 8rem;
    border: 1px solid lightgray;
    box-shadow: 0px 2px 10px rgb(0, 0, 0, 0.2);
    padding: .75rem 1.25rem;
    cursor: pointer;
    border-radius: 1rem;
    transition: transform 0.3s ease;
}

.notes_action_button:hover {
    transform: scale(1.05);
}