.candidate_details_holder {
    display: flex;
    min-height: 70%;
    max-height: 70%;
    box-sizing: border-box;
    width: 100%;
    margin-top: 1rem;
    padding: 0rem 1rem;
    justify-content: center;
    box-sizing: border-box;
    overflow-y: scroll;


    
}

.candidate_subcategory_holder {
    height: 80%;
    width: 48%;
    box-shadow: 2px 2px 19px rgb(0, 0, 0, 50%);

}

.extra_details_holder {
    padding: 2rem;
    box-sizing: border-box;
    padding-top: 5rem;
    height: 100%;
    width: 100%;
}

.all_details_collapsible_holder {
    min-width: 100%;
    min-height: 100%;
    max-height: 100%;
    max-width: 100%;
    padding: 1rem;
    box-sizing: border-box;
    border-radius: .25rem;
    display: flex;
    box-shadow: inset 0 0 5px rgb(0, 0, 0, 50%);
    overflow-y: scroll;
}

.individual_detail_holder {
    padding: 1.5rem;
    box-sizing: border-box;
    box-shadow: 0;
    border-radius: .5rem;
    min-width: 100%;
    height: 3.5rem;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    align-items: center;
    background-color: white;


    border: .5px solid lightgray;
    box-shadow: 0px 2px 2px rgb(0, 0, 0, 0.1);
}

.collapsible_root {
    padding: 1.5rem;
    box-sizing: border-box;
    border-radius: .5rem;
    box-shadow: 0;
    /* min-height: 5rem;
    height: 20rem;
    max-height: 20rem;
    overflow-y: auto; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* align-items: center; */
    background-color: white;
    border: .5px solid lightgray;
    box-shadow: 0px 2px 2px rgb(0, 0, 0, 0.1);
}


.individual_detail {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.collapsible_detail_right {
    display: flex;
    align-items: center;
}