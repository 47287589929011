.job_candidates_actions {
    display: flex;
    align-items: center;
    border: 1px solid var(--secondary40);
    border-radius: 0.5rem;
    padding: 0.35rem 1rem;
    cursor: pointer;
    background: none;
    outline: none;
}

.candidates_action {
    background-color: var(--bg);
}


.candidate_action_popup {
    display: none;
}