.dashboard_holder {
    padding: 2rem;
    box-sizing: border-box;
    /* display: flex; */
    height: 100%;
    width: 100%;
}

.dashboard_assigned {
    width: 70%;
    height: 100%;
    padding-right: 1.5rem;
    box-sizing: border-box;
}

.dashboard_section {
    border: .5px solid var(--sectionBackground);
    border-radius: 3rem;
    /* background-color: var(--sectionBackground); */
    box-shadow: 0px 2px 5px var(--sectionBackground);
    padding: 2rem;
    box-sizing: border-box;
}

.dashboard_assigned_jobs {
    height: 48%;
    margin-bottom: 1rem;
    width: 100%;
}

.dashboard_upcoming_interviews {
    height: 49%;
    width: 100%;
}

.upcoming_interiews_area {
    height: 90%;
    overflow-y: auto;
}

.dashboard_notifications {    
    width: 30%;
    height: 99%;
    padding: 1rem;
    padding-top: 2rem;
    box-sizing: border-box;
}

.dashboard_section h3 {
    margin: 0;
    margin-bottom: 2rem;
    
}

.dashboard_notifications h3 {
    text-align: center;
    margin-bottom: 1rem;
}

.dashboard_notifications_display {
    box-shadow: inset 0px 0px 5px rgb(0,0,0,0.2);
    height: 93.5%;
    border-radius: 0.5rem;
    padding: 1rem;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;
}

.dashboard_notification {
    border-bottom: 1px solid var(--secondary40);
    /* border-radius: .5rem; */
    /* box-shadow: 0px 2px 5px rgb(0,0,0,0.1); */
    height: 3rem;
    padding: 0.5rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


@keyframes swipeAway {
    from {
        transform: translateX(0);
    } to {
        transform: translateX(-150%);
    }
}


.dashboard_job_display {
    width: 95%;
    border-bottom: 1px solid var(--secondary40);
    box-sizing: border-box;
    padding: 1rem;
    margin: 0rem 1rem;
}



.dashboard_upcoming_interview {
    border-top: 1px solid var(--secondary40)
}

.dashboard_upcoming_interview {
     width: 100%;
    height: 3rem;
    /* border: 1px solid lightgray;
    box-shadow: 0px 2px 5px rgb(0,0,0,0.1); */
    border-bottom: 1px solid var(--secondary40);
    box-sizing: border-box;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border-radius: 0.3rem; */
    /* margin-bottom: 1rem; */
    cursor: pointer;
}

.dashboard_job_display_head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.dashboard_job_display_title {
    font-weight: 500;
    font-size: 1.1rem;
}

.dashboard_job_number_applicants {
    display: flex;
    align-items: center;
}

.dashboard_job_location {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0rem 1rem
}

.dashboard_job_location:first-of-type {
    margin-left: 0;
}

.dashboard_job_display_foot {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-right: 2rem;
    margin-bottom: 1rem;
}

.assigned_jobs_holder {
    overflow-y: auto;
    height: 85%;
}