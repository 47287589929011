.candidate_duplicate_icon {
    color: var(--primary);
    background-color: var(--secondary40);
    border: 1px solid var(--secondary40);
    border-radius: 35%;
    width: 1.8rem;
    height: 1.8rem;
    padding: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    position: relative;
    cursor: pointer;
    overflow: visible !important;
    overflow-x: visible !important;
    overflow-y: visible !important;
}

.tiny_exclamation {
    position: absolute;
    top:0;
    right: 0;
    background-color: var(--accent);
    color: var(--bg);
    /* font-weight: 300; */
    padding: 0.2rem;
    box-sizing: border-box;
    width: 0.85rem;
    height: 0.85rem;
    font-size: 0.65rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transform: translate(50%, -50%);
}

.duplicate_candidates_holder {
    border: 1px solid var(--secondary40);
    height: 80%;
    border-radius: 1rem;
    background-color: var(--secondary5);
}

.duplicate_candidate_table_heading {
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(128, 128, 128, 0.343);
    padding: 0.5rem 1rem;
    box-sizing: border-box;
    height: 3rem;
}

.duplicate_candidate_table_heading div {
    text-align: center;
    font-weight: bold;
}

.duplicate_candidates_body {
    width: 100%;
    height: 87.5%;
    overflow-y: auto;
}

.duplicate_candidate {
    width: 100%;
    display: flex;
    cursor: pointer;
    padding: 0rem 1rem;
    box-sizing: border-box;
    height: 3rem;
    align-items: center;
    border-bottom: 1px solid rgba(128, 128, 128, 0.343);
}

.duplicate_candidate > div {
    /* height: 100%; */
    /* border: 1px solid red; */
    /* display: flex; */
    text-align: center;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.duplicate_candidate.selected {
    background-color: var(--secondary10);
}

.duplicate_candidate_selector_radio {
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 50%;
    border: 1px solid var(--primary);
    cursor: pointer;
}

.duplicate_candidate_selector_radio.selected {
    background-color: var(--primary);
}

.merge_candidates_button {
    position: absolute;
    bottom: 3rem;
    right: 3rem;
    border: 1px solid var(--secondary20);
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    box-sizing: border-box;
    cursor: pointer;
    background-color: var(--secondary60);
    transition: all 0.3s ease;
}

.merge_candidates_button:hover {
    background-color: var(--primary60);
    transform: translateY(-2.5px);
    box-shadow: 0px 2px 5px var(--secondary40);
}

.duplicate_candidate_selector {
    width: 5%; 
    display: flex;
    justify-content: center;
    align-items: center;
}

.duplicate_candidate_name {
    width: 10%;
}

.duplicate_candidate_email {
    width: 20%;
}

.duplicate_candidate_phone {
    width: 15%;
}

.duplicate_candidate_job_applied {
    width: 20%;
}

.duplicate_candidate_date_added {
    width: 10%;
}

.duplicate_candidate_action {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mark_not_duplicate {
    border: 1px solid var(--secondary40);
    background-color: var(--secondary10);
    /* box-shadow: 0px 2px 5px var(--secondary20); */
    border-radius: 0.35rem;
    width: 85%;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.7;
    transition: all 0.3s ease;
}

.mark_not_duplicate.selected {
    background-color: var(--secondary20);
}

.mark_not_duplicate:hover {
    background-color: var(--secondary40);
}


.mark_not_duplicate_cancel, .mark_not_duplicate_confirm {
    border-radius: 1rem;
    border: 1px solid var(--secondary40);
    box-shadow: 0px 2px 5px var(--secondary40);
    padding: 0.5rem 1rem;
    width: 5rem;
    text-align: center;
    cursor: pointer;
    height: 1.2rem;
    transition: all 0.3s ease;
}
  
.mark_not_duplicate_confirm {
    background: var(--primary60);
    color: white;
}

.mark_not_duplicate_cancel:hover {
    transform: scale(1.025) translateY(-2.5px);
    box-shadow: 0px 2px 5px var(--secondary40);
    background: var(--secondary5);
}
  
.mark_not_duplicate_confirm:hover {
    background: var(--primary80);
    transform: scale(1.025) translateY(-2.5px);
    box-shadow: 0px 2px 5px var(--secondary40);
}

.duplicates_message {
    padding: 0rem 2rem;
}
  
