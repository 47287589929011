.debrief_section_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.past_interviewers_area {
    background-color: var(--secondary5);
    border: 1px solid var(--secondary40);
    border-radius: 1rem;
    padding: 1rem;
    box-sizing: border-box;
    overflow-y: auto;
    height: 13.5rem;
}

.interview_section_action {
    border: 1px solid var(--secondary40);
    background-color: var(--secondary60);
    border-radius: 0.75rem;
    transition: all 0.3s ease;
    width: 12.5rem;
    height: 2rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;
}

.interview_section_action:hover {
    transform: translateY(-2.5px) scale(1.025);
    background-color: var(--secondary80);
    box-shadow: 0px 2px 5px var(--secondary40);
}

.debrief_duration_holder {
    display: flex;
    align-items: center;
}

.interview_section_number_input.debrief {
    text-align: center;
}

.debrief_scheduling_section_row {
    display: flex;
    align-items: center;
    justify-content: space-between;    
    box-sizing: border-box;
}

.debrief_scheduling_section_row>p {
    margin: 0;
    margin: 0.5rem 0rem;
}

.scheduling_section_row_first {
    display: flex;
    align-items: center;
}

.scheduling_section_row_first>p {
    margin: 0.5rem 0rem;
    margin-right: 0.5rem;
}

.debrief_scheduling_section_select {
    margin-right: 0.5rem;
    border: 1px solid var(--secondary40);
    border-radius: 0.5rem;
    background: transparent;
    outline: none;
    padding: 0.25rem 0.5rem;
    box-sizing: border-box;
    width: 7.5rem;
    height: 2rem;
}

.past_stage {
    border-bottom: 1px solid var(--secondary40);
}

.past_stage_name {
    font-weight: 600;
    font-size: 1.1rem;
    margin: 0;
}

.past_interviewer_group_item {
    padding: 0.5rem 1rem;
    padding-bottom: 0;
} 

.past_interviewer_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
}