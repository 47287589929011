

.candidate_notes_holder {
    width: 100%;
    height: 100%;
    /* border: 1px solid red; */
    /* display: flex;
    flex-direction: column; */
    padding: 1rem;
    box-sizing: border-box;
    position: relative;
}

.candidate_notes_input {
    width: 100%;
    height: 90%;
    font-family: "Inter";
    border: none;
    resize: none;
    background: none;
    border: .1px solid lightgray;
    border-radius: 0.5rem;
    box-shadow: inset 0px 0px 5px  var(--boxShadow);
    padding: 1rem 2rem;
    box-sizing: border-box;
}