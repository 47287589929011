.feedback_category {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.feedback_category p {
    margin: 0;
}

.feedback_area>p {
    margin: 0;
    margin-bottom: 1rem;
}

.feedback_area {
    background-color: var(--secondary5);
    border: 1px solid var(--secondary40);
    border-radius: 1rem;
    padding: 1rem;
    box-sizing: border-box;
    overflow-y: auto;
    height: 17.5rem;
}

.interview_feedback_category {
    margin-bottom: 1.25rem;
}

