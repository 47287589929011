.create_job_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-x: hidden;
}

.content_section {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 2rem;
  box-sizing: border-box;
}

.section_arrow {
  transform: scale(1, 1.5);
  cursor: pointer;
  transition: transform 0.25s ease;
}

.section_arrow:hover {
  transform: scale(1.5, 2.25);
  cursor: pointer;
}

.section_holder {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  width: 200%;
  width: 70vw;

  height: 70vh;
}

.navigator_section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.create_job_section {
  /* z-index: -1; */
  position: absolute;
  width: 100%;
  height: 100%;
  display: none;
  /* box-shadow: 0px 3px 10px rgb(0, 0, 0, 0.25); */
  border-radius: 0.5rem;
  transform: translateX(0%);
  padding: 1rem 1rem 0rem 1rem;
  box-sizing: content-box;
}


@keyframes moveOutLeft {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-200%);
  }
}

@keyframes moveOutRight {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(200%);
  }
}

@keyframes moveInRight {
  from {
    transform: translateX(200%);  
  }
  to {
    transform: translateX(0%);
  }
}

@keyframes moveInLeft {
  from {
    transform: translateX(-200%);  
  }
  to {
    transform: translateX(0%);
  }
}

.create_job_input {
  background: none;
  color: var(--text);
  box-sizing: border-box;
  width: 80%;
  height: 2rem;
  padding: 1rem;
  border: 1px solid var(--secondary40);
  border-radius: .2rem;
  font-size: 1rem;
  font-family: "Inter", sans-serif;
}

.create_job_input:focus {
  outline: none;
  background: none;
  color: black;
}

.create_job_select {
  background: none;
  color: black;
  box-sizing: border-box;
  width: 40%;
  height: 2rem;
  padding: 0 .5rem;
  border: 1px solid var(--secondary40);
  border-radius: .2rem;
  font-size: 1rem;
  font-family: "Archivo", sans-serif;
  outline: none;
}

.create_job_select::placeholder {
  color: lightgray;
  opacity: 0.1;
}

.job_info_section {
  display: flex;
  align-items: center;
}

.job_info_subsection {
  width: 50%;
}

.salary_range {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
}

.dash {
  margin-right: 1rem;
}

.salary_input {
  width: 100%;
  margin-right: 1rem;
}


.job_description_section {
  margin-top: 3rem;
}

.job_description_section_heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.job_description_writeforme_holder {
  width: 20%;
  height: 2rem;
  background: linear-gradient(45deg, var(--secondary) 0%, var(--accent) 100%);
  border-radius: 1rem;
  padding: 0.1rem;
}

.job_description_writeforme {
  width: 100%;
  height: 100%;
  padding: 0.5rem 1rem;
  box-sizing: border-box;
  transition: all 0.3s ease;
  border-radius: 1rem;
  background-color: var(--bg);
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
}

.job_description_writeforme:hover {
  box-shadow: 0px 5px 15px var(--secondary);
  background-color: linear-gradient(45deg, var(--secondary) 0%, var(--accent) 100%);
  color: var(--bg);
}

.job_description_writeforme p {
  margin: 0;
}

.display_generated_job_desc {
  height: 92.5%;
  overflow-y: scroll;
}

.ql-editor {
  min-height: 17.5rem;
  max-height: 17.5rem;
}

.create_job_desc {
  /* box-shadow: 0px 0px 10px rgb(0, 0, 0, 0.15); */
}

.job_details_section {
  display: flex;
}

.job_details_table {
  border-top: 1px solid lightgray;
}

.enter_skills {
  width: 100%;
}

.skills_showcase_area {
  height: 1.5rem;
  margin-top: .5rem;
}


.location_selector {
  box-shadow: 0px 2px 2px rgb(0, 0, 0, 0.1);
}

.allSliders {
  height: 25rem;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0rem 1.5rem;
  box-sizing: border-box;
  border-radius: 1rem;
  box-shadow: inset 0px 0px 5px rgb(0, 0, 0, 0.25);
}

.slider {
  margin-bottom: 3rem;
}

.navigator_section {
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 4rem;
}

.header_section {
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 4rem;
}

.createjob_navigation_button {
  z-index: 999;
  background-color: var(--bg);
  border: 1px solid var(--secondary40);
  box-shadow: 0px 2px 2px var(--secondary20);
  padding: .5rem 2rem;
  border-radius: 1rem;
  cursor: pointer;
  transition: transform 0.25s ease;
  text-align: center;
  width: 5%;
}

.createjob_navigation_button:hover {
  transform: scale(1.1);
}

.creating_job_button {
  color: var(--primary);
  background-color: var(--bg);
  border: 1px solid var(--primary);
  box-shadow: 0px 2px 2px rgb(0, 0, 0, 0.1);
  padding: .5rem 2rem;
  text-align: center;
  border-radius: 1rem;
  width: 5%;
  cursor: pointer;
  transition: transform 0.25s ease;
}

.submit_button {
  color: white;
  background-color: var(--primary);
}

.submit_button, .cancel_button {
  z-index: 999;
  border: 1px solid lightgray;
  box-shadow: 0px 2px 2px rgb(0, 0, 0, 0.1);
  padding: .5rem 2rem;
  text-align: center;
  border-radius: 1rem;
  width: 5%;
  cursor: pointer;
  transition: transform 0.25s ease;
}

.submit_button:hover, .cancel_button:hover {
  transform: scale(1.1);
}

.salary_range_section {
  margin-bottom: 2rem;
}

.preview_button {
  position: relative;
  cursor: pointer;
}

.preview_button::after {
  content: '';
  position: absolute;
  background-color: black;
  height: 1px;
  width: 100%;
  left: 0;
  bottom: -4px;
}

.recruiting_member_info {
  display: flex;
  align-items: center;
  border-top: 1px solid rgba(211, 211, 211, 0.5); /* Make it only have the border top for the first child */
  border-bottom: 1px solid rgba(211, 211, 211, 0.5);
  min-height: 3rem;
  
}

.recruiting_member_name {
  margin-right: 2rem;
  width: 7rem;
}

.recruiting_member_role {
  font-size: 0.8rem;
  width: 10rem;
  color: gray;
}


.toggle-switch {
  width: 50px; /* Width of the outer toggle */
  height: 25px; /* Height of the outer toggle */
  background-color: #eee; /* Background color when off */
  border-radius: 0.2rem; /* Roundness of the outer toggle */
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s;
}

.toggle-switch.on {
  background-color: #333; /* Background color when on */
}

.toggle-switch-slider {
  position: absolute;
  top: 2.5px; /* Spacing from the top */
  left: 2.5px; /* Spacing from the left when off */
  width: 20px; /* Width of the slider */
  height: 20px; /* Height of the slider */
  background-color: #333; /* Color of the slider */
  border-radius: 0.2rem; /* Roundness of the slider */
  transition: left 0.3s, background-color;
}

.toggle-switch.on .toggle-switch-slider {
  left: 27.5px; /* Spacing from the left when on */
  background-color: #eee; /* Color of the slider */
}

.team_members_selection_area {
  padding: 1rem;
  height: 80%;
  max-height: 80%;
  overflow-y: scroll;
  box-sizing: border-box;
  border-radius: 0.5rem;
  box-shadow: inset 0px 0px 5px rgb(0, 0, 0, 0.2)
}

.page_switcher {
  position: absolute;
  bottom: 3rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
}

.page_switcher_dot {
  width: 20px;
  height: 20px;
  background-color: var(--secondary);
  border-radius: 50%;
  margin: 0 5px;
  transition: background-color 0.3s;
}

.page_switcher_dot.active {
  background-color: var(--primary);
}

body .quill {
  .ql-toolbar {
    border: 1px solid var(--secondary40);
    /* make the border radius of the top corners 0.5rem */
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
  .ql-container {
    border: 1px solid var(--secondary40);
    /* make the border radius of the bottom corners 0.5rem */
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
}



/* handling the incomplete node section */

.pipeline_incomplete_section {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  background-color: var(--secondary5);
  box-sizing: border-box;
  border-radius: 0.5rem;
  border: 1px solid var(--secondary40);
  height: 2rem;
  width: 70%;
  margin: 1rem 0rem;
}

.pipeline_incomplete_display {
  background-color: var(--accent65);
  font-size: 0.7rem;
  color: var(--bg);
  /* font-weight: 300; */
  padding: 0.2rem;
  box-sizing: border-box;
  width: 1rem;
  height: 1rem;
  display: flex;
  margin-right: .5rem;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.pipeline_incomplete_information {
  height: 5rem;
}

#pipeline_incomplete_section_placeholder {
  align-items: center;
}

.assigned_team_section_instructions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.add_assigned_team_button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  background-color: var(--secondary80);
  padding: 0.5rem 1rem;
  box-sizing: border-box;
  border-radius: 1rem;
  cursor: pointer;
  transition: background-color 0.3s; 
  height: 2.5rem;
  width: 10rem;
  transition: all 0.3s ease;
}

.add_assigned_team_button:hover {
  background-color: var(--secondary);
  transform: scale(1.01) translateY(-10%);
}

.add_assigned_team_button p {
  margin: 0;
}

.assigned_team_area {
  background-color: var(--secondary5);
  border-radius: 1rem;
  border: 1px solid var(--secondary40);
  height: 90%;
  width: 100%;
  box-sizing: border-box;
}

.team_section {
  width: 100%;
  height: 80%;
}

.team_members {
  background: var(--secondary5);
  border-radius: 1rem;
  border: 1px solid var(--secondary40);
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

.team_selection_section {
  margin-bottom: 2rem;
}

.no_team_members {
  opacity: 0.5;
  font-size: 1.1rem;
  padding: 1.5rem 2rem;
  box-sizing: border-box;
}

.add_interviewer_section {
}

.team_members_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--secondary40);
  padding: 0.75rem 1rem;
  box-sizing: border-box;
  height: 15%;
}

.team_members_header div {
  font-weight: bold;
  text-align: center;
}

.team_members_header p {
  margin: 0;
}

.assigned_team_member {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--secondary40);
  padding: 0.75rem 1rem;
  box-sizing: border-box;
  /* height: 15%; */
}

.assigned_team_member p {
  margin: 0;
}

.assigned_team_member div {
  text-align: center;
}

.assigned_team_name {
  width: 33%;
}

.assigned_team_email {
  width: 33%;
}

.assigned_team_role {
  width: 33%;
}

.team_members_body {
  height: 85%;
  overflow-y: scroll;
}

.assigned_members_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--secondary40);
  padding: 0.75rem 1rem;
  box-sizing: border-box;
  height: 8%;
}

.assigned_members_header div {
  font-weight: bold;
  text-align: center;
}

.assigned_members_header p {
  margin: 0;
}