.billing_popup_text {
    margin: 0;
    
}

.billing_form {
    /* border: 1px solid red;   */
    /* height:  */
    width: 100vw;
    position: relative;
    height: 100vh;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(0.5rem) brightness(80%);
}

.billing_selector {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 2.5rem;
    box-sizing: border-box;
    height: 50%;
}



.billing_selector_item {
    border: 1px solid var(--secondary40);
    background-color: var(--secondary10);
    padding: 1rem 1rem;
    box-sizing: border-box;
    border-radius: 1rem;
    width: 44%;
    height: 100%;

    display: flex;
    flex-direction: column;
    gap: 20%;
    /* aspect-ratio: 1/1; */
}

.billing_selector_item_name {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    height: 50%;
}

.billing_selector_item_icon {

    /* font-size: min(2rem, 100%); */
    height: 40%;
    width: 100%;
    opacity: 0.5;
    color: var(--primary);
    text-align: center;
}

.billing_selector_item_name label {
    font-size: clamp(0.8rem, 2vh, 1.2rem);
    font-weight: 500;
    opacity: 0.75;
    color: var(--primary);
    filter: brightness(0.1);
}

.billing_selector_item_name * {
    margin: 0;
}

.billing_selector_item_select {
    border: 1px solid var(--secondary40);
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    background-color: var(--secondary5);
    outline: none;
}



.billing_button {
    width: 100%;
    padding: 0.5rem;
    box-sizing: border-box;
    background-color: var(--primary60);
    color: var(--bg);
    border: none;
    border-radius: 1rem;
    height: 2.5rem;
    cursor: pointer;

    transition: all 0.3s ease;

    bottom: 2rem;
}

.billing_button:hover {
    background-color: var(--primary);
    transform: scale(1.01) translateY(-2px);
    box-shadow: 0 2px 5px var(--secondary20);
}

.billing_total {
    display: flex;
    align-items: center;
}

.billing_total_text {
    font-weight: 700;
    font-size: 1.15rem;
    margin-right: 0.5rem;
}

.billing_total_old_price {
    text-decoration: line-through;
    opacity: 0.85;
    margin-right: 0.5rem;
    /* chagne the color of the line that crosses out the price */
    text-decoration-color: var(--primary);

}

.billing_total_discounted_price {
    margin-right: 0.5rem;
}

.billing_total_cycle_text {
    margin: 0;
}

.billing_final_price {
    font-weight: 600;
}


.billing_total_discount_text {
    margin: 0;
    color: var(--primary);
    filter: brightness(0.75);
}


.billing_popup_text_container {
    height: 11%;
    margin: 2% 0rem;
}

.billing_selector {
    height: 50%;
}

.billing_total {
    height: 20%;
}

.billing_button_container {
    width: 100%;
    height: 10%;
}



/* BILLING SUCCESS HERE */

.billing_success_holder {
    padding: 2rem;
    box-sizing: border-box;
    height: 100vh;
    width: 100vw;
}

.billing_success_title {
    margin-bottom: 2rem;
}

.billing_success_details {
    display: flex;
    gap: 2rem;
    margin-bottom: 2rem;
}

.billing_success_details_item {
    background-color: var(--secondary10);
    border: 1px solid var(--secondary40);
    border-radius: 1rem;
    padding: 1rem;
    box-sizing: border-box;
    width: 15rem;
    height: 10rem;
    aspect-ratio: 1/1;
}

.billing_success_details_item_label {
    font-weight: 600;
    font-size: 1.1rem;
    height: 15%;
    text-align: center;
}

.billing_success_details_item_body {
    height: 75%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.billing_success_details_item_body p {
    font-size: 1.2rem;
    opacity: 0.8;
}

.billing_success_details_item p {
    margin: 0;
}