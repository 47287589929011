.job_dashboard_holder {
    display: flex;
    width: 100%;
    height: 80%;

}

.job_dashboard_charts_area {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;

}

.job_dashboard_column {
    width: 49%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: 100%;
}

.job_dashboard_chart_holder {
    height: 49%;
    padding: 1rem;
    box-sizing: border-box;
    border-radius: 0.5rem;
    border: 1px solid var(--secondary20);
    background-color: var(--secondary5);
}

.job_dashboard_chart_heading {
    margin: 0;
    margin-bottom: 1rem;
    font-weight: 500;
}

.job_dashboard_chart_direct_holder {
    height: 90%;
    display:flex;
    align-items: center;
    justify-content: center;
}

/* .applicant_history {
    height: 48%;
    width: 100%;
    margin-bottom: 1rem;
}

.candidate_information {
    display: flex;
    height: 48%;
    width: 100%;
}

.candidate_stage_chart {
    width: 49%;
    height: 100%;
    margin-right: 1rem;
}

.candidate_score_chart {
    width: 49%;
    height: 100%;
} */

