html {
    margin: 0;
}

body {
  margin: 0;
  padding: 0;
  height: 100vh;
}
.overall_nav_holder {
  height: 100%;
  /* padding: 5rem 0rem; */
  box-sizing: border-box;
  width: 13.5%;
}

nav {
  /* background-color: white; */
  color: var(--text);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;
  box-sizing: content-box;
  max-height: 100vh;

  min-width: 100%;
  width: 100%;
  max-width: 100%;

  /* padding: 15rem 0rem 0rem 0rem; */
  /* background-color: white; */

  border-right: 1px solid var(--secondary20);
  /* border-right: 1px solid rgb(0, 0, 0, 0.1); */
  box-shadow: 0px 2px 10px var(--secondary20);
  /* box-shadow: 0px 2px 10px rgb(0, 0, 0, 0.1); */
  font-size: 1rem;
}

.navHolder {
  margin: 1rem;
  height: 100%;
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: center;
  position: relative;

}

.topSection {
  display: flex;
  align-items: center;
}

.logo {
  background: url("../logo/LogoFull.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 10rem;
  height: 3rem;
  /* border: 1px solid red; */
}

.siteTitle {
  font-size: 2rem;
}

nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  /* gap: 1rem; */
}

nav a{
  color: inherit;
  text-decoration: none;
}

.topSidebar {
  display: flex;
  flex-direction: column;

}

.sidebarIcon {
  cursor: pointer;
  font-size: 1rem;
  align-self:flex-end ;
}

.topSidebar * {
  margin-bottom: 1rem;
}

.navItem, .navItemActive {
  display: block;
  padding: 10px;
  padding-left: 2rem;
  color: black;
  text-decoration: none;
  transition: background-color 0.3s ease, color 0.3s ease;
  border-radius: 2rem;
  margin-bottom: .25rem;
  width: 7rem;
  cursor: pointer;
  font-size: 1rem;
}

@media (max-width: 875px) {
  .navItem, .navItemActive {
    font-size: 0.9rem;
  }
}

@media (max-width: 780px) {
  .navItem, .navItemActive {
    font-size: 0.8rem;
  }
}

.navItem:hover {
  background-color: var(--secondary);
}

.navItemActive {
  background: var(--linearPrimarySecondary);
  color: white;
}

.topSection {
  margin-bottom: 3rem;
}

.bottomSection {
  position: absolute;
  bottom: 2rem;
}
