.settings_scheduling_category:first-of-type {
    border-top: 1px solid var(--secondary40);
}
 
.settings_scheduling_category {
    border-bottom: 1px solid var(--secondary40);
    padding: 2rem 0rem;
    box-sizing: border-box;
    margin: 0rem 1rem;
    height: 25rem;
}

.settings_scheduling_category_heading {
    margin: 0;
    font-weight: 500;
    font-size: 1.1rem;
    margin-bottom: 1%;
    height: 10%;
}

.candidate_scheduling_section_holder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 70.5%;
    margin-bottom: 2%;
}

.candidate_scheduling_save {
    height: 10.5%;
}

.candidate_scheduling_save_button {
    background: var(--primary60);
    border-radius: 1rem;
    border: 1px solid var(--secondary40); 
    padding: 0.5rem 1rem;
    box-sizing: border-box;
    height: 100%;
    width: 7.5rem;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

.candidate_scheduling_save_button:hover {
    background: var(--primary80);
}

.candidate_scheduling_saving {
    background: var(--primary10);
}

.candidate_scheduling_saved {
    background: var(--bg);
    color: var(--primary);
}

.candidate_scheduling_saved:hover {
    background: var(--bg);
    color: var(--primary);
}

.candidate_scheduling_section {
    border: 1px solid var(--secondary40);
    border-radius: 1rem;
    padding: 1rem;
    box-sizing: border-box; 
    background-color: var(--secondary5);
    width: 46.5%;
    height: 100%;
}

.candidate_scheduling_section_heading {
    margin: 0;
    font-weight: 500;
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid var(--secondary40);
}

.candidate_scheduling_section_content_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.candidate_scheduling_section_content_item_input {
    outline: none;
    background-color: transparent;
    border: 1px solid var(--secondary40);
    border-radius: 0.5rem;
    padding: 0.5rem;
    -moz-appearance: auto;
}

.candidate_scheduling_section_content_item_input::-webkit-outer-spin-button,
.candidate_scheduling_section_content_item_input::-webkit-inner-spin-button {
    -webkit-appearance: auto;
    margin: 0;
}

