.notification {
    position: absolute;
    min-width: 20vw;
    height: 6vh;
    top: 30px;
    right: 100px;
    
    z-index: 99999999;
    background: var(--background);
    color: black;
    display: flex;
    border: 1px solid var(--secondary40);
    box-shadow: 0px 0px 5px var(--secondary40);
    border-radius: .5rem;
    align-items: center;
    justify-content: center;
    display: none;

    padding: .5rem 1rem;
    box-sizing: border-box;
}

.notification div {
    margin-right: 1rem;
    cursor: default;
}


@keyframes flyIn {
    from {
        transform: translateX(200%);
    }
    to {
        transform: translateX(0%);
    }
}

@keyframes flyOut {
    from {
        transform: translateX(0%);
    }
    to {
        transform: translateX(200%);
    }
}