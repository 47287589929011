/* NEW STYLING */

.team_holder {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 2rem;
    box-sizing: border-box;
}

.team_page_header {
    margin-bottom: 1.5rem;
}

.team_actions_holder {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.member_search {
    border: 1px solid var(--secondary40);
    width: 80%;
    height: 100%;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    padding: 0rem 1rem;
    box-sizing: border-box;
}

.team_search_input {
    background: transparent;
    outline: none;
    border: none;
    padding: 0.5rem 1rem;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
}

.team_members_full_area {
    border-radius: 1rem;
    height: 100%;
    max-height: 100%;
    width: 80%;

    /* border: 1px solid blue; */
}

.team_members_subnav {
    /* margin-bottom: 1rem; */
    height: 5%;
    margin-bottom: 2%;
}

.team_members_area {
    border: 1px solid var(--secondary40);
    background: var(--secondary5);
    /* box-shadow: 0px 2px 5px var(--secondary20); */
    border-radius: 1rem;
    height: 92.5%;
    max-height: 92.5%;
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;
}

.no_members_message {
    padding: 2rem;
    opacity: 0.7;
    font-size: 1.1rem;
    font-weight: 400;
}

.member_information {
    border-bottom: 1px solid var(--secondary40);
    height: 50%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0.5rem 0rem 2rem 0rem;
    box-sizing: border-box;
}

.member_permissions {
    padding-top: 1rem;
}

.member_name {
    display: flex;
    align-items: center;
    gap: 2.5rem;

    margin-bottom: 1.5rem;
}

.member_email {
    margin-bottom: 1.5rem;
}

.member_name .member_item {
    width: 43.5%;
}

.member_item {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    height: 2rem;
}

.member_input {
    border: 1px solid var(--secondary40);
    background: var(--secondary5);
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    box-sizing: border-box;
    width: 100%;
    outline: none;
    
}

.member_item_label {
    font-weight: 550;
    margin-right: 0.5rem;
}

.member_permissions_label {
    text-align: center;
    margin: 0;

    font-size: 1.2rem;
    font-weight: 550;

    margin-bottom: 1.5rem;
}

.member_permissions_holder {
    display: flex;
    justify-content: space-between;
    align-items: center ;
}

.member_permissions_item {
    width: 50%;
}

.member_select {
    width: 75%;
}

.role_explanation {
    display: flex;
    align-items: center;
    background: var(--secondary5);
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    box-sizing: border-box;
    border: 1px solid var(--secondary40);
    cursor: pointer;
    transition: all 0.3s ease;

    z-index: 100;
}

.role_explanation:hover {
    background: var(--secondary40);
}

.role_explanation p {
    margin: 0;
    display: flex;
    align-items: center;
}

.role_explanation_exclamation {
    background-color: var(--accent);
    color: var(--bg);
    padding: 0.2rem;
    box-sizing: border-box;
    width: 1.1rem;
    height: 1.1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 0.8rem;
    margin-right: 0.5rem !important;
}

.add_member_container {
    width: 20%;
    position: relative;
}

.add_member_btn {
    /* border: 1px solid var(--secondary40); */
    border-radius: 0.75rem;
    padding: 0.5rem 1rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 80%;

    background: var(--secondary60);
    transition: background 0.3s ease;
}

.add_member_btn.open {
    border-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.add_member_btn:hover{ 
    background: var(--secondary);
}

.add_team_dropdown {
    /* border: 1px solid var(--secondary40); */
    background: var(--bg);
    border-top: none;
    border-radius: 0.75rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    cursor: pointer;
    width: 80%;
    position: absolute;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add a subtle shadow for depth */
}

.add_team_dropdown:hover {
    background: var(--secondary60);
}

.add_team_dropdown_item {
    width: 100%;
    padding: 0.5rem 1rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-sizing: border-box;
    background: var(--secondary60);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
    transition: background 0.3s ease;
}

.add_team_dropdown_item:hover {
    background: var(--secondary);
}


.add_member_actions {
    position: absolute;
    bottom: 2rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 2.5rem;
    padding: 0rem 2rem;
    box-sizing: border-box;
}

.add_member_button {
    height: 2rem;
    width: 10rem;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    border: 1px solid var(--secondary40);
    background: var(--secondary60);
    transition: all 0.3s ease;
    cursor: pointer;
}

.add_member_button:hover {
    background: var(--secondary);
    box-shadow: 0px 2px 5px var(--secondary20);
}

.team_members_area_holder {
    display: flex;
    height: 80%;
    min-height: 80%;
}

.team_members_filter_area {
    width: 20%;
    height: 100%;
    padding-right: 1rem;
    box-sizing: border-box;
    justify-content: space-between;
    display: flex;
    flex-direction: column;

}

.team_members_heading {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--secondary40);
    padding: 0rem 1rem;
    padding-bottom: .5rem;
    box-sizing: border-box;
    height: 2rem;
}

.team_members_heading div {
    text-align: center;
    font-weight: bold;
}

.team_member {
    width: 100%;
    display: flex;
    cursor: pointer;
    padding: 0rem 1rem;
    /* padding-right: 0; */
    box-sizing: border-box;
    height: 3rem;
    align-items: center;
    border-bottom: 1px solid var(--secondary40);
}


.team_member div {
    text-align: center;
}

.expand_cell {
    width: 5%;
}

.name_cell {
    width: 20%;
}

.role_cell {
    width: 25%;
}

.department_cell {
    width: 25%;
}

.actions_cell {
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
}

.member_action {
    cursor: pointer;
    color: var(--primary);
    filter: brightness(0.9);
}

.team_team {
    border-bottom: 1px solid var(--secondary40);
}

.team_info {
    width: 100%;
    display: flex;
    cursor: pointer;
    padding: 0rem 1rem;
    /* padding-right: 0; */
    box-sizing: border-box;
    height: 3rem;
    align-items: center;

}

.team_info div {
    text-align: center;
}

.team_sub_member {
    padding: 0rem 2rem;
    box-sizing: border-box;
}
 



/* SPECIFICALLY FOR THE DELETE POPUP STYLING */


.actions_bottom {
    position: absolute;
    bottom: 2rem;
    padding: 0rem 2rem;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.close_popup {
    border: 0.1px solid lightgray;
    background-color: var(--bg);
    box-shadow: 0px 2px 5px rgb(0, 0, 0, 0.1);
    border-radius: 1rem;
    width: 15%;
    cursor: pointer;
    padding: 0.5rem 1rem;
    text-align: center;
    transition: all 0.3s ease;
}

.close_popup:hover {
    box-shadow: 0px 2px 5px rgb(0, 0, 0, 0.2);
    transform: scale(1.025);
}

.create_member {
    border: 1px solid var(--secondary10);
    color: var(--bg);
    background-color: var(--primary80);
    box-shadow: 0px 2px 5px var(--secondary20);
    border-radius: 1rem;
    width: 15%;
    cursor: pointer;
    padding: 0.5rem 1rem;
    text-align: center;
    transition: all 0.3s ease;
}

.create_member:hover {
    background-color: var(--primary);
    box-shadow: 0px 2px 10px var(--secondary40);
    transform: scale(1.025);
} 

.team_members_sort {
    height: 15%;
/* 
    border: 1px solid red !important; */
}

.team_members_filter_select {
    width: 100%;
    outline: none;
    border: 1px solid var(--secondary40);
    background: var(--secondary20);
    border-radius: 0.5rem;
    padding: 0rem 1rem;
    height: 60%;
    /* height: clamp(50%, 2rem); */
    box-sizing: border-box;
}


.team_members_filter {
    height: 80%;
}

.team_members_filter_item {
    border: 1px solid var(--secondary40);
    background: var(--secondary5);
    border-radius: 0.5rem;
    padding: 1rem;

    box-sizing: border-box;
}

.team_members_filter_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 8%;
    margin-bottom: 2%;

}

.team_members_filter_label {
    margin: 0;
    font-weight: 600;
}

.team_members_filter_icon {
    cursor: pointer;
    transition: all 0.3s ease;
}

.team_members_filter_icon:hover {
    transform: scale(1.025);
}

.team_members_and {
    display: flex;
    align-items: center;
    height: 3%;
    margin-top: 2%;
    margin-bottom: 2% ;

}

.team_members_and .line {
    height: 0.2rem;
    background-color: var(--secondary40);

    width: 40%;
}

.team_members_and .and {
    width: 20%;
    text-align: center;
    margin: 0;
}


.filter_buttons_container {
    display: flex;
    flex-wrap: wrap;

    justify-content: space-between;
}
  
.filter_buttons_container button {
    /* flex-basis: 50%; Ensures two buttons per row */

    height: 2.25rem;
    /* max-height: 1%; */
    width: 48.5%;
    /* max-width: 10%; */
    background: var(--secondary10);
    border: 1px solid var(--secondary40);

    transition: all 0.3s ease;
    margin-bottom: 0.5rem;

    cursor: pointer;
    border-radius: 0.5rem;
    padding: 0.25rem 0.5rem;
    font-size: 0.8rem;
    box-sizing: border-box;

}
  
.filter_buttons_container button.active {
    background: var(--secondary40);
}

.filter_by_role p, .filter_by_department p {
    margin: 0;
    font-weight: 550;
    margin-bottom: 0.5rem;
}

.filter_by_role {
    border-top: 1px solid var(--secondary40);
    padding: 0.75rem 0rem;
    padding-bottom: 0.5rem;
    box-sizing: border-box;
    border-bottom: 1px solid var(--secondary40);
    height: 37.5%;
}


.filter_by_department {
    padding: 0.75rem 0rem;
    padding-bottom: 0.5rem;
    box-sizing: border-box;
    height: 52.5%;
}


  
.filter_department {
    border-bottom: 1px solid var(--secondary40);
    padding: 0.5rem 0rem;
    padding: 0.5rem;
    cursor: pointer;

    transition: all 0.3s ease;
}

.filter_department.active {
    background-color: var(--secondary30);
}

.filter_department:last-of-type {
    border-bottom: none;
}

.filter_by_department_label {
    height: 15%;
    margin: 0;
    font-weight: 600;
}

.filter_departments_search {
    height: 10%;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
}

.filter_departments_search_input {
    width: 100%;
    border: none;
    outline: none;
    background: transparent;
    box-sizing: border-box;
}

.filter_departments_container {
    overflow-y: auto;
    /* border: 1px solid red; */
    height: 75%;

    background-color: var(--secondary5);
    border: 1px solid var(--secondary40);

   
    box-sizing: border-box;
    border-radius: 0.5rem;
}


.team_info_holder {
    width: 50%;

    height: 100%;
}

.team_info_holder:first-of-type {
    padding-right: 1rem;
    box-sizing: border-box;
}

.team_info_holder:last-of-type {
    padding-left: 1rem;
    box-sizing: border-box;
}

.new_team_members_area {
    background: var(--secondary5);
    border: 1px solid var(--secondary40);
    border-radius: 0.5rem;
    padding: 1rem;
    box-sizing: border-box;

    height: 70%;
    min-height: 70%;
}

.new_team_members_list {
}

.new_team_member_item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    border-bottom: 1px solid var(--secondary40);
    box-sizing: border-box;
}

.delete_team_member {
    cursor: pointer;
    margin-left: 10px;
}


.role_permissions {
    background: var(--secondary5);
    border: 1px solid var(--secondary40);
    border-radius: 0.5rem;
    padding: 1rem;
    box-sizing: border-box;
}

.role_permissions section {
    border-bottom: 1px solid var(--secondary40);
    padding: 1rem 0rem;
    box-sizing: border-box;
}

.role_permissions section:last-of-type {
    border-bottom: none;
}

