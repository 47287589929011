.login_holder {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
}

.login_header {
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    box-sizing: border-box;
}

.get_in_touch_button {
    text-decoration: none;
    color: var(--text);
    border: 1px solid var(--secondary);
    border-radius: 1rem;
    padding: 0.5rem 1rem;
    transition: all 0.3s ease;
}

.get_in_touch_button:hover {
    background-color: var(--secondary);
    color: var(--bg);
    box-shadow: 0px 2px 5px var(--secondary40);
}

.login_area {
    border: 1px solid var(--secondary40);
    box-shadow: 0px 2px 5px var(--secondary10);
    width: 40%;
    height: 45%;
    border-radius: 1rem;
    padding: 2rem;
    box-sizing: border-box;
    background-color: var(--secondary5);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login_email_input {
    background: none;
    /* color: black; */
    box-sizing: border-box;
    width: 80%;
    height: 2rem;
    padding: 1rem;
    border: 1px solid var(--secondary40);
    border-radius: .5rem;
    color: rgba(0, 0, 0, 0.508);
    font-size: 1rem;
    font-family: "Inter", sans-serif;
    margin-top: 3rem;
    margin-bottom: 2rem;
  }
  
  .login_email_input:focus {
    outline: none;
    background: none;
    /* color: black; */
  }

.login_actions {
    margin-top: 3rem;
    display: flex;
    width: 50%;
    justify-content: space-between;
}

.sign_in {
    background-color: var(--primary80);
    color: var(--bg);
    border-radius: 0.75rem;
    font-size: 1rem;
    display: flex;
    width: 80%;
    justify-content: center;
    padding: 0.5rem 1rem;
    box-sizing: border-box;
    align-items: center;
    cursor: pointer;
    
    transition: all 0.3s ease;
}

.sign_in:hover {
    background-color: var(--primary);
    box-shadow: 0px 2px 5px var(--secondary40);
    transform: scale(1.025);
}

.getInTouch a {
    text-decoration: none;
    color: black
}

.getInTouch {
    background-color: rgb(255, 255, 255, 0.8);
    border-radius: .2rem;
    width: 10rem;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    padding: 0.5rem 1rem;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 0 2px rgb(0, 0, 0, 0.2);
    border: 1px solid lightgray;
    color: black;
    transition: transform 0.25s ease, background-color 0.25s ease, box-shadow 0.25s ease;
}

.getInTouch:hover{
    background-color: rgb(250, 250, 250, 1);
    box-shadow: 0 0 2px rgb(0, 0, 0, 0.2);
    transform: scale(1.025);
}
