.calendar_settings_holder {
    padding: 2rem;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
}

.cal_settings_heading {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.cal_settings_heading a {
    transition: all 0.3s ease;
    margin-right: 1rem;
}

.cal_settings_heading a:hover {
    transform: scale(1.1);
}

.cal_settings_heading a:active {
    color: black;

}

.calendar_settings_body {
    /* display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 2rem; */
    padding: 0rem 1rem;
    box-sizing: border-box;
    height: 92.5%;
    overflow-y: auto;
}

.calendar_settings_section:first-of-type {
    border-top: 1px solid var(--secondary40);
}

.calendar_settings_section {
    border-bottom: 1px solid var(--secondary40);
    padding: 2rem 0;
    /* border: 1px solid red; */
}

.calendar_settings_section_heading {
    margin-bottom: 1.5rem;
}

.weekly_availability {
    margin-bottom: 2rem;
}

.day {
    display: flex;
    /* justify-content: space-between; */
}

.day_name {
    min-width: 8rem;
    max-width: 8rem;
    border-right: 0.5px solid lightgray;
    margin-right: 2rem;
}

.day_slots {
    width: 20rem;
    border-right: 0.5px solid lightgray;
    margin-right: 2rem;
}

.add_new_day {
    cursor: pointer;
    padding-top: 0.3rem;
    color: var(--primary);
}

.remote_timeslot {
    cursor: pointer;
    padding-left: 1rem;
}

.timeslot_select {
    font-family: "Archivo", sans-serif;
    background: none;
    outline: none;
    border: .5px solid rgb(119, 116, 116);
    padding: 0.5rem;
    box-sizing: border-box;
    border-radius: 0.5rem;
}

.timeslot_select:hover {
    border: .5px solid rgb(40, 39, 39);
}

.save_timezone {
    /* border: 1px solid red; */
}

.save_availability {
    cursor: pointer;
    width: 8rem;
    height: 2rem;
    background-color: var(--bg);
    border: 1px solid var(--primary60);
    border-radius: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--primary);
    /* box-shadow: 0px 2px 5px rgb(0, 0, 0, 0.3); */
    transition: all 0.3s ease;
}

.save_availability:hover {
    background-color: var(--primary80);
    color: var(--bg);
    border: 1px solid var(--primary5);
    box-shadow: 0px 2px 5px var(--secondary60);
    /* box-shadow: 0px 2px 5px rgb(0, 0, 0, 0.5); */
}

.calendar_option {
    border: 1px solid var(--primary70);
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    width: 15rem;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
}


.calendar_connect {
    border: 1px solid var(--primary5);
    background-color: var(--primary70);
    color: var(--bg);
}

.calendar_connect:hover {
    background-color: var(--primary80);
    color: var(--bg);
    box-shadow: 0px 2px 5px var(--secondary60);
}

.calendar_sync {
    color: var(--primary)
}

.calendar_sync:hover {
    background: var(--primary80);
    border: 1px solid var(--secondary5);
    box-shadow: 0px 2px 5px var(--secondary60);
    color: var(--bg);
    
}


.day_actions {
    display: flex;
    /* align-items: center; */
}

.add_new_day {
    cursor: pointer;
    padding-top: 0.3rem;
    color: var(--primary);
    margin-right: 0.5rem;
}

.copy_day {
    cursor: pointer;
    padding-top: 0.3rem;
    color: var(--primary);
    height: 1rem;
}


.copy_day_popup_overlay {
    position: absolute;
    background-color: transparent;
    z-index: 1000;
}

.copy_day_popup {
    position: absolute;
    background-color: var(--bg);
    border: 1px solid var(--secondary40);
    padding: 1rem;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 10rem;
}

.copy_day_popup h4 {
    margin-bottom: 0.5rem;
    font-size: 0.9rem;
}

.copy_day_option {
    display: flex;
    align-items: center;
    margin-bottom: 0.3rem;
}

.copy_day_option input[type="checkbox"] {
    margin-right: 0.3rem;
}

.copy_day_option label {
    cursor: pointer;
    font-size: 0.8rem;
}

.copy_day_actions {
    display: flex;
    justify-content: space-between;
    margin-top: 0.75rem;
}

.copy_day_actions button {
    padding: 0.3rem 0.6rem;
    margin-left: 0.3rem;
    border: 1px solid var(--secondary40);
    border-radius: .5rem;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 0.8rem;

}

.copy_day_actions button:last-child {
    background-color: var(--primary80);
    color: var(--bg);
}


.copy_day_actions button:last-child:hover {
    background-color: var(--primary);
}

.copy_day_actions button:first-child {
    background-color: var(--secondary80);
    color: var(--bg);
}

.copy_day_actions button:first-child:hover {
    background-color: var(--secondary);
}
