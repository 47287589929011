.settings_emails {
    /* border: 1px solid red; */
    width: 100%;
    height: 82.5%;
}

.settings_emails_category:first-of-type {
    border-top: 1px solid var(--secondary40);
}
 
.settings_emails_category {
    border-bottom: 1px solid var(--secondary40);
    padding: 2rem 0rem;
    margin: 0rem 1rem;
}

.settings_emails_templates {
    height: 22.5rem;
}

.settings_emails_category_heading {
    margin: 0;
    font-weight: 500;
    font-size: 1.1rem;
}

.settings_emails_category_body {
    height: 95%;
    margin-top: 1.5rem;   
}

.settings_emails_templates_section_switcher {
    margin-bottom: 2rem;
}

.subnav_item {
    cursor: pointer;
}

.subnav_item p {
    margin: 0;
}




.settings_email_templates {
    padding: 2rem;
    box-sizing: border-box;
    border-bottom: 1px solid var(--secondary);
}

.email_template_category_holder:first-of-type {
    border-top: 0.75px solid var(--secondary40);
}

.email_template_category_holder {
    border-bottom: 0.75px solid var(--secondary40);
    
}

.email_template_category {
    padding: 0.75rem 0rem;
    padding-right: 1rem;
    /* border-top: 2px solid var(--sectionBackground); */
    font-size: 0.8rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.email_template_category h3, .email_template_category_collapsible_first h3{
    font-weight: 400;
}

.settings_emails_templates_section {
    /* border: 1px solid green; */
    height: 80%;

}

.email_templates_holder {
    height: 80%;
    overflow-y: auto;
}

.action_email_templates_holder {
    height: 100%;
}

.email_template_category_collapsible_trigger_holder {
    background: none;
    width: 100%;
    border: none;
    margin: 0;
    padding: 0;
}


.email_template_category_trigger {
    background: none;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 0rem;
    box-sizing: border-box;
    padding-right: 1rem;
    font-size: 0.8rem;
    /* padding-right: 1rem; */
    /* border: 1px solid red; */
}


.email_template_subcategory_holder { 
    border-bottom: 0.5px solid var(--secondary40);
}   

.email_template_subcategory {
    width: 100%;
    /* border: 1px solid red; */
    display: flex;
    justify-content: space-between;
    padding: 0.75rem 0rem;
    box-sizing: border-box;
    padding-right: 1rem;
    font-size: 0.8rem;
    cursor: pointer;
}

.email_template_subcategory p {
    margin: 0;
}

.email_create_new_template {
    justify-content: flex-start;
}

.email_create_new_template_holder {
    margin-top: 1rem;
    width: 20rem;
    border-radius: 0.5rem;
    /* padding: 0.25rem 0rem; */
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1px solid var(--secondary40);
    transition: all 0.3s ease;
}

.email_create_new_template_holder:hover {
    background: var(--secondary40);
    border: 1px solid var(--secondary5);
    box-shadow: 0px 2px 5px var(--secondary40);
}