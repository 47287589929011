.interview_transcript_holder {
    overflow-y: auto;
    width: 100%;
    height: 100%;
}

.interview_transcript_message:first-of-type {
    border-top: 1px solid var(--secondary40);
}

.interview_transcript_message {
    border-bottom: 1px solid var(--secondary40);
    padding: 0.5rem 1rem;
    box-sizing: border-box;
}

.interview_transcript_message_role {
    font-weight: 550;
    font-size: 1.1rem;
}