.skillscore_heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    /* border-top: 1px solid var(--secondary40); */
    border-bottom: 1px solid var(--secondary40);
}

.skillscore_info_column {
    width: 25%;
    border-right: 1px solid var(--secondary40);
    padding: 1rem 0rem;
    box-sizing: border-box;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.skillscore_content_column {
    width: 75%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 0rem;
    box-sizing: border-box;
    height: 100%;
}

.skillscore_heading_text {
    font-weight: 500;
    font-size: 1.2rem;
    margin: 0;
    width: 100%;
    text-align: center;
}

.skillscore_row {
    width: 100%;
    display: flex;
    border-bottom: 1px solid var(--secondary40);
    justify-content: space-between;
    align-items: center;
    height: 40%;
}

.skillscore_category {
    height: 85%;
    width: 28%;
    aspect-ratio: 1/1;
    border: 1px solid var(--secondary40);
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    margin-right: 2rem;
    padding: 0.75rem 1rem;
    box-sizing: border-box;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
}

.skillscore_category:hover {
    border: 1px solid var(--primary);
}

.skillscore_category:last-of-type {
    margin-right: 0;
}

.skillscore_info_item {
    padding: 1rem 0rem;
    border-bottom: 1px solid var(--secondary40);
    width: 100%;
}

.skillscore_info_item_body {
    margin: 0;
}

.skillscore_info_item_heading {
    font-weight: 550;
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
}