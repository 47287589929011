/* .view_jobs_page_holder {
    border: 1px solid var(--secondary40);
    width: 7.5rem;
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
} */

.view_jobs_page_link {
    color: var(--text);
    text-decoration: none;

    border: 1px solid var(--secondary40);
    width: 7.5rem;
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
}