.jobs_page_content {
    padding: 2rem;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
}

.jobs_page_heading {
    margin-bottom: 2rem;
}

.all_job_postings {
    display: flex;
    flex-direction: column;
    height: 90%;
    width: 100%;
    box-sizing: border-box;
}

.jobs_page_header {
    display: flex;
    align-items: center;
    width: 100%;
}

.jobs_page_header_left {
    width: 20%;
}

.jobs_page_header_info {
    width: 7.5%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.jobs_page_header_search {
    width: 62.5%;
    display: flex;
    align-items: center;
}

.create_new_job {
    width: 10%;
}

.searchBar {
    width: 100%;
    border-radius: 50px;
    padding: 1rem;
    box-sizing: border-box;
    max-height: 3rem;
    /* margin-bottom: 2rem; */
    font-size: 1rem;
    /* border: 0.5px solid lightgray; */
    border: 0;
    /* box-shadow: 0px 2px 5px rgb(0, 0, 0, 0.2); */
    background: none;
}

.searchBar:focus {
    outline: none;
}



.create_new_job {
    border-radius: 1.5rem;
    border: .5px solid lightgray;
    box-shadow: 0px 2px 10px var(--secondary20);
    height: 2.5rem;
    width: 10rem;
    /* padding: 0.5rem 1rem; */

    background-color: var(--primary);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;
}

.create_new_job:hover {
    box-shadow: 0px 2px 5px var(--secondary80);
    transform: scale(1.025);
}

.create_new_job a {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    display: flex;
    text-decoration: none;
    justify-content: center;
    align-items: center;
    color: var(--background);
}


.jobs_page_body {
    display: flex;
    width: 100%;
    height: 92.5%;
    min-height: 92.5%;

    padding: 0.5rem 0rem;
    box-sizing: border-box;
}

.jobs_page_sort_filters {
    width: 20%;

    display: flex;
    flex-direction: column;

}

.jobs_hide_filled {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.jobs_hide_filled p {
    margin: 0;
}

.jobs_filter_by_department {
    padding: 0.25rem 0rem;
    height: 85%;
}

.jobs_filter_departments_container {
    overflow-y: auto;
    /* border: 1px solid red; */
    height: 85%;

    background-color: var(--secondary5);
    border: 1px solid var(--secondary40);

   
    box-sizing: border-box;
    border-radius: 0.5rem;
}

.jobs_holder {
    width: 80%;
    height: 100%;

    overflow-y: auto;
    padding: 0rem 0.5rem;
    box-sizing: border-box;
}

.job {
    position: relative;

    display: flex;
    align-items: center;

    border-bottom: 1px solid var(--secondary40);

    padding: 0.75rem 0rem;
}

.job_link {
    width: 100%;
    text-decoration: none;
    color: var(--text);
}

.job_posting {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0rem 1rem;
    box-sizing: border-box;
}

.job_posting_info {
    display: flex;
}

.job_posting_details {
    margin-right: 0.5rem;
}

.job_status {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    margin-bottom: 0.5rem;
    background-color: var(--secondary50);
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    width: 5rem;
    height: 1rem;
    font-size: 0.8rem;
}

.job_status.filled {
    background-color: rgba(0, 128, 0, 0.5);
}


.job_status p {
    margin: 0;
}

.job_department {
    display: flex;
    align-items: flex-end;
    padding: 0.1rem 0rem;
    box-sizing: border-box;
    gap: 0.5rem;

}

.job_department p {
    margin: 0;
    opacity: 0.8;
    font-size: 1.1rem;
}

.no_jobs_yet {
    padding: 0.5rem 2rem;
    box-sizing: border-box;
    font-size: 1.2rem;
    opacity: 0.7;
}

/* 

.job_posting_industry {
    display: block;
    text-align: left;
    justify-content: left;
    margin-bottom: 1rem;
}

.job_posting_industry h1 {
    margin: 0;
    padding: 0;
}

.job_posting_holder:first-of-type {
    border-top: 1px solid var(--secondary40);
}

.job_posting_holder {
    border-bottom: 1px solid var(--secondary40);
}

.job_posting_link {
    width: 90%;
    text-decoration: none;
    color: black;
}

.job_posting {
    width: 100%;
    height: 7.5rem;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    box-sizing: border-box;
}

.job_posting h2 {
    padding: 0;
    margin: 0;
    margin-bottom: 1.5rem;
}

.end_of_posting {
    display: flex;
    align-items: center;
}

.all_jobs {
    height: 80vh;
}


.all_jobs_area {
    overflow-y: auto;
    height: 100%;
}

.filled_jobs_area {
    margin-top: 5rem;
}


.filled_jobs_area h1 {
    margin-bottom: 2rem;
}

.no_jobs_yet {
    width: 100%;
}

.no_jobs_yet p {
    margin: 0;
    font-size: 1.2rem;
    color: rgba(0, 0, 0, 0.6);
} */