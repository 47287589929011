.alert_item {
    border-bottom: 1px solid var(--secondary40);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0.5rem;
    box-sizing: border-box;
    cursor: pointer;
}

.alert_item p {
    margin: 0;
}

.alert_item_info_date {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.alert_item_info_date p {
    margin: 0;
}

.alert_item_actions_icon {
    font-weight: 800;
}