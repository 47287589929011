.top_section {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.stage_delete.interview  {
    position: absolute;
    bottom: 2rem;
    left: 2rem;
}

.stage_save.interview {
    position: absolute;
    bottom: 2rem;
    right: 2rem;
}


.interview_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 24%;
    margin: 1rem 0rem;
}

.interview_bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 74%;
}

.interview_bottom_first {
    width: 49%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.interview_bottom_first .interview_section {
    width: 100%;
}

.interview_section {
    background: var(--secondary5);
    border: 1px solid var(--secondary40);
    border-radius: 0.5rem;
    width: 49%;
    height: 100%;
    padding: 1rem;
    box-sizing: border-box;
    position: relative;
}

.feedback_section {
    height: 32%;
}

.interviewers_section {
    height: 63.5%;
}

.interview_section_heading {
    margin-bottom: 1rem;
}



.interview_section_number_input_holder {
    display: flex;
    align-items: center;
}

.interview_section_number_input {
    background-color: var(--secondary10);
    border: 1px solid var(--secondary40);
    border-radius: 0.5rem;
    outline: none;
    padding: 0.25rem 0.5rem;
    margin-right: 0.5rem;
    width: 2rem;
    font-family: "sans-serif" !important;
    font-size: 1rem;
    text-align: right;
}


.notes_input {
    width: 100%;
    height: 90%;
    border: none;
    box-sizing: border-box;
    font-size: 1rem;
    font-family: "Inter", sans-serif;
    background: none;
    resize: none;
}

.interviewers_input_container {
    display: flex;
    align-items: center;
    width: 100%;
    /* border: 1px solid red; */
}


.interviewers_input_area {
    padding: .5rem;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: .3rem;
    width: 100%;
    /* border-bottom: 2px solid #ccc; */
    font-size: 16px;
    outline: none;
}


.interviewers_list {
    margin-top: 10px;
}

.interviewer_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    border-bottom: 1px solid var(--secondary40);
}

.interviewer_icon {
    /* margin: 0 10px; */
    color: black;
    font-size: 1rem;

}

.delete_interviewer {
    cursor: pointer;
    margin-left: 10px;
}

.autosuggest-container {
    position: relative;
    width: 100%;
}

.interviewers_input_container {
    /* border: 1px solid red; */
}

.interviewers_input_area {
    background: none;
    border: 1px solid var(--secondary40);
    font-family: "Inters", sans-serif;
}

.suggestions-container {
    position: absolute;
    /* top: 40px; */
    width: 100%;
    border-radius: 5px;
    background: var(--bg);
    /* background: var(--secondary5); */
    z-index: 10;
    /* border: 1px solid var(--secondary40); */
    /* border-top-width: 0; */
    box-shadow: 0px 2px 5px var(--secondary20);
}

  .suggestions-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    background-color: var(--secondary5);
    border-radius: 0.5rem;
    overflow: hidden;
  }

  .suggestion-item {
    padding: 10px;
    border-bottom: 1px solid var(--secondary40);
    /* border-radius: 0.5rem; */
    cursor: pointer;
  }

  .suggestion-item:last-child {
    border-bottom: none;
  }

  .suggestion-item:hover {
    background-color: var(--secondary40);
  }


.interview_heading {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
}

.interview_heading_icon {
    font-size: 1.5rem;
    margin-right: 0.5rem;
}

.create_stage_heading.input {
    font-size: 2em;
    font-weight: bold;
    border: none;
    outline: none;
    background: none;
    text-align: left;
    margin-bottom: 0rem;
}

.create_stage_heading {
    outline: none;
}

.interview_node_switcher {
    position: relative;
    display: flex;
    margin-bottom: 1.75rem;
}

.interview_node_switcher div {
    margin-right: 4rem;
}

.interview_detail {
    display: flex;
    align-items: center;
}

.interview_detail_heading {
    margin-right: 0.5rem;
}

.interview_detail_input {
    background: none;
    border: 1px solid var(--secondary40);
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    outline: none;
    width: 20rem;
    margin-right: 1rem;
}

.interview_detail_save {
    background-color: var(--primary40);
    border: 1px solid var(--primary5);
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;
}

.interview_detail_save:hover {
    background-color: var(--primary80);
    border: 1px solid var(--primary5);
}

.interview_detail_save:active {
    background-color: var(--primary20);
    border: 1px solid var(--primary40);
}

.interview_card {
    background: var(--secondary5);
    border: 1px solid var(--secondary40);
    border-radius: 0.5rem;
    width: 49.5%;
    height: 100%;
    padding: 1rem;
    box-sizing: border-box;
    position: relative;
}

.interview_card_heading {
    margin-bottom: 1rem;
}

.interview_card_input {
    background-color: transparent;
    border: none;
    border-radius: 0.5rem;
    outline: none;
    padding: 0.25rem 0.5rem;
    margin-right: 0.5rem;
    width: 15rem;
    font-family: "Inter";
    font-size: 1rem;
}

.interview_stage_body {
    height: 72.5%;
    min-height: 72.5%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.interview_section_holder {
    height: 72.5%;
    min-height: 72.5%;
    display: flex;
    flex-direction: column;
}

.interview_top_row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 22.5%;
}

.interview_bottom_row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 75.5%;
}

.interview_bottom_left_row {
    height: 100%;
    width: 49.5%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.duration_card {
    height: 28.5%;
    width: 100%;
}

.location_card {
    height: 68.5%;
    width: 100%;
}

.location_buttons {
    display: flex;
    padding: 1rem 0rem;
    margin-bottom: 1rem;
}

.location_buttons button {
    background-color: var(--secondary10);
    border: 1px solid var(--secondary40);
    border-radius: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 8rem;
    height: 3rem;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-right: 2rem;
}

.location_buttons button.active {
    background-color: var(--secondary50);
}

.location_input {
    background: none;
    border: 1px solid var(--secondary40);
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    outline: none;
    width: 20rem;
    margin-right: 1rem;
}

.interview_section_text {
    font-weight: 500;
}

.interview_section_select {
    background-color: transparent;
    border: 1px solid var(--secondary40);
    outline: none;
    padding: 0.5rem 1rem;
    width: 10rem;
    height: 2.5rem;
    border-radius: 0.5rem;
}


.interview_section_incomplete {
    position: absolute;
    top: 0px;
    right: -6rem;
    /* border: 1px solid red; */
    background-color: var(--accent);
    color: var(--bg);
    font-weight: 300;
    padding: 0.2rem;
    font-size: 0.8rem;
    box-sizing: border-box;
    width: 1rem;
    height: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transform: translate(-50%, -50%);
}


.save_load_interview_node {
    position: absolute;
    left: 2rem;
    top: 2rem;
    display: flex;
    gap: 0.5rem;
}

.save_interview_node {
    background-color: var(--secondary60);
    border: 1px solid var(--secondary40);
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 8.5rem;
    transition: all 0.3s ease;
}

.save_interview_node:hover {
    background-color: var(--secondary80);
}

.load_interview_node {
    background-color: transparent;
    border: 1px solid var(--secondary40);
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 8.5rem;
    transition: all 0.3s ease;
}

.save_interview_node_button {
    background-color: var(--secondary60);
    border: 1px solid var(--secondary40);
    border-radius: 1rem;
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 8.5rem;
    transition: all 0.3s ease;
}

.load_interview_node:hover {
    background-color: var(--secondary10);
}

.save_interview_node_button:hover {
    background-color: var(--secondary80);
}

.create_interview_override_save {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.save_interview_node_select {
    border: 1px solid var(--secondary40);
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    outline: none;
    width: 10rem;
    background-color: transparent;
}

.create_interview_create_node_span {
    text-decoration: underline;
    cursor: pointer;
    color: var(--primary);
}

.save_interview_subsection {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.save_interview_node_input {
    border: 1px solid var(--secondary40);
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    outline: none;
    width: 12.5rem;
    background-color: transparent;

}