.settings_holder {
    width: 100%;
    height: 100%;
    padding: 2rem;
    box-sizing: border-box;
}

.settings_title {
    /* font-size: 2rem; */
    margin-bottom: 2rem;
}


.subnav {
    display: flex;
}


.subnav_item {
    position: relative;
    color: black;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.5s ease;
    padding: 0;
    margin-right: 2rem;
}

.subnav_item a {
    margin: 0;
    padding: 0;
}

.subnav_item::after {
    content: '';
    position: absolute;
    background-color: black;
    height: 3px;
    width: 0%;
    left: 0;
    transition: width 0.5s ease;
    bottom: -8px;
}

.subnav_item:hover {
    transform: scale(1.05);
}

.subnav_item:hover::after {
    width: 100%;
}

.active_subnav_item::after {
    content: '';
    position: absolute;
    background-color: black;
    height: 3px;
    width: 100%;
    left: 0;
    bottom: -8px;
}
