.move_job_select_holder {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 1rem 0rem;
    box-sizing: border-box;
}

.move_job_select_label {
    margin-right: 0.5rem;
}

.move_job_select {
    outline: none;
    border: 1px solid var(--secondary40);
    background-color: transparent;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    cursor: pointer;
}