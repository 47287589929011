.reports_holder {
    padding: 2rem;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
}

.reports_selector {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
}

.reports_selection_text {
    margin-right: 1rem;
}

.reports_selection_option {
    background: none;
    color: black;
    box-sizing: border-box;
    width: 10rem;
    height: 2rem;
    padding: 0 .5rem;
    border: 1px solid lightgray;
    box-shadow: 0px 2px 2px rgb(0, 0, 0, 0.1);
    border-radius: .2rem;
    font-size: 1rem;
    font-family: "Inter", sans-serif;
    outline: none;
}

.reports_selection_option::placeholder {
    color: lightgray;
    opacity: 0.1;
}

.reporting_area {
    width: 80vw;
    height: 80vh;
    padding: 1rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.company_report_chart_row {
    height: 48%;
    display: flex;
    justify-content: space-between;
}

.company_report_chart_section {
    width: 49%;
    height: 100%;
    padding: 1rem;
    box-sizing: border-box;
    border-radius: 1rem;
    border: 1px solid var(--secondary20);
    background-color: var(--secondary5);
}

.company_report_chart_holder {
    height: 100%;
}

.company_report_chart_heading_holder {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 0.5rem;
}


.company_report_chart_heading {
    font-weight: 550;
    margin: 0;
    margin-bottom: 1rem;
} 

.company_report_chart_heading_holder p {
    margin-bottom: 0;
}

.company_report_timeframe_select {
    height: 2rem;
}

.company_report_chart {
    height: 90%;
    width: 100%;
}



/* Handling specific charts */

.time_to_hire_chart {
    height: 15%;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0rem 1rem;
}

.time_to_hire_chart p {
    margin-bottom: 0;
    margin-right: .5rem;
}

.company_pie_charts {
    display: flex;
    height: 85%;
    width: 100%;
    padding: 0.5rem 1rem;
    box-sizing: border-box;
}

.jobs_filled_chart, .application_source_chart {
    width: 50%;
    height: 100%;
}


.company_report_timeframe_select {
    background: none;
    color: black;
    box-sizing: border-box;
    width: 10rem;
    height: 2rem;
    padding: 0 .5rem;
    border: 1px solid var(--secondary40);
    border-radius: .5rem;
    font-size: 1rem;
    font-family: "Inter", sans-serif;
    outline: none;
}


/* 
.reports_row {
    display: flex;
    border-top: .25px solid lightgray;
    border-bottom: .25px solid lightgray;
    height: 30%;
    padding: 2rem 0rem;
}

.reports_row_title {
    border-right: .5px solid lightgray;
    width: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5%;
}

.reporting_chart {
    width: 20%;
    aspect-ratio: 16 / 9;
    border: 1px solid lightgray;
    margin-right: 2.5%;
} */