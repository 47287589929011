
.template_delete {
    border-radius: 0.5rem;
    border: 0.5px solid lightgray;
    box-shadow: 0px 2px 5px rgb(255,0,0,0.2);
    color: red;
    padding: 0.5rem 1rem;
    width: 5rem;
    text-align: center;
    cursor: pointer;
    height: 1.2rem;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.template_delete:hover {
    transform: scale(1.025);
    box-shadow: 0px 2px 5px rgb(255,0,0,0.35);
}
