.interviewers_heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5rem;
}

.add_interviewers {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--secondary60);
    border: 1px solid var(--secondary10);
    border-radius: 1rem;
    width: 8.5rem;
    height: 2.5rem;
    transition: all 0.3s ease;
    font-size: 1rem;
    color: var(--text);
    cursor: pointer;
}

.add_interviewers:hover {
    background-color: var(--secondary);
    box-shadow: 0px 2px 5px var(--secondary40);
    transform: translateY(-2.5px) scale(1.025);
}

.interviewers_body {
    border: 1px solid var(--secondary40);
    background: var(--secondary5);
    border-radius: 2rem;
    padding: 1rem;
    box-sizing: border-box;
    height: 80%;
    overflow-y: auto;
}

.add_interviewer_subnav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    width: 65%;
    margin-bottom: 1rem;
}

.add_interviewer_section {
    width: 100%;
    height: 67.5%;
}


.add_interviewer_section_option {
    background-color: var(--secondary10);
    border-radius: 1.5rem;
    width: 12.5rem;
    height: 3rem;
    border: 1px solid var(--secondary40);
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
}  

.add_interviewer_section_option.selected {
    background-color: var(--secondary50);
}

.add_interviewer_section_top_row {
    display: flex;
    align-items: center;
    width: 100%;
    height: 25%;
    margin-bottom: 2%;
}

.add_interviewer_section_text {
    width: 60%;
}

.add_interviewer_subsection {
    width: 40%;
    height: 100%;
    border: 1px solid var(--secondary40);
    border-radius: 1rem;
    background-color: var(--secondary5);
    padding: 1rem;
    box-sizing: border-box;
}

.add_interviewer_selection_pool {
    height: 70%;
    width: 100%;
    background-color: var(--secondary5);
    border-radius: 1rem;
    border: 1px solid var(--secondary40);
    padding: 1rem;
    box-sizing: border-box;
}

.add_interviewer_selection_pool>p {
    margin: 0;
    margin-bottom: 0.5rem;
}

.interviewers_list {
    height: 70%;
    overflow-y: auto;
}

.add_interviewer_selected_interviewers {
    border: 1px solid var(--secondary40);
    background-color: var(--secondary5);
    border-radius: 1rem;
    height: 85%;
    padding: 1rem;
    box-sizing: border-box;
}

.interviewers_list.specific {
    height: 83.5%;
}


.interviewer_group_item {
    padding: 0.5rem 1rem;
    border-bottom: 1px solid var(--secondary40);
}

.interviewers_sublist {
    max-height: 7.5rem;
    overflow-y: auto;
    padding: 0rem 3rem;

    box-sizing: border-box;
}