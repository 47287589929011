.all_pipeline_holder {
    height: 100vh; 
    width: 100%;
    max-width: 100%;
    padding-bottom: 18vh;
    box-sizing: border-box;
}


.pipeline_container {
    display: flex;
    align-items: center;
    padding: 1rem;
    box-sizing: border-box;
    /* padding-right: 25%; */
    border-radius: .5rem;
    width: 100%;
    max-width: 100%;
    overflow-y: hidden;
    overflow-x: scroll;
    height: 95%;
}

.stage:first-of-type {
    border-left: 1px solid var(--secondary40)
}

.stage {
    min-width: 13.5rem;
    width: 13.5rem;
    /* max-width: 25%; */
    /* margin-left: 0.5rem;
    margin-right: 0.5rem; */
    margin-bottom: 0.5rem;
    overflow-x: hidden;
    min-height: 100%;
    height: 100%;
    max-height: 100%;
    box-sizing: border-box;
    padding: 1rem 0.25rem;
    border-right: 1px solid var(--secondary40);
}

.addStage {
    border: 1px dashed lightgray;
    box-shadow: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    font-weight: 100;
    color: rgb(0, 0, 0, 0.5);
    cursor: pointer;
}


.stage h3 {
    margin: 0;
    padding: 0;
}

.dropArea {
    height: 90%; 
    max-height: 90%;
    overflow-y: scroll;
    border-radius: .5rem;
    box-sizing: border-box;
    padding: 0.75rem 0.5rem;
    margin: 1.5rem 0.5rem 0 0.5rem ;

}

.screen_action_button_area {
    padding: 0.5rem;
    box-sizing: border-box;
}

.screen_action_button {
    background-color: rgb(0, 0, 0, 0.8);
    border-radius: .2rem;
    display: flex;
    justify-content: center;
    padding: 0.5rem 1rem;
    cursor: pointer;
    color: white;
    transition: transform 0.25s ease, background-color 0.25s ease, box-shadow 0.25s ease;
}


.screen_action_button:hover {
    background-color: rgb(0, 0, 0, 1);
    box-shadow: 0 0 2px rgb(0, 0, 0, 0.2);
    transform: scale(1.025);
}

.candidate_link:first-of-type {
    border-top: 1px solid var(--secondary40);
}

.candidate_link {
    border-bottom: 1px solid var(--secondary40);
}

.candidate_pipeline {
    
    /* margin-bottom: .5rem; */
    box-sizing: border-box;
    border-radius: .2rem;
    padding: .5rem;
    /* box-shadow: 0px 2px 2px rgb(0, 0, 0, 0.1); */
    height: 3rem;
    cursor: pointer;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.candidate_pipeline h3 {
    font-weight: 400;
    font-size: 0.9rem;
}

.candidate_left_side {
    /* border: 1px solid red; */
    display: flex;
    align-items: center;
    width: 70%;
}

.candidate_right_side {
    width: 30%;
}

.candidate_opened {
    width: 0.5rem;
    margin-right: 0.75rem;
}

.glowing-indicator {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: rgb(8, 154, 8, 0.8);
    box-shadow: 0 0 15px rgb(8, 154, 8);
}

.pipeline_options {
    width: 95%;
    max-width: 95%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 1.5rem;
}

.addNewStage_popup {
    background: gray;
    transform: translateX(-100%);
}

.stage_top_section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 100%;
    width: 100%;
    padding: 0rem 1rem;
    box-sizing: border-box;
}

.stage_top_section .stage_move_arrows {
    cursor: pointer;
    opacity: 0.8;
}

.stage_title_center {
    width: 100%;
    display: flex;
    padding: 0rem 1rem;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
}

.stage_title_center h3 {
    font-size: 1rem;
}


.stage_bottom_holder {
    height: 100%;
}

.review_candidates_holder {
    width: 60%;
    height: 2rem;
    background: linear-gradient(45deg, var(--secondary) 0%, var(--accent) 100%);
    border-radius: 1rem;
    padding: 0.1rem;
}

.review_candidates {
    width: 100%;
    height: 100%;
    padding: 0.5rem 1rem;
    box-sizing: border-box;
    transition: all 0.3s ease;
    border-radius: 1rem;
    background-color: var(--bg);
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
}

.review_candidates:hover {
    box-shadow: 0px 2px 5px rgb(0, 0, 0, 0.25);
    background-color: linear-gradient(45deg, var(--secondary) 0%, var(--accent) 100%);
    color: var(--bg);
}

