.skillscore_category_heading {
    text-align: center;
    margin: 0;
    font-weight: 500;
    height: 20%;
}

.skillscore_category_expand {
    display: flex;
    width: 100%;
    justify-content: center;
    height: 40%;
    align-items: center;
    border-bottom: 1px solid var(--secondary40);
}

.weightage_section {
    padding: 0.5rem 0rem;
    box-sizing: border-box;
    height: 40%;
}

.weightage_top_row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
}

.weightage_top_row p {
    margin: 0;
}

.skillscore_subcategory_holder {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 0rem 2rem;
    box-sizing: border-box;
    justify-content: space-between;
    height: 80%;
}

.skillscore_subcategory {
    width: 47.5%;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--secondary40);
    padding: 1rem;
    box-sizing: border-box;
    border-radius: 0.5rem;
    text-align: center;
    height: 100%;
    position: relative;
}

.skillscore_subcategory_heading {
    margin: 0;
    height: 15%;
    font-weight: 500;
}

.skillscore_subcategory_body {
    height: 50%;
    border-bottom: 1px solid var(--secondary40);
}

.skillscore_subcategory_body p {
    /* font-weight: 500; */
    font-size: 0.9rem;
    opacity: 0.75;
    text-align: left;
    /* color: var(--primary80); */
}

.subcategory_weightage_section {
    padding: 1rem 0rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 40%;
}

.skillscore_subcategory_body_emphasis {
    color: var(--primary);
    font-weight: 500;
}

/* HANDLING THE SKILLS INPUT SECTION */
.skillscore_category_input_holder {
    width: 100%;
}

.skillscore_category_input {
    width: 100%;
}

.skillscore_category_input input {
    width: 100%;
    outline: none;
    background: none;
    padding: 0.5rem 1rem;
    box-sizing: border-box;
    border-radius: 0.5rem;
    border: 1px solid var(--secondary40);
}

.skillscore_category_list {
    width: 100%;
    height: 90%;
    margin-top: 1rem;
    box-sizing: border-box;
    overflow-y: auto;
    box-shadow: inset 0px 0px 5px var(--secondary40);
    background: var(--secondary5);
    border-radius: 0.5rem;
}

.skillscore_category_list_item {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    
    box-sizing: border-box;
    /* border-radius: 0.5rem; */
    border-bottom: 1px solid var(--secondary40);
   
    border-top: none;
    /* margin-bottom: 1rem; */
}

.skillscore_category_list_item:first-of-type {
    border-top: 1px solid var(--secondary40);
}

.skillscore_category_list_item_name {
    width: 35%;
    height: 100%;
}

.skillscore_category_list_item_weightage_holder {
    width: 55%;
    border-left: 1px solid var(--secondary40);
    border-right: 1px solid var(--secondary40);
    padding-left: 5%;
    padding-right: 5%;
    box-sizing: border-box;
}

.skillscore_category_instructions {
    margin: 0;
    margin-bottom: 0.75rem;
    margin-left: 0.75rem;
    text-align: left;
    width: 100%;
    opacity: 0.65;
}

.delete_icon_holder {
    width: 10%;
    text-align: right;
}

.delete_icon {
    cursor: pointer;
}

/* HANDLING THE SLIDER CUS THE OTHER ONE NEEDS A DUMB FUCKING PADDING TO WORK */

.skillscore_weightage_slider {
    padding-right: 0;
}

/* HANDLING THE TECHNICAL SKILLS */

.technical_skills_instructions_area {
    display: flex;
    justify-content: space-between;
    width: 100%;
}