.schedule_meeting_holder {
    padding: 2rem;
    box-sizing: border-box;
    width: 100%;
}

.schedule_meeting_area {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 60%;
}

.schedule_meeting_attendees_info {
    width: 39%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.schedule_meeting_candidate_availability {
    height: 60%;
}

.schedule_meeting_interviewers {
    height: 37.5%;
    box-sizing: border-box;
}

.schedule_meeting_calendar {
    width: 60%;
    height: 100%;
    max-height: 100%;
}




.schedule_meeting_candidate_availability_days {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 85%;
    background-color: var(--secondary5);
    border-radius: 1rem;
    border: 1px solid var(--secondary40);
    overflow: hidden;
    overflow-x: auto;
    
}

.schedule_meeting_candidate_availability_day:last-of-type {
    border-bottom: none;
}

.schedule_meeting_candidate_availability_day {
    display: flex;
    width: max(100%, max-content);
    height: 19%;
    flex: 1;
    border-bottom: 1px solid var(--secondary40);
    transition: all 0.3s ease;
    cursor: pointer;
}

.schedule_meeting_candidate_availability_day:hover {
    background-color: var(--secondary15);
}

.schedule_meeting_candidate_availability_day>p{
    margin: 0rem;
}

.candidate_availability_day_title {
    width: 20%;
    padding: 0.5rem 0rem;
    /* border-right: 1px solid var(--secondary40); */
    display: flex;
    align-items: center;
    justify-content: center;
}

.candidate_availability_timeslots {
    width: 80%;
    height: 100%;
    padding: 0.35rem 0rem;
    /* overflow-x: auto; */
    display: flex;
    align-items: center;
    box-sizing: border-box;
    font-size: 0.8rem;
}

.candidate_availability_timeslot {
    border: 1px solid var(--secondary20);
    background-color: var(--secondary10);
    border-radius: 0.5rem;
    /* border-top: none;
    border-bottom: none; */
    /* box-shadow: 0px 2px 5px 1px var(--secondary10); */
    padding: 0.5rem 0.35rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10rem;
    min-width: 10rem;    
    height: 100%;
    min-height: 100%;
    margin-right: 0.5rem;
    /* background-col/or: var(--bg); */
    /* box-shadow: 0px 2px 5px 0px var(--bg); */
}

.schedule_meeting_interviewers_list {
    border: 1px solid var(--secondary40);
    /* padding: 1rem; */
    background-color: var(--secondary5);
    border-radius: 1rem;
    height: 85%;
    max-height: 85%;
}

.schedule_meeting_interviewers_list .interviewer_item {
    padding: 0.5rem 0rem !important;
}

.schedule_meeting_interviewers_list .interviewer_group_item {
    padding: 0.5rem 0rem !important;
}

/* .schedule_meeting_info_section {
    border: 1px solid red;
} */

.schedule_meeting_info_section>h3 {
    margin-bottom: 0.5rem;
}

.schedule_meeting_calendar {
    height: 500px;
    margin-bottom: 20px;
}

.selected-slot {
    background-color: #f0f0f0;
    padding: 15px;
    border-radius: 5px;
    margin-top: 20px;
}

.selected-slot button {
    margin-top: 10px;
    padding: 5px 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
}

.selected-slot button:hover {
    background-color: #45a049;
}

.calendar_display_right_side {
    width: 100%;
    height: 600px;
}

.selected-slot {
    margin-top: 20px;
    padding: 10px;
    background-color: #f0f0f0;
    border-radius: 5px;
}

.selected-slot button {
    margin-top: 10px;
    padding: 5px 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
}

.selected-slot button:hover {
    background-color: #45a049;
}

body .unavailable-day {
    background-color: #f9f9f9;
    color: #ccc;
}

body .available-time-slot {
    background-color: rgba(0, 255, 0, 0.1);
}

.rbc-time-view {
    border: none !important;
    border-radius: 1rem !important;
    overflow: hidden;
}

.rbc-allday-cell {
    height: 0px !important;
    overflow: hidden;
}

.rbc-row.rbc-time-header-cell {
    min-height: 50px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.rbc-header {
    /* border: none !important; */
    border-left: 1px solid var(--secondary40) !important;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: none !important;
}

.rbc-time-header-content {
    border-left: none !important;
    
}

.rbc-time-content {
    border-top: 1px solid var(--secondary40) !important;
}

.rbc-timeslot-group {
    border-bottom: 1px solid var(--secondary20) !important;
}

.rbc-time-content > * + * > * {
    border-left: 1px solid var(--secondary20) !important;
}

.rbc-time-gutter>.rbc-timeslot-group>.rbc-time-slot {
    background-color: transparent !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.rbc-events-container {
    margin-right: 0px !important;
}

.rbc-event-label {
    display: none !important;
}

.schedule_meeting_bottom_row {
    height: 32.5%;
    padding: 1rem 0rem;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
}

.schedule_meeting_suggested_times {
    width: 40%;
}

.schedule_meeting_confirm {
    width: 55%;
}

.suggested_times_area {
    background-color: var(--secondary5);
    border-radius: 1rem;
    border: 1px solid var(--secondary40);
    padding: 1rem;
    box-sizing: border-box;
    height: 87.5%;
    margin-top: 2.5%;
}

.suggested_times_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.confirm_area {
    background-color: var(--secondary5);
    border-radius: 1rem;
    border: 1px solid var(--secondary40);
    padding: 2rem;
    box-sizing: border-box;
    height: 87.5%;
    margin-top: 2.5%;
    position: relative;
    display: flex;
}

.confirm_area_button {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    border: 1px solid var(--secondary40);
    background-color: var(--primary60);
    transition: all 0.3s ease;
    padding: 0.5rem 1rem;
    width: 8rem;
    height: 2.5rem;
    border-radius: 1rem;
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.confirm_area_button:hover {
    background-color: var(--primary);
}

.confirm_area_no_event {
}

.confirm_area_no_event > p {
    font-size: 1.1rem;
    opacity: 0.5;
    font-weight: 400;
}

.confirm_area_icon {
    font-size: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 2rem;
}

.confirm_area_info {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
}

.confirm_area_info_section {
    display: flex;
    padding: 0.5rem 0rem;
}


.confirm_area_info_section_title {
    font-weight: 500;
    margin-right: 1rem;
    color: var(--primary)
}

.rbc-event-non-selectable {
    pointer-events: none;
    cursor: not-allowed;
}

.rbc-current-time-indicator {
    background-color: var(--primary) !important;
}

.interviewer_item.schedule_meeting_interviewer_item {
    padding: 0.5rem 1rem !important;
    box-sizing: border-box;
    transition: all 0.3s ease;
    cursor: pointer;
}

.interviewer_sub_item.schedule_meeting_interviewer_item {
    padding: 0.5rem 1rem !important;
    box-sizing: border-box;
    transition: all 0.3s ease;
    cursor: pointer;
}

.interviewer_item.schedule_meeting_interviewer_item:hover {
    background-color: var(--secondary10);
}

.interviewer_sub_item.schedule_meeting_interviewer_item:hover {
    background-color: var(--secondary10);
}

.suggested_times_grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 0.1rem; /* Reduced gap between rows */
    width: 100%;
    height: 100%;
}

.suggested_time_slot {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--secondary10);
    border: 1px solid var(--secondary20);
    border-radius: 8px;
    padding: 1rem;
    box-sizing: border-box;
    height: 92.5%;
    width: 95%;
    cursor: pointer;
    transition: all 0.3s ease;
}

.suggested_time_slot:hover {
    background-color: var(--secondary30);
    border-color: var(--secondary40);
}

.suggested_time_slot.active {
    background-color: var(--secondary50);
}
/* 
.suggested_time_slot.active:hover {
    background-color: var(--secondary50);
} */


.suggested_time_slot span {
    text-align: center;
}

.suggested_time_slot span:first-child {
    font-weight: bold;
    margin-bottom: 0.5rem;
}