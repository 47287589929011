.number_slider {
    width: 100%;
    box-sizing: border-box;
    /* padding: 0rem 0.5rem; */
    padding-right: 1rem;
}

.number_slider input[type="range"] {
    -webkit-appearance: none; /* Override default appearance */
    appearance: none;
    width: 100%; /* Full-width slider */
    height: 8px; /* Thickness of the slider */
    /* background: #ddd; Light grey background */
    background: none;
    outline: none; /* Removes the outline */
    opacity: 0.7; /* Partial transparency */
    transition: all 0.3s; /* Smooth transition for hover effect */
}

.number_slider input[type="range"]:hover {
    opacity: 1; /* Full opacity on hover */
}

/* Thumb (slider handle) styles */
.number_slider input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 0.75rem; /* Width of the thumb */
    height: 0.75rem; /* Height of the thumb */
    transform: translateY(-0.125rem); /* Centers the thumb vertically */
    background: var(--primary); /* Purple background */
    cursor: pointer; /* Cursor changes to a pointer when hovering over the thumb */
    border-radius: 35%; /* Rounded thumb */
    box-shadow: 0 0 4px var(--secondary40); /* Subtle shadow for depth */
    transition: all 0.3s ease;
}

.number_slider input[type="range"]::-moz-range-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 0.75rem; /* Width of the thumb */
    height: 0.75rem; /* Height of the thumb */
    transform: translateY(-0.125rem); /* Centers the thumb vertically */
    background: var(--primary); /* Purple background */
    cursor: pointer; /* Cursor changes to a pointer when hovering over the thumb */
    border-radius: 35%; /* Rounded thumb */
    box-shadow: 0 0 4px var(--boxShadow); /* Subtle shadow for depth */
    transition: all 0.3s ease;
}

/* Track styles */
.number_slider input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.45rem;
    cursor: pointer;
    background: var(--secondary); /* Lighter purple */
    border-radius: 5rem;
}

.number_slider input[type="range"]::-moz-range-track {
    width: 100%;
    height: 0.45rem;
    cursor: pointer;
    background: var(--secondary); /* Lighter purple */
    border-radius: 5rem;
}
