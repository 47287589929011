.pipeline_editor_page_holder {
    width: 100%;
    height: 100%;
}

.pipeline_editor_holder {
    flex-direction: column;
    display: flex;
    flex-grow: 1;
    width: 60rem;
    height: 30rem;

    /* border: 1px solid black; */

  }


  .pipeline_editor_holder aside {
    /* border-right: 1px solid #eee; */
    padding: 0rem 1rem;
    /* box-sizing: border-box; */
    font-size: 12px;
    /* background:; */
  }
  
  .pipeline_editor_holder aside .description {
    margin-bottom: 10px;
  }

.pipeline_editor_stages_section_head {
  margin-bottom: 0.75rem;
}
  
  .pipeline_editor_holder .pipeline_node {
    height: 20px;
    padding: 4px;
    border: 1px solid lightgray;
    background: var(--bg);
    box-shadow: 0px 2px 5px var(--boxShadow);
    border-radius: 0.5rem;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pipeline_node {
    cursor: grab;
  }
  
  .pipeline_editor_holder .pipeline_node.input {
    border-color: var(--secondary);
  }
  
  .pipeline_editor_holder .pipeline_node.output {
    border-color: #ff0072;
  }
  
  .pipeline_editor_holder .reactflow-wrapper {
    flex-grow: 1;
    height: 100%;
  }
  
  .dndflow .selectall {
    margin-top: 10px;
  }
  
  @media screen and (min-width: 768px) {
    .pipeline_editor_holder {
      flex-direction: row;
    }
  
    .pipeline_editor_holder aside {
      width: 20%;
      max-width: 250px;
    }
  }

.pipeline_editor {
    border: 1px ridge var(--secondary10);
    border-radius: 0.5rem;
    box-shadow: inset 0px 0px 5px var(--secondary30);
    background-image: radial-gradient(var(--primary30) 1px, transparent 0);
    background-size: 20px 20px;
}

.pipeline_editor_stages_section {
    border-bottom: .5px solid var(--secondary);
    padding: 1rem 0rem;
}

.pipeline_editor_stages_section:first-of-type {
    border-top: .5px solid var(--secondary);
}

.react-flow__pane {
    cursor: default;
}
