.resizable_panes_holder {
    height: 3rem;
    width: 100%;
    border: .5px solid lightgray;
    border-radius: .5rem;
    box-shadow: 0px 2px 5px rgb(0, 0, 0, 0.1);
    text-align: center;
}

.resizable_panels_group {
    border: none;
    border-radius: .5rem;

}



.panel {
    box-sizing: border-box;
    padding: 1rem;
    height: 3rem;
    overflow-y: hidden;
    /* max-width: 10%; */
    /* min-width: 10%; */
    border: .5px solid lightgray;
    display: flex;
    justify-content: center;
    align-items: center;
}