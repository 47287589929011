.scheduling_type {
    display: flex;
}

.scheduling_type>p {
    width: 17.5%;
}

.scheduling_type_options {
    width: 82.5%;
    padding: 0rem 5rem;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.scheduling_type_option {
    padding: 1rem;
    box-sizing: border-box;
    border: 1px solid var(--secondary40);
    background-color: var(--secondary10);
    border-radius: 1.35rem;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 15rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9rem;
}


.scheduling_type_option.selected {
    background-color: var(--secondary40);
}

.scheduling_email_subject {
    outline: none;
    border: 1px solid var(--secondary40);
    border-radius: 0.5rem;
    background: none;
    width: 100%;
    padding: 0.5rem 1rem;
    box-sizing: border-box;
}

.scheduling_email_body {
    margin-top: 0.5rem;
}

.scheduling_bottom_actions {
    width: 100%;
    padding: 0rem 1rem;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
}

.send_email_actions {
    display: flex;
    align-items: center;
}

.schedule_later {
    display: flex;
    align-items: center;
    margin-right: 1rem;
    font-size: 1.1rem;
    cursor: pointer;
}

.scheduling_action {
    border: 1px solid var(--secondary40);
    padding: 0.5rem 1rem;
    width: 10rem;
    border-radius: 0.75rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
}

.scheduling_action:hover {
    transform: scale(1.025) translateY(-1.5px);
}

.scheduling_action.later:hover {
    background-color: var(--secondary30);
}

.scheduling_action.send {
    background-color: var(--secondary70);
}

.scheduling_action.send:hover {
    background-color: var(--secondary);
}


/* handlign the schedulign popup */

.schedule-post {
    font-family: Arial, sans-serif;
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  h2 {
    margin: 0;
    font-size: 24px;
  }

  .close-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }

  .time-info {
    font-size: 14px;
    color: #666;
    margin-bottom: 20px;
  }

  .input-group {
    margin-bottom: 20px;
  }

  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }

  .date-input,
  .time-input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }

  .button-group {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }

  .back-button,
  .next-button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }

  .back-button {
    background-color: #f0f0f0;
    color: #333;
  }

  .next-button {
    background-color: #1a73e8;
    color: white;
  }

.date-input {
    outline: none;
    background-color: transparent;
    border: 1px solid var(--secondary40);
}

.time-input {
    outline: none;
    background-color: transparent;
    border: 1px solid var(--secondary40);
}

.schedule_email {
    background-color: var(--secondary70);
    padding: 0.5rem 1rem;
    box-sizing: border-box;
    border-radius: 0.75rem;
    cursor: pointer;
    width: 8.5rem;
    height: 2.25rem;
    border: 1px solid var(--secondary40);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    transition: all 0.3s ease;
}

.schedule_email:hover {
    background-color: var(--secondary);
    transform: scale(1.025) translateY(-1.5px);
}

