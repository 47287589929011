

.get_template_name {
    width: 100%;
    /* margin-bottom: 1rem; */
}


.create_template_email_body {
    height: 52.5%;
}