.delete_bottom_actions {
    padding: 0rem 1rem;
    box-sizing: border-box;
}

.cancel_delete, .delete_candidate {
    border-radius: 1rem;
    box-shadow: none;
    border: 1px solid var(--secondary40);
    padding: 0.5rem 1rem;
    width: 5rem;
    text-align: center;
    cursor: pointer;
    height: 1.2rem;
    transition: all 0.3s ease;
}

.delete_candidate {
    background: linear-gradient(45deg, var(--secondary) 0%, var(--accent) 100%);
    color: var(--bg);
}

.cancel_delete:hover, .delete_candidate:hover {
    transform: scale(1.025);
    box-shadow: 0px 2px 5px var(--secondary60);
}
  