.settings_account {
    height: 82.5%;
}

.settings_account_category:first-of-type {
    border-top: 1px solid var(--secondary40);
}

.settings_account_category {
    border-bottom: 1px solid var(--secondary40);
    padding: 2rem 0rem;
    box-sizing: border-box;
    margin: 0rem 1rem;
}

.settings_account_category_heading {
    margin: 0;
    font-weight: 500;
    font-size: 1.1rem;
    margin-bottom: 1rem;
}

.settings_company_information {
    height: 60%;
}

.company_information_item {
    display: flex;
    padding: 1rem 0rem;
    box-sizing: border-box;
}

.company_information_item_heading {
    width: 12.5%;
    font-weight: 400;
    margin: 0;
}

.company_information_item_input {
    background: none;
    padding: 0.5rem 1rem;
    box-sizing: border-box;
    border-radius: .5rem;
    border: 1px solid lightgray;
    outline: none;
    width: 75%;

}

.company_information_item_textarea {
    resize: none;
    background: none;
    font-family: "Inter", sans-serif;
    font-size: 0.9rem;
    height: 80%;
    width: 75%;
    border: 1px solid lightgray;
    border-radius: 0.5rem;
    padding: 1rem;
    box-sizing: border-box;
}


.settings_account_actions {
    height: 25%;
}

.settings_account_actions_holder{
    width: 100%;
    height: 60%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 2rem;
    box-sizing: border-box;
}

.settings_account_action {
    padding: 0.5rem 1rem;
    border-radius: 0.75rem;
    border: 1px solid var(--primary40);
    width: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;
}

.settings_account_action:hover {
    border: 1px solid var(--secondary5);
    background: var(--secondary);
    box-shadow: 0px 2px 5px var(--secondary40);

}

.company_action_confirmation_message {
    padding: 1rem 0rem;
    box-sizing: border-box;
    margin-bottom: 1rem;
}

.company_action_confirmation_message p {
    margin: 0;
    font-size: 1.1rem;
}

.company_action_confirmation_holder {
    display: flex;
    width: 100%;
    padding: 0rem 0.5rem;
    box-sizing: border-box;
    justify-content: space-between;
}

.confirmation_action {
    border: 1px solid var(--secondary40);
    border-radius: 1rem;
    background: none;
    width: 5rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(1.1);
    transition: all 0.3s ease;
    cursor: pointer;
}

.confirmation_action:hover {
    background: var(--secondary);
    box-shadow: 0px 2px 5px var(--secondary40);
}

.delete_confirmation_box {
    outline: none;
    background: none;
    border: 1px solid var(--secondary40);
    border-radius: 0.5rem;
    width: 70%;
    padding: 0.5rem 1rem;
}

.save_changes_holder {
    height: 15%;
    width: 100%;
    padding: 0rem 1rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
}

.saved_message {
    width: 7.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--primary40);
    background-color: var(--bg);
    border-radius: 1rem;
    padding: 0.5rem 1rem;
    transition: all 0.3s ease;
    color: var(--primary80);
}

.save_changes_button {
    width: 7.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--primary40);
    background-color: var(--primary80);
    border-radius: 1rem;
    cursor: pointer;
    padding: 0.5rem 1rem;
    transition: all 0.3s ease;
    color: var(--bg);
}

.save_changes_button:hover {
    background-color: var(--primary);
    box-shadow: 0px 2px 5px var(--secondary40);
    /* transform: scale(1.1); */
    color: var(--bg);
}

.save_changes_button p, .saved_message p {
    margin: 0;
    margin-left: 0.5rem;
    opacity: 0.8;
}


.deletion_acknowledgment {
    text-align: center;
    padding: 2rem;
    box-sizing: border-box;
}

.deletion_acknowledgment p {
    font-size: 1.1rem;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 450;
}
