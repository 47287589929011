.edit_job_add_pipeline_stage {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--secondary20);
    background-color: var(--secondary60);
    border-radius: 1rem;
    padding: 0.5rem 1rem;
    box-sizing: border-box;
    width: 6.5rem;
    height: 2rem;
    cursor: pointer;
    transition: all 0.3s ease;
}

.edit_job_add_pipeline_stage:hover {
    background-color: var(--secondary);
    transform: scale(1.025) translateY(-1.5px);
}   

.pipeline_stage_options {
    border: 1px solid var(--secondary40);
    background: var(--bg);
    box-shadow: 0px 2px 5px var(--secondary20);
    /* background: red; */
    border-radius: .5rem;
    width: 15rem;
    /* transform: translateX(-50%); */
    /* height: 20rem; */
    overflow: hidden;
}

.pipeline_stage_option {
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease;
    height: 2.5rem;
    box-sizing: border-box;
    border-bottom: 1px solid var(--secondary20);
}

.pipeline_stage_option:last-child {
    border-bottom: none;
}

.pipeline_stage_option:hover {
    background-color: var(--secondary10);
}



/* Edit Job */

.edit_job_body {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 1rem !important;
    box-sizing: border-box;
}

.edit_job_details {
    width: 50%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1rem 0rem !important;
    padding-right: 1rem !important;
    /* box-sizing: border-box !important; */
}


.edit_job_pipeline_area {
    width: 50%;
    padding-left: 1rem;
    box-sizing: border-box;
}

.edit_job_section {
    padding-top: 0.5rem;
    box-sizing: border-box;
}

.edit_job_detail {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 1rem;
}

.edit_job_detail>p {
    margin: 0;
    margin-right: 0.5rem;
}

.edit_job_input {
    width: 7.5rem;
    font-family: "Inter";
    border: none;
    resize: none;
    background: none;
    border: 1px solid var(--secondary40);
    border-radius: 0.5rem;
    height: 2.5rem;
    padding: .5rem 1rem;
    box-sizing: border-box;
    outline: none;
}

.edit_job_desc {
    width: 100%;
    height: 80%;
}

.edit_job_location_holder {
    width: 100%;
    padding-right: 0rem !important;
}

.edit_job_location {
    width: 90% !important;
}

.edit_job_detail>.create_job_select {
    width: 90%;
}

.edit_job_detail>p {
    margin-bottom: 0.5rem;
}

.edit_job_pipeline_holder {
    height: 50%;
}

.edit_job_pipeline {
    border: 1px solid var(--secondary40);
    border-radius: 0.5rem;
    background-color: var(--secondary5);
    height: 75%;
    box-shadow: inset 0px 2px 5px var(--secondary20);
    padding: 1rem;
    box-sizing: border-box;
    overflow-y: auto;
}

.edit_pipeline_stage {
    padding: 0.5rem;
    box-sizing: border-box;
    border-bottom: 1px solid var(--secondary40);
    display: flex;
    justify-content: space-between;
    align-items: center;
    
}

.edit_pipeline_stage>p {
    margin: 0;
}

.edit_pipeline_stage_incomplete {
    background-color: var(--primary);
    color: var(--bg);
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    font-size: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;
}

.delete_stage_bottom_actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0rem 1rem;
    box-sizing: border-box;
}

.delete_stage_direction {
    padding: 0.5rem 1rem;
    border: 1px solid var(--secondary20);
    background-color: var(--secondary40);
    box-shadow: 0px 2px 5px var(--secondary20);
    border-radius: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 5rem;
    text-align: center;
}

.delete_stage_direction:not(.disabled):hover {
    background-color: var(--secondary80);
}


/* EDITING SKILLSCORE */

.top_section {
    width: 100%;
}


