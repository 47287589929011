.calendar_holder {
    padding: 2rem;
    box-sizing: border-box;
}

.calendar_heading {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.calendar_heading a {
    text-decoration: underline;
    color: black;
}

.calendar_display_area {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    box-sizing: border-box;
}

.calendar_display_left_side {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
	padding: 0rem 2rem;
	box-sizing: border-box;
}

.calendar_display_right_side {
    width: 70%
}


.navigator {
    height: 50%
}

.event_information {
    border: 1px solid var(--secondary40);
    height: 50%;
	width: 100%;
    border-radius: 0.5rem;
    /* box-shadow: 0px 2px 5px var(--boxShadow); */
    padding: 0.5rem;
	padding-top: 1rem;
    box-sizing: border-box;
}


/* Designing the calendar components */


/* Navigator first */

body .navigator_default_main {
    border-left: 1px solid var(--secondary50);
    border-right: 1px solid #c0c0c0;
    border-bottom: 1px solid #c0c0c0;
	border: none;
    background-color: none;
    background: none;
    color: #000000;
    box-sizing: content-box;
}

body .navigator_default_main *, body .navigator_default_main *:before, body .navigator_default_main *:after {
    box-sizing: content-box;
}

body .navigator_default_month {
    font-family: "Inter", sans-serif;
    font-size: 12px;
}

body .navigator_default_day {
    color: black;
}

body .navigator_default_cell {
    text-align: center;
	/* border-right: 1px solid red; */
	/* padding: 0.5rem; */
	/* box-sizing: border-box; */
	border-left: 0.5px solid var(--secondary30);
	border-right: 0.5px solid var(--secondary30);
}

body .navigator_default_weekend {
    background-color: none;
	background: none;

	/* border-right: none; */
	/* border-left: none; */
}

body .navigator_default_dayheader {
    color: black;
	/* border-left: 0.5px solid var(--secondary30);
	border-right: 0.5px solid var(--secondary30); */
}

body .navigator_default_line {
    border-bottom: none;
}

body .navigator_default_dayother {
    color: gray;
}

body .navigator_default_todaybox {
    border: 1px solid var(--primary70);
    border-radius: .15rem;
	z-index: 1;
}

/* body .navigator_default_title, .navigator_default_titleleft, .navigator_default_titleright", 'border-top: 1px solid #c0c0c0;border-bottom: 1px solid #c0c0c0;color: #333;background: #f3f3f3;'); */
body .navigator_default_busy {
    font-weight: bold;
}



body .navigator_default_select .navigator_default_cell_box {
    background-color: var(--secondary40);
}

body .navigator_default_title {
    text-align: center;
}

body .navigator_default_titleleft, body .navigator_default_titleright {
    text-align: center;
}

body .navigator_default_dayheader {
    text-align: center;
}

body .navigator_default_weeknumber {
    text-align: center;
    color: #999;
}

body .navigator_default_cell_text {
    cursor: pointer;
}


body .navigator_default_title, body .navigator_default_titleleft, body .navigator_default_titleright {
    border-top: none;
    border-bottom: none;
    color: var(--text);
    background: none;
}




body .calendar_default_main
{
	border: none;
}

body .calendar_default_colheader
{
}

body .calendar_default_cornerright_inner
{
	position: absolute;
	top: 0px;
	left: 0px;
	bottom: 0px;
	right: 0px;
	/* border-right: 1px solid #999; */
	/* border-bottom: 1px solid #999; */
	border: none;
	color: #ffffff;
	background: var(--secondary50);
	background: -moz-linear-gradient(
		top,
		var(--secondary70) 0%,
		var(--secondary50));
	background: -webkit-gradient(
		linear, left top, left bottom,
		from(var(--secondary70)),
		to(var(--secondary50)));
	/* background: none; */
	filter: progid:DXImageTransform.Microsoft.Gradient(startColorStr="#777777", endColorStr="#666666");

}

body .calendar_default_rowheader_inner
{
	font-size: 16pt;

	text-align: right;
	position: absolute;
	top: 0px;
	left: 0px;
	bottom: 0px;
	right: 0px;
	border-right: 1px solid var(--secondary50);
	border-bottom: 1px solid var(--secondary50);

	color: var(--text);
	background: var(--secondary50);
	background: -moz-linear-gradient(
		top,
		var(--secondary70) 0%,
		var(--secondary50));
	background: -webkit-gradient(
		linear, left top, left bottom,
		from(var(--secondary70)),
		to(var(--secondary50)));
	background: none;
	filter: progid:DXImageTransform.Microsoft.Gradient(startColorStr="#777777", endColorStr="#666666");
	/* text-shadow:
		0px -1px 1px rgba(000,000,000,0.2),
		0px 1px 0px rgba(255,255,255,0.3);
	 */
}

body .calendar_default_corner_inner
{
	position: absolute;
	top: 0px;
	left: 0px;
	bottom: 0px;
	right: 0px;
	border-right: 1px solid var(--secondary50);
	border-bottom: 1px solid var(--secondary50);
	/* border: none; */
	color: var(--text);
	background: var(--secondary50);
	background: -moz-linear-gradient(
		top,
		var(--secondary70) 0%,
		var(--secondary50));
	background: -webkit-gradient(
		linear, left top, left bottom,
		from(var(--secondary70)),
		to(var(--secondary50)));
	background: none;
	filter: progid:DXImageTransform.Microsoft.Gradient(startColorStr="#777777", endColorStr="#666666");
}

body .calendar_default_rowheader_minutes
{
	font-size:10px;
	vertical-align: super;
	padding-left: 2px;
	padding-right: 2px;
}

body .calendar_default_colheader_inner
{
	text-align: center;
	position: absolute;
	top: 0px;
	left: 0px;
	bottom: 0px;
	right: 0px;
	border-right: none;
	border-bottom: 1px solid var(--secondary50);
	color: var(--text);
	background: var(--secondary50);
	background: -moz-linear-gradient(
		top,
		var(--secondary70) 0%,
		var(--secondary50));
	background: -webkit-gradient(
		linear, left top, left bottom,
		from(var(--secondary70)),
		to(var(--secondary50)));
	background: none;
	filter: progid:DXImageTransform.Microsoft.Gradient(startColorStr="#777777", endColorStr="#666666");
	/* text-shadow:
		0px -1px 1px rgba(000,000,000,0.2),
		0px 1px 0px rgba(255,255,255,0.3); */

}
body .calendar_default_cell_inner
{
	position: absolute;
	top: 0px;
	left: 0px;
	bottom: 0px;
	right: 0px;
	background: none;
	border-right: 1px solid #ddd;
	border-bottom: 1px solid #ddd;
}

body .calendar_default_cell_business .calendar_default_cell_inner {
	background: none;
}

body .calendar_default_alldayheader_inner
{
	text-align: center;
	position: absolute;
	top: 0px;
	left: 0px;
	bottom: 0px;
	right: 0px;
	border-right: 1px solid var(--secondary30);
	border-bottom: 1px solid var(--secondary30);

	color: #ffffff;
	background: var(--secondary50);
	background: -moz-linear-gradient(
		top,
		var(--secondary50) 0%,
		var(--secondary70));
	background: -webkit-gradient(
		linear, left top, left bottom,
		from(var(--secondary70)),
		to(var(--secondary50)));
	filter: progid:DXImageTransform.Microsoft.Gradient(startColorStr="#777777", endColorStr="#666666");
	text-shadow:
		0px -1px 1px rgba(000,000,000,0.2),
		0px 1px 0px rgba(255,255,255,0.3);

}

body .calendar_default_message
{
	padding: 10px;
	opacity: 0.9;
	filter: alpha(opacity=90);

	color: #ffffff;
	background: #000;
	background: -moz-linear-gradient(
		top,
		#999 0%,
		#666);
	background: -webkit-gradient(
		linear, left top, left bottom,
		from(#999),
		to(#666));
	filter: progid:DXImageTransform.Microsoft.Gradient(startColorStr="#999999", endColorStr="#666666");
	text-shadow:
		0px -1px 1px rgba(000,000,000,0.2),
		0px 1px 0px rgba(255,255,255,0.3);

}

body .calendar_default_alldayevent
{
	border-radius: 15px; /* ie gradient + rounded corners problem */
	overflow: hidden;
}

body .calendar_default_alldayevent_inner
{
	position: absolute;
	overflow: hidden;
	top: 2px;
	bottom: 2px;
	left: 2px;
	right: 2px;

	padding: 2px;

	margin-right: 1px;
	font-size: 12px;
	color: var(--text);
	background: var(--accent70);
	background: -moz-linear-gradient(
		top,
		var(--secondary50) 0%,
		var(--accent70));
	background: -webkit-gradient(
		linear, left top, left bottom,
		from(var(--secondary50)),
		to(var(--accent70)));
	filter: progid:DXImageTransform.Microsoft.Gradient(startColorStr="#a2c200", endColorStr="#8aaa00");
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	border-radius: 5px;
	border: 1px solid var;
	-moz-box-shadow:
		0px 2px 3px rgba(000,000,000,0.3),
		inset 0px 0px 2px rgba(255,255,255,0.8);
	-webkit-box-shadow:
		0px 2px 3px rgba(000,000,000,0.3),
		inset 0px 0px 2px rgba(255,255,255,0.8);
	box-shadow:
		0px 2px 3px rgba(000,000,000,0.3),
		inset 0px 0px 2px rgba(255,255,255,0.8);
	text-shadow:
		0px -1px 0px rgba(000,000,000,0.2),
		0px 1px 0px rgba(255,255,255,1);
}

body .calendar_default_event
{
	border-radius: 5px; /* ie gradient + rounded corners problem */
}


body .calendar_default_event_inner
{
	overflow: hidden;
	position: absolute;
	top: 0px;
	bottom: 2px;
	left: 2px;
	right: 0px;
	margin-right: 1px;
	font-size: 12px;
	color: var(--text);
	background: var(--accent70);
	background: -moz-linear-gradient(
		top,
		var(--secondary50) 0%,
		var(--accent70));
	background: -webkit-gradient(
		linear, left top, left bottom,
		from(var(--secondary50)),
		to(var(--accent70)));
	filter: progid:DXImageTransform.Microsoft.Gradient(startColorStr="#a2c200", endColorStr="#8aaa00");
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	border-radius: 5px;
	border: 1px solid var(--secondary60);
	-moz-box-shadow:
		0px 2px 3px rgba(000,000,000,0.3),
		inset 0px 0px 2px rgba(255,255,255,0.8);
	-webkit-box-shadow:
		0px 2px 3px rgba(000,000,000,0.3),
		inset 0px 0px 2px rgba(255,255,255,0.8);
	box-shadow:
		0px 2px 3px rgba(000,000,000,0.3),
		inset 0px 0px 2px rgba(255,255,255,0.8);
	/* text-shadow:
		0px -1px 0px rgba(000,000,000,0.2),
		0px 1px 0px rgba(255,255,255,1); */
}

body .calendar_default_event_bar_inner {
	background: var(--primary);
}

body .calendar_default_event_hover body .calendar_default_event_inner,
body .calendar_default_alldayevent_hover body .calendar_default_alldayevent_inner
{
	background: var(--accent70);
	background: -moz-linear-gradient(
		top,
		var(--secondary50) 0%,
		var(--accent70));
	background: -webkit-gradient(
		linear, left top, left bottom,
		from(var(--secondary50)),
		to(var(--accent70)));
	filter: progid:DXImageTransform.Microsoft.Gradient(startColorStr="#a8dc18", endColorStr="#8fc300");
}

body .calendar_default_shadow_inner
{
	background-color: var(--secondary30);
	opacity: 0.5;
	filter: alpha(opacity=50);
	height: 100%;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	border-radius: 5px;
}



/* Handling the event information thing */

.event_information_card {
	position: relative;
	height: 100%;
}

.event_information_heading {
	width: 100%;
	text-align: center;
}

.event_information_heading p {
	font-size: 0.9rem;
	font-weight: 500;
	margin: 0;
}

.event_close {
	position: absolute;
	right: 0.5rem;
	top: 0rem;
	font-size: 0.9rem;
	cursor: pointer;
}

.event_information_body {
	margin-top: 1rem;
	display: flex;
	height: 95%;
	padding: 1rem 0rem;
	box-sizing: border-box;
	margin-bottom: 1rem;
	position: relative;
}

.event_information_body_section {
	width: 50%;
	max-width: 50%;
	height: 100%;
	padding: 0rem 0.5rem;
	box-sizing: border-box;
}

.event_information_body_section:first-of-type {
	border-right: 1px solid var(--secondary20);
}

.event_information_item:first-of-type {
	/* border-top: 1px solid var(--secondary20); */
}

.event_information_item {
	padding: 1rem 0rem;
	display: flex;
	align-items: center;
	/* border-bottom: 1px solid var(--secondary20); */
}

.event_information_time {
	margin-bottom: 1rem;
}

.event_information_item p {
	margin: 0;
	font-size: 1rem;
}

.event_information_item_heading {
	font-weight: 550;
	padding-right: 0.5rem;
	box-sizing: border-box;
	width: 20%;
	/* overflow-x: ellipsis; */
}

.event_information_item_button {
	border: 1px solid var(--secondary40);
	border-radius: 0.5rem;
	padding: 0.5rem 1rem;
	box-sizing: border-box;
	cursor: pointer;
	transition: all 0.3s ease;
}

.event_information_item_button:hover {
	background-color: var(--secondary40);
	box-shadow: 0px 2px 5px var(--secondary20);
}

.event_actions {
	display: flex;
	align-items: center;
}

.event_actions_select {
	background: none;
    color: black;
    box-sizing: border-box;
    width: 30%;
    height: 2rem;
    padding: 0 .5rem;
    border: 1px solid lightgray;
    box-shadow: 0px 2px 2px rgb(0, 0, 0, 0.1);
    border-radius: .2rem;
    font-size: 1rem;
    font-family: "Inter", sans-serif;
    outline: none;
	margin-right: 2rem;
	cursor: pointer;
}

.event_action_button {
	border: 1px solid var(--primary30);
	box-shadow: 0px 2px 5px var(--secondary30);
	color: var(--primary70);
	padding: 0.35rem 1rem;
	border-radius: 0.2rem;
	cursor: pointer;
	transition: all 0.3s ease;
}

.event_action_button:hover {
	border: 1px solid var(--primary70);
	color: var(--primary90);
	box-shadow: 0px 2px 5px var(--secondary50);
}

.event_information_interviewers {
	height: 85%;
}

.event_interviewers_holder {
	overflow-y: auto;
	height: 90%;
}

.event_information_link_holder {
	display: flex;
	justify-content: center;
	align-items: center;
}

.event_information_link {
	padding: 0.5rem 1rem;
	width: 5rem;
	border-radius: 1rem;
	text-align: center;
	cursor: pointer;
	box-shadow: 0px 2px 5px var(--boxShadow);
	border: 1px solid var(--secondary40);
	transition: all 0.3s ease;
}

.event_information_link:hover {
	background-color: var(--secondary40);
	border: 1px solid var(--secondary5);
	width: 7.5rem;
}

.event_link {
	text-decoration: none;
	color: var(--bg);
	border-radius: 0.5rem;
	padding: 0.5rem 1rem;
	background-color: var(--primary70);
	transition: all 0.3s ease;
	position: absolute;
	bottom: 2rem;
	left: 1rem;
}

.event_link:hover {
	background-color: var(--primary80);
	transform: scale(1.05);
}

.event_information_interviewers {
	padding: 0rem 1rem;
}

.event_interviewers_holder {
	box-shadow: inset 0px 0px 5px var(--secondary70);
	border-radius: 0.5rem;
	padding: 1rem;
}

.event_interviewer {
	padding: 0rem 0.5rem;
	border-bottom: 1px solid var(--secondary40);
}

.interview_information_section {
	text-align: center;
	padding: 0rem 2rem;
	box-sizing: border-box;
}

.interview_information_section p {
	margin: 0;
	margin-bottom: 1rem;
}

.interview_content_area {
	border-radius: 0.5rem;
	height: 80%;
	width: 100%;
	border: 1px solid var(--secondary20);
	box-shadow: inset 0px 0px 5px var(--secondary20);
}

.interview_notes, .interview_feedback {
	width: 100%;
	height: 100%;
	resize: none;
	border: none;
	background: none;
	font-family: "Inter", sans-serif;
	font-size: .9rem;
	padding: 1rem;
	box-sizing: border-box;
}


/* handling the small cancellation popup */

.cancel_reason {
	resize: none;
	background: none;
	border: 1px solid var(--secondary40);
	border-radius: 0.5rem;
	width: 100%;
	height: 10rem;
	padding: 1rem;
	box-sizing: border-box;
	font-family: "Inter", sans-serif;
}

.cancel_interview {
	position: absolute;
	bottom: 2rem;
	right: 2rem;
}

.interview_feedback_actions_area {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 1rem;
}



.save_changes_button p {
  margin-bottom: 0rem;
  margin-left: 0.5rem;
}
