@import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100..900;1,100..900&display=swap');

html {
  margin: 0;
}



body {
  font-family: "Inter", sans-serif;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: var(--background);
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Archivo", sans-serif;
}

.website-container {
  display: flex;
  height: 100vh;
  min-width: 100vw;
  max-width: 100vw;
  position: relative;
}

.subnav_link {
  text-decoration: none;
  color: black;
}


.main_content {
  width: 86.5%;
  height: 100vh;
}
