.feedback_section {
    cursor: pointer;
}

.feedback_form_instructions {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
}

.feedback_form_actions {
    display: flex;
    align-items: center;
}

.feedback_form_action_button {
    background-color: transparent;
    border: 1px solid var(--secondary40);
    border-radius: 0.5rem;
    padding: 0.25rem 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 5rem;
    transition: all 0.3s ease;
    margin-right: 1rem;
}

.feedback_form_action_button:hover {
    background-color: var(--secondary10);
}

.feedback_form_action_button:nth-child(2) {
    background-color: var(--secondary60);
    border: 1px solid var(--secondary40);
    border-radius: 0.5rem;
    padding: 0.25rem 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 5rem;
    transition: all 0.3s ease;
}

.feedback_form_action_button:nth-child(2):hover {
    background-color: var(--secondary80);
}


.feedback_form_select {
    outline: none;
    background: none;
    border-radius: 1rem;
    border: 1px solid var(--secondary40);
    height: 2.5rem;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
}

.create_new_popup_message {
    text-decoration: underline;
    color: var(--primary);
    cursor: pointer;
}


.create_feedback_form_section {
    margin-bottom: 2rem;
}

.feedback_form_name {
    display: flex;
    align-items: center;
    width: 100%;
}

.feedback_form_name h3 {
    margin-right: 1rem;
    /* width: 10%; */
}

.create_form_content {
    width: 100%;
}

.create_form_content_input {
    background-color: var(--secondary5);
    border: 1px solid var(--secondary40);
    border-radius: 0.5rem;
    outline: none;
    padding: 0.5rem 1rem;
    margin-right: 0.5rem;
    font-family: "Inter";
    font-size: 1rem;
    width: 50%;
}

.feedback_form_questions {
    height: 80%;
}

.feedback_form_questions_heading {
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.feedback_form_add_question {
    border: 1px solid var(--secondary10);
    color: var(--bg);
    background-color: var(--primary80);
    box-shadow: 0px 2px 5px var(--secondary20);
    border-radius: 0.5rem;
    width: 15%;
    cursor: pointer;
    padding: 0.5rem 1rem;
    text-align: center;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}


.feedback_form_add_question:hover {
    background-color: var(--primary);
    box-shadow: 0px 2px 10px var(--secondary40);
    transform: scale(1.025);
}

.feedback_form_questions_area {
    height: 90%;
    background-color: var(--secondary5);
    border: 1px solid var(--secondary40);
    border-radius: 0.5rem;
    padding: 1rem;
    box-sizing: border-box;
}

.feedback_form_question {
    padding: 0rem 0.5rem;
    border-bottom: 1px solid var(--secondary40);
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.question_details {
    display: flex;
    align-items: center;
    width: 90%;
}

.question_actions {
    display: flex;
    align-items: center;
    width: 10%;
}

.question_text_display {
    font-weight: 400;
    margin-right: 1rem;
}

.question_type_display {
    font-weight: 500;
    opacity: 0.6;
}

.question_action_icon {
    cursor: pointer;
}

.question_edit_icon {
    margin-right: 2rem;
}



