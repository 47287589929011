
html {
  height: 100%;
  min-height: 100%;
  box-sizing: border-box;
}

:root {
  overflow: overlay;
  --text: #110615;
  --background: #f9f6f9;
  --bg: #f9f6f9;
  --primary: #9755ab;
  --secondary: #c899d6;
  --accent: #bb6fd2;
  --textcontrast: #64d97b;
  --bgcontrast: #64d97b;
  --primarycontrast: #ffdd00;
  --secondarycontrast: #64d97b;
  --accentcontrast: #ffdd00;
  --text5: hsl(284, 56%, 5%, 5%);
  --text10: hsl(284, 56%, 5%, 10%);
  --text15: hsl(284, 56%, 5%, 15%);
  --text20: hsl(284, 56%, 5%, 20%);
  --text25: hsl(284, 56%, 5%, 25%);
  --text30: hsl(284, 56%, 5%, 30%);
  --text35: hsl(284, 56%, 5%, 35%);
  --text40: hsl(284, 56%, 5%, 40%);
  --text45: hsl(284, 56%, 5%, 45%);
  --text50: hsl(284, 56%, 5%, 50%);
  --text55: hsl(284, 56%, 5%, 55%);
  --text60: hsl(284, 56%, 5%, 60%);
  --text65: hsl(284, 56%, 5%, 65%);
  --text70: hsl(284, 56%, 5%, 70%);
  --text75: hsl(284, 56%, 5%, 75%);
  --text80: hsl(284, 56%, 5%, 80%);
  --text85: hsl(284, 56%, 5%, 85%);
  --text90: hsl(284, 56%, 5%, 90%);
  --text95: hsl(284, 56%, 5%, 95%);
  --primary5: hsl(286, 34%, 50%, 5%);
  --primary10: hsl(286, 34%, 50%, 10%);
  --primary15: hsl(286, 34%, 50%, 15%);
  --primary20: hsl(286, 34%, 50%, 20%);
  --primary25: hsl(286, 34%, 50%, 25%);
  --primary30: hsl(286, 34%, 50%, 30%);
  --primary35: hsl(286, 34%, 50%, 35%);
  --primary40: hsl(286, 34%, 50%, 40%);
  --primary45: hsl(286, 34%, 50%, 45%);
  --primary50: hsl(286, 34%, 50%, 50%);
  --primary55: hsl(286, 34%, 50%, 55%);
  --primary60: hsl(286, 34%, 50%, 60%);
  --primary65: hsl(286, 34%, 50%, 65%);
  --primary70: hsl(286, 34%, 50%, 70%);
  --primary75: hsl(286, 34%, 50%, 75%);
  --primary80: hsl(286, 34%, 50%, 80%);
  --primary85: hsl(286, 34%, 50%, 85%);
  --primary90: hsl(286, 34%, 50%, 90%);
  --primary95: hsl(286, 34%, 50%, 95%);
  --secondary5: hsl(286, 43%, 72%, 5%);
  --secondary10: hsl(286, 43%, 72%, 10%);
  --secondary15: hsl(286, 43%, 72%, 15%);
  --secondary20: hsl(286, 43%, 72%, 20%);
  --secondary25: hsl(286, 43%, 72%, 25%);
  --secondary30: hsl(286, 43%, 72%, 30%);
  --secondary35: hsl(286, 43%, 72%, 35%);
  --secondary40: hsl(286, 43%, 72%, 40%);
  --secondary45: hsl(286, 43%, 72%, 45%);
  --secondary50: hsl(286, 43%, 72%, 50%);
  --secondary55: hsl(286, 43%, 72%, 55%);
  --secondary60: hsl(286, 43%, 72%, 60%);
  --secondary65: hsl(286, 43%, 72%, 65%);
  --secondary70: hsl(286, 43%, 72%, 70%);
  --secondary75: hsl(286, 43%, 72%, 75%);
  --secondary80: hsl(286, 43%, 72%, 80%);
  --secondary85: hsl(286, 43%, 72%, 85%);
  --secondary90: hsl(286, 43%, 72%, 90%);
  --secondary95: hsl(286, 43%, 72%, 95%);
  --accent5: hsl(286, 52%, 63%, 5%);
  --accent10: hsl(286, 52%, 63%, 10%);
  --accent15: hsl(286, 52%, 63%, 15%);
  --accent20: hsl(286, 52%, 63%, 20%);
  --accent25: hsl(286, 52%, 63%, 25%);
  --accent30: hsl(286, 52%, 63%, 30%);
  --accent35: hsl(286, 52%, 63%, 35%);
  --accent40: hsl(286, 52%, 63%, 40%);
  --accent45: hsl(286, 52%, 63%, 45%);
  --accent50: hsl(286, 52%, 63%, 50%);
  --accent55: hsl(286, 52%, 63%, 55%);
  --accent60: hsl(286, 52%, 63%, 60%);
  --accent65: hsl(286, 52%, 63%, 65%);
  --accent70: hsl(286, 52%, 63%, 70%);
  --accent75: hsl(286, 52%, 63%, 75%);
  --accent80: hsl(286, 52%, 63%, 80%);
  --accent85: hsl(286, 52%, 63%, 85%);
  --accent90: hsl(286, 52%, 63%, 90%);
  --accent95: hsl(286, 52%, 63%, 95%);
  --linearPrimarySecondary: linear-gradient(to right, #9755ab, #c899d6);
  --linearPrimaryAccent: linear-gradient(#9755ab, #bb6fd2);
  --linearSecondaryAccent: linear-gradient(#c899d6, #bb6fd2);
  --radialPrimarySecondary: radial-gradient(#9755ab, #c899d6);
  --radialPrimaryAccent: radial-gradient(#9755ab, #bb6fd2);
  --radialSecondaryAccent: radial-gradient(#c899d6, #bb6fd2);
  --sectionBackground: #eaddee;
  --boxShadow: #eaddee;
  --iconColor: #ba7fcc;
}



/* CSS for popups */


.popup_holder {
  width: 100vw;
  position: relative;
  height: 100vh;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(0.5rem) brightness(80%);
}

.popup_holder.overlay {
  backdrop-filter: none;
}

.popup {
  /* background-color: white; */
  z-index: 9999;
  width: 80%;
  height: 80%;
  max-height: 80%;
  background: var(--background);
  border-radius: 0.5rem;
  border: .1px solid lightgray;
  box-shadow: 0px 2px 5px rgb(0,0,0,0.2);
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: center;
  text-align: left;
  padding: 2rem;
  box-sizing: border-box;
  position: relative;
}

.popup_bottom_actions {
  position: absolute;
  bottom: 2rem;
  width: 90%;
  display: flex;
  justify-content: space-between;
}

.popup_cancel, .popup_action {
  border-radius: 1rem;
  color: var(--text);
  border: 1px solid var(--secondary40);
  padding: 0.5rem 1rem;
  width: 5rem;
  text-align: center;
  cursor: pointer;
  height: 1.2rem;
  transition: all 0.3s ease;
}

.popup_action {
  background: var(--primary60);
  color: var(--text);
}

.popup_action:hover {
  background: var(--primary);
  color: var(--bg);
}

.popup_cancel:hover, .popup_action:hover {
  transform: scale(1.025);
  box-shadow: 0px 2px 5px var(--secondary20);
}

.popup_heading {
  text-align: center;
  margin: 0;
  padding: 0;
  margin-bottom: 2rem;
}

.popup_text {
  text-align: left;
  width: 100%;
  margin: 0;
  margin-bottom: 1rem;
  padding: 0;
}


.popup_close {
  cursor: pointer;
  position: absolute;
  right: 2rem;
  top: 2rem;
}


input[type="text"] {
  font-family: "Inter", sans-serif;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


/* Handling the scrollbar */

/* For Webkit browsers (Chrome, Safari, newer versions of Opera) */
::-webkit-scrollbar {
  width: 10px;  /* Adjust width as needed */
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: var(--primary80);
  border-radius: 5px;
}

/* For Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--primary80) transparent;
}

/* For Internet Explorer and Edge */
* {
  -ms-overflow-style: none;
}

*::-ms-scrollbar {
  width: 10px;  /* Adjust width as needed */
}

*::-ms-scrollbar-track {
  background: transparent;
}

*::-ms-scrollbar-thumb {
  background-color: var(--primary80);
  border-radius: 5px;
}
