.new_question_info {
    height: 55%;
    border-bottom: 1px solid var(--secondary40);
}

.new_question_section {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    height: 3rem;
}



.new_question_section_head {
    width: 25%;
}

.new_question_input {
    width: 70%;
    font-family: "Inter";
    border: none;
    resize: none;
    background: none;
    border: 1px solid var(--secondary40);
    border-radius: 0.5rem;
    height: 2.5rem;
    padding: .5rem 1rem;
    box-sizing: border-box;
    outline: none;
}

.new_question_select {
    font-family: "Inter";
    border: none;
    resize: none;
    background: none;
    border: 1px solid var(--secondary40);
    border-radius: 0.5rem;
    outline: none;
    height: 2.5rem;
    padding: .5rem 1rem;
    box-sizing: border-box;
}


/* handling editing options */

.edit_options_trigger {
    border: 1px solid var(--secondary40);
    border-radius: .5rem;
    padding: 0.5rem 1rem;
    box-sizing: border-box;
    height: 2.5rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: .9rem;

}

.edit_options_heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 1rem;
}

.add_option_input {
    background: none;
    border: 1px solid var(--secondary40);
    border-radius: .5rem;
    height: 2.5rem;
    padding: 0.5rem 1rem;
    box-sizing: border-box;
    width: 60%;
    outline: none;
}

.add_option {
    border: 1px solid var(--secondary10);
    color: var(--bg);
    background-color: var(--primary80);
    box-shadow: 0px 2px 5px var(--secondary20);
    border-radius: .5rem;
    width: 25%;
    cursor: pointer;
    height: 2.5rem;
    padding: 0.5rem 1rem;
    box-sizing: border-box;
    text-align: center;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}


.add_option:hover {
    background-color: var(--primary);
    box-shadow: 0px 2px 10px var(--secondary40);
    transform: scale(1.025);
}

.edit_options_list {
    width: 100%;
    height: 70%;
    overflow-y: auto;
}

.edit_option:first-of-type {
    border-top: 1px solid var(--secondary40);
}

.edit_option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--secondary40);
    padding: 0rem 0.5rem;
}


/* handling the preview */

.new_question_preview {
    height: 45%;
    padding: 1rem 0rem;
    box-sizing: border-box;
}

.question_preview_area {
    border-radius: 1rem;
    padding: 1rem;
    box-sizing: border-box;
    height: 70%;
    margin-top: 2.5%;
}

.question_preview_text {
    margin: 0;
}

.question_preview_response {
    margin-top: 1rem;
}

.range_slider_preview {
    display: flex;
    align-items: center;
    width: 100%;
    border: 1px solid var(--secondary40);
    border-radius: 0.5rem;
    height: 2.5rem;
    padding: .5rem 1rem;
    box-sizing: border-box;
}

.range_slider_value {
    width: 5%;
    text-align: center;
    padding-right: 2.5%;
    border-right: 1px solid var(--secondary40);
}

.range_slider_slider {
    width: 92.5%;
    display: flex;
    align-items: center;
}

.range_slider_desc {
    width: 25%;
    text-align: center;
    margin: 0;
}

.question_preview_range {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 50%;
}

.question_preview_range p {
    margin: 0;
}

.question_preview_slider {
    width: 95%;
    padding-right: 0rem;
    padding: 0rem 0.5rem;
    display: flex;
}


.yes_no_preview {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 60%;
}

.yes_no_option {
    cursor: pointer;
    border: 1px solid var(--secondary40);
    border-radius: 1rem;
    padding: 0.5rem 1rem;
    box-sizing: border-box;
    height: 2.5rem;
    width: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--secondary10);
    transition: all 0.3s ease;
}

.yes_no_option.selected {
    background-color: var(--secondary40);
}

.new_question_long_input {
    width: 70%;
    font-family: "Inter";
    border: none;
    resize: none;
    background: none;
    border: 1px solid var(--secondary40);
    border-radius: 0.5rem;
    height: 4.5rem;
    padding: .5rem 1rem;
    box-sizing: border-box;
    outline: none;
}