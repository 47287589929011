.scheduling_section {
    border-bottom: 1px solid var(--secondary40);
    width: 100%;
    padding: 1rem 0rem;
    box-sizing: border-box;
    position: relative;
    transition: all 0.3s ease;
}

.automate_section {
    height: 3.5rem;
}

.scheduling_section:first-of-type {
    padding-top: 0rem;
}

.scheduling_section:last-of-type {
    margin-bottom: 0rem;
    border-bottom: none;
}

.scheduling_section_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* height: 2rem; */
    margin-bottom: 1rem;
}

.scheduling_section.automate {
    height: 8rem;
}

.scheduling_section_bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.scheduling_section_bottom>p {
    width: 20%;
}

.scheduling_choices {
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 8rem;
}

.scheduling_choice {
    border: 1px solid var(--secondary40);
    background-color: var(--secondary10);
    border-radius: 1.35rem;
    width: 15rem;
    height: 3rem;
    transition: all 0.3s ease;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.scheduling_choice.selected {
    background-color: var(--secondary40);
}   

.scheduling_templates_holder {
    margin-top: 1rem;
}

.scheduling_template:first-of-type {
    border-top: 1px solid var(--secondary20);
}

.scheduling_template {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--secondary20);
    padding: 0rem 1rem;
    height: 2.5rem;
    cursor: pointer;
    transition: all 0.3s ease;
}

.scheduling_template p {
    margin: 0;
}

.scheduling_template:hover {
    background-color: var(--secondary10);
}

/* .scheduling_template:las */

.scheduling_template_action {
    display: flex;
    align-items: center;
}

.scheduling_template_action>p {
    margin: 0;
    margin-right: 0.5rem;
}

.scheduling_section_bottom.reminder {

    justify-content: flex-start;
}

.scheduling_section_select {
    margin-right: 1rem;
    border: 1px solid var(--secondary40);
    border-radius: 0.5rem;
    background: transparent;
    outline: none;
    padding: 0.5rem 1rem;
    width: 7.5rem;
    height: 2.5rem;
}

.scheduling_section_top {
}

.scheduling_section_top>.interview_section_text {
    margin: 0;
}