.all_candidates_holder {
    padding: 2rem;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.add_candidates_note {
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding: 0.5rem 1rem;
    box-sizing: border-box;
    border-radius: 0.5rem;
    border: 1px solid var(--secondary40);
    background-color: var(--secondary5);
    margin-bottom: 1.5rem;
}

.add_candidates_note_body {
    margin: 0;
}

.add_candidates_note_head {
    font-weight: 500;
    margin: 0;
    margin-right: 1rem;
}

.allCandidatesSection {
    width: 100%;
    height: 80%;
    padding: 2rem 0rem;
    box-sizing: border-box;
}

.allCandidates_options_holder{
    height: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-bottom: 1rem; */
}

.allCandidates_options {
    /* border: 1px solid var(--secondary); */
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.allCandidateTableHead {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--secondary40);
    height: 2rem;
    padding: 0rem 1rem;
    box-sizing: border-box;
    padding-bottom: .5rem;
    width: 100%;
}

.allCandidateTableHead div {
    text-align: center;
    font-weight: bold;
}

.allCandidatesTable {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 2.5px;
    box-sizing:  border-box;
    box-shadow: 0px 0px 5px inset var(--secondary);
    width: 80%;
    height: 100%;
}

.candidatesSearchHolder {
    width: 100%;
    padding: 0.5rem 2rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
}

.candidatesSearch {
    border: 1px solid var(--secondary40);
    padding: 0.5rem 1rem;
    box-sizing: border-box;
    border-radius: 0.5rem;
}

.allCandidatesTableBody {
    overflow-y: auto;    
    box-sizing: border-box;
    width: 100%;
    height: 95%;
}

.candidate_allCandidates {
    width: 100%;
    display: flex;
    /* cursor: pointer; */
    min-height: 2.5rem;
    height: 2.5rem;
    max-height: 2.5rem;
    align-items: center;
    padding: 0rem 1rem;
    /* padding-right: 0; */
    box-sizing: border-box;
    border-bottom: 1px solid var(--secondary40);
    font-size: 0.8rem;
}

.candidate_allCandidates > div {
    text-align: center;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.allCandidatesFilter {
    height: 100%;
    width: 20%;
    /* border: 1px solid var(--secondary); */
    /* box-shadow: 0px 0px 5px  var(--secondary); */
    padding: 1rem 1rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    align-items: center;
}



.all_candidates_filter_options_heading {
    margin-bottom: 1rem;
}


.filter_options_location_eligibility {
    border-top: 1px solid var(--secondary40);
}

.filter_options_location_eligibility, .filter_options_country_elibility {
    border-bottom: 1px solid var(--secondary40);
    border-radius: 0.2rem;
    padding: 1.5rem 1rem;
    /* border: 1px solid red; */
    width: 100%;
    box-sizing: border-box;
}

.filter_options_country_elibility {
    margin-bottom: 2rem;
}

.filter_options_location_heading, .filter_options_country_heading {
    display: flex;
    align-items: center;
    margin: 0;
    margin-bottom: 1rem;
    padding: 0;
    /* just */
}

.filter_options_location_input_holder {
    margin-bottom: 1rem;
}

.filter_options_location_input {
    outline: 0;
    border: 0.5px solid var(--secondary);
    color: var(--text); 
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    box-sizing: border-box;
    background: none;
    width: 100%;
}

.filter_location_submit {
    border: 1px solid var(--secondary);
    border-radius: 1rem;
    padding: 0.35rem .75rem;
    box-sizing: border-box;
    color: var(--text);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;
}

.filter_location_submit:hover {
    background: var(--secondary);
    color: var(--background);
    
}


.allCandidates_option_icon {
    color: var(--primary);
    font-size: 1rem;

}

.location_selector {
    box-shadow: none !important;
    background-color: none !important;
    background: none !important;
    outline: none !important;
}

.loading_candidates, .no_candidates {
    padding: 1rem;
    font-size: 0.8rem;
}


.candidate_checkbox_cell {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5%;
}

.candidate_name_cell {
    width: 15%;
}

.candidate_email_cell {
    width: 22.5%;
}

.candidate_phone_cell {
    width: 15%;
}

.candidate_job_applied_cell {
    width: 20%;
}

.candidate_location_cell {
    width: 15%;
}

.candidate_view_cell {
    width: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.candidate_info_cell {
    width: 7.5%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: visible !important;
    overflow-x: visible !important;
    overflow-y: visible !important;
}

.semantic_search_button {
    border: 1px solid var(--secondary40);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem;
    box-sizing: border-box;
    border-radius: 1rem;
    background: var(--secondary40);
    border: 1px solid var(--secondary40);
    box-shadow: 0px 2px 5px var(--secondary20);
    cursor: pointer;
    margin-top: 1rem;
    transition: all 0.3s ease;
}

.semantic_search_button:hover {
    background: var(--secondary80);
    box-shadow: 0px 2px 5px var(--secondary40);

}
