.toggle-button {
    width: 50px;
    height: 25px;
    margin-right: 1.5rem;
    background-color: var(--secondary5);
    border: 1px solid var(--secondary40);
    border-radius: 25px;
    /* border: 1px solid lightgray; */
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s;
    position: relative;
}
  
.toggle-button.on {
    background-color: var(--secondary60);
    border: 1px solid var(--secondary5);
}
  
.toggle-circle {
    width: 25px;
    height: 25px;
    background-color: var(--primary);
    border-radius: 50%;
    transition: all 0.3s;
    position: absolute;
    left: 0;
  }
  
.toggle-button.on .toggle-circle {
    transform: translateX(25px);
   
}

.toggle-button.off .toggle-circle {
    background-color: var(--primary60);
}

