.tasks_container {
    position: absolute;
    bottom: 0rem;
    right: 0rem;
    width: 25rem; /* width when closed */
    background: var(--bg);
    background-color: var(--bg);
    height: 100%; /* Height when closed */
    transition: all 0.5s ease; /* Smooth animation */
    transform: translateX(100%);
    z-index: 999;
}

.tasks_container.open {
    transform: translateX(0%);
}
  
.tasks_heading {
    background-color: var(--secondary10);
    opacity: 1;
    height: 5%;
    cursor: pointer;
    text-align: center;
    border: 1px solid var(--secondary40);
    border-bottom: none;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
  
.tasks_heading_text {
    margin: 0;
    font-family: 500;
}

.tasks_heading_icon {
    position: absolute;
    left: 1rem;
    transform: translateX(50%);
    /* margin-left: 1rem; Adjust to your liking */
}

.tasks_heading_count {
    position: absolute;
    top:0;
    right: 1rem;
    background-color: var(--accent);
    color: var(--bg);
    /* font-weight: 300; */
    font-size: 0.8rem;
    padding: 0.2rem;
    box-sizing: border-box;
    width: 1rem;
    height: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transform: translate(50%, -50%);
}

.tasks_body {
    padding: 0.75rem;
    background-color: var(--secondary5);
    height: 95%;
    border: 1px solid var(--secondary40);
    border-radius: 0px 0px 5px 5px; /* Removed border radius for top corners */
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease 0.5s, visibility 0s linear 0.8s;
}

.tasks_tabs {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    padding: 0rem 2rem;
}

.tasks_tabs_heading_count {
    position: absolute;
    top:0rem;
    right: 0rem;
    background-color: var(--accent);
    color: var(--bg);
    /* font-weight: 300; */
    font-size: 0.8rem;
    padding: 0.2rem;
    box-sizing: border-box;
    width: 1rem;
    height: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transform: translate(150%, -50%);
}

.tasks_container.open .tasks_body {
    opacity: 1;
    visibility: visible;
    transition: opacity 0s, visibility 0s;
}
  

.no_tasks {
    opacity: 0.6;
    font-size: 1.05rem;
    width: 100%;
    text-align: center;
}

.tasks_tab_content {
    height: 90%;
    overflow-y: auto;
}

.task_item {
    border-bottom: 1px solid var(--secondary40);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0.5rem;
    box-sizing: border-box;
    cursor: pointer;
}   

.task_item p {
    margin: 0;
}

.task_item:first-of-type {
    border-top: 1px solid var(--secondary40);
}

.task_item_info_date {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.task_item_info_date p {
    margin: 0;
}

.task_item_actions_icon {
    font-weight: 800;
    font-size: 1.1rem;
}