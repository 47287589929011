.email_section_name {
    font-weight: 500;
}

.email_section {
    border: 1px solid var(--secondary40);
    width: 12rem;
    height: 8rem;
    border-radius: 0.5rem;
    padding: 0.5rem;
    box-sizing: border-box;
    cursor: pointer;
}

.email_section_view_emails {
    display: flex;
    align-items: center;
    justify-content: center;
}
