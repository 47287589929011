.job_holder {
    padding: 2rem;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.job_subnav {
    display: flex;
}


.job_subnav_item {
    position: relative;
    color: black;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.5s ease;
    padding: 0;
    margin-right: 2rem;
}

.job_subnav_item a {
    margin: 0;
    padding: 0;
}

.job_subnav_item::after {
    content: '';
    position: absolute;
    background-color: black;
    height: 3px;
    width: 0%;
    left: 0;
    transition: width 0.5s ease;
    bottom: -8px;
}

.job_subnav_item:hover {
    transform: scale(1.05);
}

.job_subnav_item:hover::after {
    width: 100%;
}

.active_job_subnav_item::after {
    content: '';
    position: absolute;
    background-color: black;
    height: 3px;
    width: 100%;
    left: 0;
    bottom: -8px;
}


.pageSelector {
    margin-bottom: 2rem;
}




.job_info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 1rem;
    /* height: 7rem; */
}

.job_all_actions {
    display: flex;
    align-items: center;
}

.job_actions_holder {
    border: 1px solid var(--secondary40);
    background: var(--bg);
    /* background: red; */
    border-radius: 1rem;
    width: 15rem;
    /* transform: translateX(-50%); */
    /* height: 20rem; */
    overflow: hidden;
}

.job_add_candidates_button {
    display: flex;
    align-items: center;
    border: 1px solid var(--secondary40);
    border-radius: 2rem;
    padding: 0.5rem 1.5rem;
    box-sizing: border-box;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-right: 2rem;
}

.job_add_candidates_button:hover {
    background-color: var(--secondary40);
}

.job_add_candidates_button p {
    margin: 0;
}

.job_action {
    width: 100%;
    /* border-bottom: 1px solid var(--secondary40); */
    padding: 0.75rem 1rem;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    outline: none;
}

.job_action:hover {
    background-color: var(--secondary10);
}

.delete_job_type_holder {
    width: 90%;
    background: linear-gradient(45deg, var(--secondary) 0%, var(--accent) 100%);
    border-radius: 1rem;
    padding: 0.1rem;
    margin-bottom: 1.2rem;
    /* border: 1px solid red; */
    /* padding: 2rem; */
}

.delete_job_type {
    width: 100%;
    height: 100%;
    padding: 0.5rem 1rem;
    box-sizing: border-box;
    transition: all 0.3s ease;
    border-radius: 1rem;
    background-color: var(--bg);
    display: flex;
    justify-content: center;
    align-items: center;
}

.delete_job_type:hover {
    background-color: linear-gradient(45deg, var(--secondary) 0%, var(--accent) 100%);
    cursor: pointer;
    color: var(--bg);
}

a.job_action {
  text-decoration: none;
  color: var(--text);
}


/* sourcing */

.sourcing_holder {
    height: 75vh;
}

.sourcing_search_holder {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 6%;
    margin-bottom: 2%;
    box-sizing: border-box;
}

.candidate_search_results {
    height: 85%;
    margin-bottom: 2%;
    border: 1px solid var(--secondary40);
    background: var(--secondary5);
    border-radius: 1rem;
}



.sourcing_search_input {
    background: transparent;
    outline: none;
    border: 1px solid var(--secondary40);
    border-radius: 0.75rem;
    padding: 0.5rem 1rem;
    box-sizing: border-box;
    width: 85%;
    height: 100%;
}

.sourcing_search_button {
    background: var(--primary60);
    border: none;
    outline: none;
    border-radius: 0.75rem;
    padding: 0.5rem 1rem;
    box-sizing: border-box;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 10%;
}

.sourcing_search_button:hover {
    background: var(--primary80);
    box-shadow: 0px 2px 5px var(--secondary40);
    transform: scale(1.05) translateY(-0.1rem);
}


.candidate_search_results {
    height: 80%;
    width: 100%;
    /* border: 1px solid red; */
    overflow-y: hidden;
}

.candidate_search_result {
    padding: 0.5rem 1.5rem;
    box-sizing: border-box;
    height: 10%;
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--secondary40);

    transition: all 0.3s ease;
}

.candidate_search_result:hover { 
    background: var(--secondary10);
}

.candidate_search_result:last-of-type {
    border-bottom: none;
}

.candidate_search_result_link {
    height: 100%;
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.candidate_search_result_link:last-of-type {
    border-bottom: none;
}

.sourcing_result_info {
    display: flex;
    align-items: center;
    /* gap: 0.5rem; */
    width: 34%;
    margin-right: 1%;

    height: 100%;
}

.sourcing_result_info_description {
    width: 60%;
    height: 100%;
    opacity: 0.7;
    padding: 0rem 1rem;
    box-sizing: border-box;

    overflow: hidden;
    text-overflow: ellipsis;
}

.sourcing_result_info_description p {
    margin: 0;
}

.sourcing_result_info_link {
    width: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sourcing_result_info_name {
    width: 40%;
    min-width: 40%;
    max-width: 40%;
    margin-right: 5%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.sourcing_result_info_job_company {
    height: 100%;
    width: 55%; 
    min-width: 55%;
    max-width: 55%;
    font-size: 0.9rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.25rem;
}

.sourcing_result_info_job_company p {
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.sourcing_result_info_location {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.sourcing_result_info_company {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.sourcing_result_info_job {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.sourcing_result_icon {
    opacity: 0.9;
    color: var(--primary);
    filter: brightness(0.8);
    width: 1rem;
}

.sourcing_result_info_location {
    /* border: 1px solid purple; */
}

.sourcing_footer {
    height: 5%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.results_per_page {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.results_per_page select {
    /* height: 100%; */
    background: transparent;
    outline: none;
    cursor: pointer;

    border: 1px solid var(--secondary40);
    border-radius: 0.35rem;
    padding: 0.25rem 0.5rem;
    box-sizing: border-box;
}

.sourcing_pagination {
    /* border: 1px solid red; */
}

.sourcing_footer > *:first-child {
    justify-self: start;
}

.sourcing_footer > *:nth-child(2) {
    justify-self: center;
}

.sourcing_footer > *:last-child {
    justify-self: end;
}

.sourcing_footer > *:only-child {
    grid-column: 1;
}

.sourcing_pagination {
    display: flex;
    justify-content: center;
    align-items: center;

}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
}

.pagination_button {
    background: var(--bg);
    border: 1px solid var(--secondary5);
    color: var(--text);
    cursor: pointer;
    font-size: 14px;
    padding: 8px 16px;
    transition: all 0.3s ease;
}

.pagination_nav_button {
    background: var(--bg);
    border: none;
    cursor: pointer;
    padding: 8px 16px;
    transition: all 0.3s ease;
}

.pagination_nav_button:hover:not(:disabled) {
    transform: scale(1.05);
}

.pagination_button:hover:not(:disabled) {
    background-color: var(--secondary10);
    border: 1px solid var(--secondary40);
}

.pagination_button:disabled:not(.pagination_button--active) {
    opacity: 0.5;
    cursor: not-allowed;
}

.pagination_button--prev,
.pagination_button--next {
    display: flex;
    align-items: center;
    gap: 10px;
}

.pagination_numbers {
    display: flex;
    align-items: center;
    margin: 0 10px;
}

.pagination_button--number {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    padding: 0;
    margin: 0 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pagination_button--active {
    background-color: var(--primary60);
    border: 1px solid var(--secondary40);
    color: var(--text);
}

.pagination_ellipsis {
    margin: 0 5px;
    color: #777;
}

@media (max-width: 600px) {
    .pagination {
        flex-direction: column;
        gap: 10px;
    }

    .pagination_numbers {
        order: -1;
    }
}


.sourcing_no_results {
    padding: 2rem;
    box-sizing: border-box;
    font-size: 1.1rem;
    opacity: 0.7;
}

.loading_holder {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* HTML: <div class="loader"></div> */
.loader {
    width: 40px;
    height: 20px;
    --c: no-repeat radial-gradient(farthest-side, var(--primary) 93%, #0000);
    background:
        var(--c) 0 0,
        var(--c) 50% 0;

    filter: brightness(0.75);
    background-size: 8px 8px;
    position: relative;
    clip-path: inset(-200% -100% 0 0);
    animation: l6-0 1.5s linear infinite;
    transform: scale(2.25);
}

.loader:before {
    content: "";
    position: absolute;
    width: 8px;
    height: 12px;
    background: var(--primary);
    left: -16px;
    top: 0;
    animation: 
        l6-1 1.5s linear infinite,
        l6-2 0.5s cubic-bezier(0, 200, .8, 200) infinite;
}

.loader:after {
    content: "";
    position: absolute;
    inset: 0 0 auto auto;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: var(--primary); 
    animation: l6-3 1.5s linear infinite;
}

@keyframes l6-0 {
    0%, 30% { background-position: 0 0, 50% 0; }
    33% { background-position: 0 100%, 50% 0; }
    41%, 63% { background-position: 0 0, 50% 0; }
    66% { background-position: 0 0, 50% 100%; }
    74%, 100% { background-position: 0 0, 50% 0; }
}

@keyframes l6-1 {
    90% { transform: translateY(0); }
    95% { transform: translateY(1.5rem); }
    100% { transform: translateY(1.5rem); left: calc(100% - 8px); }
}

@keyframes l6-2 {
    100% { top: -0.1px; }
}

@keyframes l6-3 {
    0%, 80%, 100% { transform: translate(0); }
    90% { transform: translate(26px); }
}