.options-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    max-height: 300px;
    overflow-y: auto;
}

.category {
    padding: 0.5rem 0rem;
    border-bottom: 1px solid var(--secondary40);
}

.category-name {
    font-weight: 600;
    margin-bottom: 0.5rem;
    padding: 0rem 0.5rem;
}

.category-sources {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.category-sources li {
    cursor: pointer;
    padding: 0.5rem;
}

.category-sources li:hover {
    background-color: var(--secondary10);
}

.selector-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.selector {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid var(--secondary40);
    border-radius: 0.5rem;
    cursor: pointer;
}

.candidate_sourcing_desc {
    margin: 0rem;
    margin-bottom: 0.75rem;
}

.candidate_sourcing_desc:last-of-type {
    margin-bottom: 2rem;
}

.options-list {
    background: var(--bg);
    border: 1px solid var(--secondary20);
    box-shadow: 0px 2px 5px var(--secondary20);
    width: 99%;
    border-radius: 0 0 0.5rem 0.5rem;
}

.create_new_source_text {
    text-decoration: underline;
    color: var(--primary);
    cursor: pointer;
}

.add_source_section:first-of-type {
    border-top: 1px solid var(--secondary40);
}

.add_source_section {
    padding: 1rem 0rem;
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--secondary40);
}

.add_source_section_name {
    font-weight: 600;
    width: 30%;
    margin: 0;
}

.add_source_section_input {
    border: 1px solid var(--secondary40);
    outline: none;
    padding: 0.5rem;
    width: 12.5rem;
    box-sizing: border-box;
    border-radius: 0.5rem;
    background: none;
}

.add_source_button {
    border: 1px solid var(--secondary40);
    border-radius: 1.5rem;
    padding: 0.5rem 1rem;
    width: 7.5rem;
    height: 2.5rem;
    position: absolute;
    left: 3rem;
    bottom: 3rem;
    box-sizing: border-box;
    background: var(--primary80);
    cursor: pointer;
    color: var(--bg);
    transition: all 0.3s ease;
}

.add_source_button:hover {
    background: var(--primary);
    box-shadow: 0px 2px 5px var(--secondary40);
}

.add_source_button:disabled {
    border: 1px solid var(--secondary5);
    background: var(--secondary60);
}