.candidates {
    display: flex;
    height: 77.5vh;
    align-items: center;
    box-sizing: border-box;
    padding: 1rem;
    border-radius: .5rem;
    /* box-shadow: 1px 1px 5px rgb(59, 58, 58, 0.5); */
}

.candidateFilter {
    width: 20%;
    height: 100%;
    padding: 1rem;
}

.candidateFilter h3 {
    font-size: 1.3rem;
}

.candidateFilter p {
    font-size: 1.2rem;
    color: #222;
}

.candidateSection {
    width: 100%;
    height: 100%;
}

.candidateOptions {
    height: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.candidateTable {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 2.5px;
    padding: 0rem 0rem;
    overflow: auto;
    box-sizing:  border-box;
    box-shadow: 0px 0px 5px inset var(--secondary);
    width: 100%;
    min-height: 90%;
    height: 90%;
    max-height: 90%;
    
}

.candidateInfo {
    display: flex;
    width: 33%;
}

.candidateSkills {
    width: 33%;
}

.candidateEnd {
    display: flex;
    justify-content: right;
    width: 33%;
    
}

.candidateHeading {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--secondary40);
    padding: 0rem 1rem;
    padding-bottom: .5rem;
    box-sizing: border-box;
    height: 2rem;
}

.candidateHeading div {
    text-align: center;
    font-weight: bold;
}

.candidatesBody {
    width: 100%;
    height: 95%;
    overflow-y: auto;
}

.candidateName {
    font-size: 1.2rem;
}

.candidateLink {
    width: 100%;
}

.candidate {
    width: 100%;
    display: flex;
    cursor: pointer;
    padding: 0rem 1rem;
    /* padding-right: 0; */
    box-sizing: border-box;
    height: 3rem;
    align-items: center;
    border-bottom: 1px solid var(--secondary40);
}

.candidate div {
    text-align: center;
    
}


.candidateLink {
    color: inherit;
    text-decoration: inherit;
}

.filterScore {
    border-top: 1px solid rgba(128, 128, 128, 0.343);
}

.filterCriteria.stage_filter {
    border-top: 1px solid rgba(128, 128, 128, 0.343);
}

.filterCriteria {
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(128, 128, 128, 0.343);
}

.filter_heading {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    /* padding-right: 2rem; */
    /* padding: 0rem .5rem; */
    padding-right: 1rem;
    box-sizing: border-box;
}

.filter_heading p {
    font-size: 1rem;
    font-weight: 600;
}


.add_candidates_button {
    background-color: rgb(0, 0, 0, 0.8);
    border-radius: .2rem;
    font-size: 0.75rem;
    display: flex;
    justify-content: center;
    padding: 0.5rem 1rem;
    align-items: center;
    cursor: pointer;
    color: white;
    transition: transform 0.25s ease, background-color 0.25s ease, box-shadow 0.25s ease;
}

.add_candidates_button:hover {
    background-color: rgb(0, 0, 0, 1);
    box-shadow: 0 0 2px rgb(0, 0, 0, 0.2);
    transform: scale(1.025);
}

.add_candidates_button span {
    margin-left: 0.5rem;
}


.job_candidates_options_holder{
    height: 2.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.job_candidates_options {
    /* border: 1px solid var(--secondary); */
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.job_candidates_option_icon {
    color: var(--primary);
    font-size: 1rem;
}

.job_candidates_sort_by_select {
    outline: none;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    width: 15rem;
    height: 2.5rem;
    background: none;
    border: 1px solid var(--secondary40);
}

.filter_option_availability {
    margin-bottom: 0.5rem;
}

.filter_option_availability p {
    margin: 0;
    /* margin-bottom: 0.5rem; */
    padding: 0;
}

.regular_filter, .skillscore_filter {
    height: 75%;
    overflow-y: auto;
    /* border:  1px solid red; */
}

.filter_option_subcategory {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding: 0rem .5rem; */
    padding-right: 1rem;
    /* margin-right: 2rem; */
}

.filter_option_subcategory p {
    font-size: 1rem;
    font-weight: 500;
}

.candidate_link {
    text-decoration: none;
    color: var(--text);
}


.add_candidates_body {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    /* border: 1px solid red; */
}

.add_candidates_file_input {
    width: 50%;
    /* border: 1px solid red; */
}

.file_list_area {
    /* border: 1px solid red; */
    height: 60%;
    overflow-y: auto;
}

/* File Input Area */
.file-input-container {
    border: 2px dashed #ccc;
    border-radius: .5rem;
    /* padding: 10px; */
    text-align: center;
    margin-bottom: 20px;
    height: 20%;
    box-sizing: border-box;
}

/* Styling the label that will act as the file input for better UX */
.label-file-input {
    position: relative;
    display: inline-block;
    width: 100%;
    /* padding: 10px; */
    /* background-color: #f7f7f7; */
    cursor: pointer;
    /* border: 1px solid #ddd; */
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
}

.file-input {
    opacity: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    position: absolute;
    cursor: pointer;
    background: none;
    background-color: red;
    border: none;
}



.label-file-input:hover {
    background-color: var(--sectionBackground);
}

/* List of files */
.file-list {
    list-style: none;
    padding: 0;
}

.file-list li:first-of-type {
    border-top: 1px solid var(--secondary40);
}

.file-list li {
    padding: .75rem 0.5rem;
    border-bottom: 1px solid var(--secondary40);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.file-list li:first-child {
    margin-top: 0;
}

.button-remove {
    background: none;
    border: none;
    font-size: 0.8rem;
    cursor: pointer;
}

.add_candidates_details {
    padding: 1rem 1.5rem;
    margin-left: 1.5rem;
    box-sizing: border-box;
    max-width: 50%;
    width: 50%;
    border-left: 1px solid var(--secondary40);
}

.add_candidate_details_heading {
    margin: 0;
    font-size: 1.55rem;
}


.add_candidates_section_detail:first-of-type {
    border-top: 1px solid var(--secondary40);
    margin-top: 3rem;
}

.add_candidates_section_detail {
    display: flex;
    align-items: center;
    padding: 0rem 1rem;
    height: 5rem;
    border-bottom: 1px solid var(--secondary40);
    cursor: pointer;
}

.add_candidates_section_detail_name {
    font-weight: 550;
    width: 30%
}

.add_candidates_section_detail_desc {
    font-weight: 450;
    opacity: 0.5;
    width: 65%;
}


.add_candidate_detail {
    padding: 1.5rem 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    border-bottom: 1px solid var(--secondary40);
}

.add_candidate_detail:first-of-type {
    border-top: 1px solid var(--secondary40);
}

.candidate_detail_name {
    font-weight: 500;
    margin: 0;
    margin-bottom: .75rem;
}

.candidate_detail_desc {
    margin: 0;
}

.job_candidates_add_candidates {
    margin-right: 0.5rem;
}


/* handling teh table */

.job_candidate_checkbox_cell {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5%;
}

.job_candidate_name_cell {
    width: 17.5%;
}

.job_candidate_email_cell {
    width: 20%;
}

.job_candidate_phone_cell {
    width: 15%;
}

.job_candidate_stage_cell {
    width: 20%;
}

.job_candidate_score_cell {
    width: 15%;
}

.job_candidate_info_cell {
    width: 7.5%;
}

